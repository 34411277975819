import axios from "axios";
import React, {Component} from "react";
import FeatherIcon from 'feather-icons-react';
import ReactToPrint from "react-to-print";
import UserIdentity from "../../users/UserIdentity";
import Headerpdf from "../../users/Headerpdf";
 

class SchoolfeeStudent extends Component{

    state = {
        schoolfee : true,
        student : {
            user : {
                user : {}
            }
        },
        months : [],
        studentschoolfees : {
            schoolfees : []
        }
    }

    componentDidMount(){
        this.getMonth();
        this.getUser()
    }

    async getUser(){
        var student = await axios.get("students/"+this.props.match.params.student_id);
        if(student.status === 200)
        {
            this.setState({
                student : student.data
            })
            this.getStudentschoolfees()
        }
    }

    async getStudentschoolfees(){
        var schoolfees =  await axios.get('students/schoolfees/'+this.props.match.params.student_id)
        if(schoolfees.status === 200)
        {
            this.setState({
                studentschoolfees : schoolfees.data
            })
        }
    }

    async getMonth(){
        var months = await axios.get("months");
        if(months.status === 200){
          this.setState({
            months : months.data
          })
        }
      }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: !this.state.schoolfee
        }); 
    }

    render(){
        var profile = JSON.parse(localStorage.getItem('profile'))

        var today = new Date(), amount = 0, currency=[], schoolfees = "";
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        schoolfees = 
        this.state.studentschoolfees.schoolfees.map((invoice) => {
            amount += invoice.pay_mount
            
            if(currency.filter(dat => dat.currency == invoice.sectionschoolfeecategory.currency).length == 0)
            {
                currency.push({
                    currency : invoice.sectionschoolfeecategory.currency,
                    amount : invoice.pay_mount
                })
            }
            else{
                currency.map((dat, index) => {
                    if(dat.currency == invoice.sectionschoolfeecategory.currency)
                    {
                        currency[index].amount = currency[index].amount > 0 ? currency[index].amount + invoice.pay_mount : invoice.pay_mount
                    }
                })
            }
            

            var first_name = this.state.studentschoolfees.user.first_name ? this.state.studentschoolfees.user.first_name : "";
            return (
                <div className="col-6">
                    <div className="m-1 border border-1 ps-2 p-1 rounded rounded-3 mb-2" key={invoice.id}>
                        <div className=" my-1">
                            <img src={profile.profile.establishment.logo } className="img-fluid" width={this.state.student && (profile.profile.establishment.logo&& ("60"))} /> 
                            <span className="h6 fw-bolder font-monospace">{profile.profile.establishment.name}</span>
                        </div>
                        <div className="text-center">
                            <h6 className="h6 font-monospace">Reçu N°{invoice.invoice}</h6>
                        </div>
                        <div className="row">
                            <div className="col-md-7 mb-2">
                                Adresse : <span className="small font-monospace">{profile.profile.establishment.city}</span><br />
                                Tél : <span className="small font-monospace">{profile.profile.establishment.tel}</span><br />
                                Email : <span className="small font-monospace">{profile.profile.establishment.email}</span><br />
                            </div>
                            <div className="col-md-5 mb-2">
                                Année scolaire : <span className="font-monospace small">{invoice.year.year}</span><br />
                                Classe : <span className="font-monospace small">{this.state.studentschoolfees.sectionclass.class.short_entitled_class + " " + this.state.studentschoolfees.sectionclass.title }</span><br />
                                Section/option : <span className="font-monospace small">{invoice.sectionschoolfeecategory.section.entitled_section}</span>
                            </div>
                        </div>
                        
                        <div className="mt-3 ">
                            Reçu de  <span className="small font-monospace">{ this.state.studentschoolfees.user.last_name + " " + this.state.studentschoolfees.user.family_name + " " + first_name}</span> <br />
                            La somme de  <span className="small font-monospace">{ invoice.pay_mount + "" + invoice.sectionschoolfeecategory.currency}</span> <br />
                            Motif  <span className="small font-monospace"> {invoice.sectionschoolfeecategory.schoolfeecategory.category + " mois de " + invoice.month.month}</span> <br />
                            <div className="text-end pe-5 me-5 small">
                                {profile.profile.establishment.city}, le { today}
                            </div>
                        </div>
                    </div>
                </div>
            )
        })

        return(
        <div className="row">
            <div className="col-12">
                <div className="card shadow overflow-hidden mb-3 overflow-hidden rounded rounded-3">
                    <div className="card-body ">
                        <div className="row">
                            <div className="fw-bolder h6 text-center font-monospace">Apropos de l'élève</div>
                            <div className="col-md-3">
                                <div className="d-flex">
                                    <div className="image mr-3">
                                        <img src={this.state.student && (this.state.student.user.avatar)} className="rounded-circle img-fluid" width={this.state.student && (this.state.student.user.avatar && ("100"))} /> 
                                    </div>
                                    <div className="mt-4">
                                        <div>
                                            <b>{this.state.student && (this.state.student.matricule + " # ")} {this.state.student && ( this.state.student.user.identity_number)}</b><br />
                                            {this.state.student && (this.state.student.user.last_name + " "+ this.state.student.user.family_name + " "+ this.state.student.user.first_name)} 
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div>
                                    <a href={"tel:"+this.state.student && (this.state.student.user.tel)} rel="noopener noreferrer" target="_blank" className="text-dark text-decoration-none"> <FeatherIcon icon="phone" size="16" className={"text-primary "}></FeatherIcon>{this.state.student && (this.state.student.user.tel)}</a> </div>
                                <div>
                                    <a href={"mailto:"+this.state.student && (this.state.student.user.email)} rel="noopener noreferrer" target="_blank"  className="text-dark text-capitalize text-decoration-none"> <FeatherIcon icon="mail" size="16" className={"text-primary "}></FeatherIcon> {this.state.student && (this.state.student.user.email)}</a> </div>
                                <div>
                                    <FeatherIcon icon="map-pin" size="16" className={"text-primary "}></FeatherIcon> {this.state.student && (this.state.student.user.adresse)}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div>
                                    <FeatherIcon icon="box" size="16" className={"text-primary "}></FeatherIcon>
                                    {this.state.student && (this.state.student.sectionclass && (this.state.student.sectionclass.class.short_entitled_class + " " + this.state.student.sectionclass.title))}
                                </div>
                                <div>
                                    <FeatherIcon icon="home" size="16" className={"text-primary "}></FeatherIcon>
                                    {this.state.student && (this.state.student.sectionclass && (this.state.student.sectionclass.section.entitled_section))}
                                </div>
                                {
                                    currency.length > 0 &&(
                                        <div className="small">
                                            <FeatherIcon icon="dollar-sign" size="16" className={"text-primary "}></FeatherIcon>
                                            Net { 
                                                currency.map(dat => {
                                                    return(
                                                        <b>{ dat.amount + " " + dat.currency } ,</b> 
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                }
                                
                            </div>
                            <div className="col-md-3">
                                <FeatherIcon icon="users" size="16" className={"text-primary "}></FeatherIcon>{this.state.student && (this.state.student.user.user.first_name + " "+ this.state.student.user.user.last_name)} 
                                <div>
                                    <a href={"tel:"+this.state.student && (this.state.student.user.user.tel)} rel="noopener noreferrer" target="_blank" className="text-dark text-decoration-none"> <FeatherIcon icon="phone" size="16" className={"text-primary "}></FeatherIcon>{this.state.student && (this.state.student.user.user.tel)}</a> </div>
                                <div>
                                    <a href={"mailto:"+this.state.student && (this.state.student.user.user.email)} rel="noopener noreferrer" target="_blank" className="text-dark text-capitalize text-decoration-none"> <FeatherIcon icon="mail" size="16" className={"text-primary "}></FeatherIcon> {this.state.student && (this.state.student.user.user.email)}</a> </div>
                                <div>
                                    <FeatherIcon icon="map-pin" size="16" className={"text-primary "}></FeatherIcon> {this.state.student && (this.state.student.user.user.adresse)}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className="text-end">
                <ReactToPrint
                    trigger={() => <button className="btn btn-sm btn-outline-primary" type="button"><FeatherIcon icon={"printer"} size="22"></FeatherIcon> <span className="d-none d-sm-inline">Imprimer</span></button>}
                    content={() =>  this.componentRef}
                />
            </div>
            
            <div ref={el => (this.componentRef = el)}>
                <Headerpdf />
                <UserIdentity matricule ={this.state.student.matricule} name={this.state.student.user.last_name + " " + this.state.student.user.family_name + " " + this.state.student.user.first_name} classe={this.state.student && (this.state.student.sectionclass && (this.state.student.sectionclass.class.short_entitled_class + " " + this.state.student.sectionclass.title + " " + this.state.student.sectionclass.section.entitled_section))} title={"Liste des Reçus"} /> 
                
                <div className="">
                    <div className="text-center text-primary table_action">
                        <input type={"checkbox"} className="rounded rounded-3" name="schoolfee" value={this.state.schoolfee} id="schoolfee" onChange={ this.handleInput } /> <label className="" htmlFor="schoolfee">Afficher la fiche des paiements scolaire</label>
                    </div>
                    <div className={ this.state.schoolfee ? "row d-none" : "row" }>
                    {
                        schoolfees
                    }
                    </div>
                    <div className= { this.state.schoolfee ? "col-12" : "d-none" }>
                        <div className="col-md-12 font-monospace">
                            <div className={this.state.schoolfee ? "bg-secondary text-center text-white font-monospace fw-bolder my-2" : "bg-secondary text-center text-white font-monospace fw-bolder my-2 d-none"}>Fiche des paiements scolaire</div>
                                <div className={this.state.schoolfee ? "table-responsive" : "table-responsive d-none" }>
                                <table className="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Mois</th>
                                            <th>Montant</th>
                                            <th>Paiement</th>
                                            <th>N° fact</th>
                                            <th>Année</th>
                                            <th>Date</th>
                                            <th>Responsable</th>
                                            <th>Comptable</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.studentschoolfees != null ?(
                                                this.state.months.map(month =>{
                                                    return(<>
                                                        {
                                                    
                                                            this.state.studentschoolfees.schoolfees.filter(mon => mon.month.id == month.id).map((schoolfee, index) =>{
                                                                return(
                                                                    <tr key={schoolfee.id}>
                                                                        {
                                                                            index == 0 &&(
                                                                                <td align="center" className="fw-bolder" rowSpan={this.state.studentschoolfees.schoolfees.filter(mon => mon.month.id == month.id).length}>{month.month  }</td>
                                                                            )
                                                                        }
                                                                        <td align="right">{ schoolfee.pay_mount + ""+ schoolfee.sectionschoolfeecategory.currency }</td>
                                                                        <td align="">{ schoolfee.sectionschoolfeecategory.schoolfeecategory.category }</td>
                                                                        <td align="right">{ schoolfee.invoice }</td>
                                                                        <td align="">{ schoolfee.year.year }</td>
                                                                        <td align="right">{ schoolfee.createdAt.slice(0, 10) }</td>
                                                                        <td align="right">{ schoolfee.user.last_name +  " " + schoolfee.user.first_name }</td>
                                                                        <td align="right">{ schoolfee.staff.user.last_name +  " " + schoolfee.staff.user.first_name }</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            this.state.studentschoolfees.schoolfees.filter(mon => mon.month.id == month.id).length == 0 &&(
                                                                <tr key={month.id}>
                                                                    <td align="center" className="fw-bolder">{ month.month}</td>
                                                                    <td align="center" className="fw-bolder"></td>
                                                                    <td align="center" className="fw-bolder"></td>
                                                                    <td align="center" className="fw-bolder"></td>
                                                                    <td align="center" className="fw-bolder"></td>
                                                                    <td align="center" className="fw-bolder"></td>
                                                                    <td align="center" className="fw-bolder"></td>
                                                                    <td align="center" className="fw-bolder"></td>
                                                                </tr>
                                                            )
                                                        }
                                                        </>
                                                    )
                                                })
                                            ) : ""
                                        }
                                    </tbody>
                                </table>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
export default SchoolfeeStudent