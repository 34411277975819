import axios from "axios";
import React, {Component} from "react";
import TypesalaryForm from "./TypesalaryForm";
import FeatherIcon from 'feather-icons-react';
import Loading from "../../Loading";
import { toast } from "react-toastify";

class   Typesalary extends Component{

    state = {
        isModal : false,
        loading : false,
        formData : null,
        typesalaries : [],
    }

    componentDidMount(){
        this.getTypesalaries();
    }

    async getTypesalaries(){
        this.setState({ typesalaries : [], loading : false})
        var typesalaries = await axios.get('typesalaries');
        if(typesalaries.status === 200)
        {
            this.setState({typesalaries : typesalaries.data, loading : true}) 
        }
    }

    showModal = (typesalary = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : typesalary,
        })
        this.getTypesalaries()
    }

    delete = (typesalary) =>{
        axios.delete("typesalaries/"+typesalary).then((response) => {
            this.getTypesalaries()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    handleCheck(val) {
        var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
         return  permissions.some(item => val === item.permission);
     }

    render(){
        var typesalaries =
        this.state.typesalaries.map((typesalary) => {
            return(
                <div className="col-md-4" key={typesalary.id}>
                    <div className="card shadow mb-2 rounded rounded-3 overflow-hidden">
                        <div className="card-body">
                            <div>
                                <div className="text-center mb-2 h5">{typesalary.type} </div>
                            </div>
                            <div className="  text-end me-2">
                                {
                                    this.handleCheck("Modifier type de salaire") == true && (
                                        <button onClick={ () => this.showModal(typesalary)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Modifier cet enregistrement"><FeatherIcon icon="edit-3" size={13}></FeatherIcon> </button>
                                    )
                                }
                                {
                                    this.handleCheck("Supprimer type de salaire") == true && (
                                        <button onClick={ () => this.delete(typesalary.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="delete" size={13}></FeatherIcon> </button>
                                    )
                                }
                            </div> 
                        </div>
                    </div>
                </div>
            )
        })
        return(
            <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Type des salaires</h5>
                {
                    this.handleCheck("Ajouter type de salaire") == true && (
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="btn-group me-2">
                                <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><FeatherIcon icon="plus-circle" ></FeatherIcon> Type salaire</button>
                            </div>
                        </div>
                    )
                }
            </div>

            <div className="row">
                { this.state.loading ?  typesalaries : <Loading />}
            </div>

            <TypesalaryForm 
                isModal={this.state.isModal} 
                formData={this.state.formData} 
                showModal={this.showModal}
            />
            

        </div>
        )
    }
}
export default Typesalary;