import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import NotFound from './pages/NotFound';
import Dashboard from './pages/Dashboard';
import Schoolfee from "./pages/cp/schoolfees/Schoolfee";
import Chartaccount from "./pages/cp/chartaccounts/Chartaccount";
import Gift from "./pages/cp/gifts/Gift";
import Accountingbalance from "./pages/cp/accountingbalances/Accountingbalance";
import Expense from "./pages/cp/expenses/Expense";
import Wage from "./pages/cp/wages/Wage";
import Schoolfeecategory from "./pages/cp/schoolfeecategories/Schoolfeecategory";
import Login from "./partials/Login";
import Typesalary from "./pages/cp/typesalaries/Typesalary";
import Trash from "./pages/trashs/Trash";
import Schoolfees from "./pages/cp/schoolfees/Schoolfrees";
import Report from "./pages/cp/reports/Report";
import SchoolfreeDette from "./pages/cp/schoolfees/SchoolfreeDette";
import Profile from "./pages/users/Profile";
import Notification from "./pages/users/Notification";
import SchoolfeeStudent from "./pages/cp/students/SchoolfeeStudent";
import Search from "./pages/users/Search";
import Sms from "./pages/cp/sms/Sms";
import Archive from "./pages/cp/archives/Archive";
import ArchiveDetail from "./pages/cp/archives/ArchiveDetail";
import SchoolfreeListClass from "./pages/cp/schoolfees/SchoolfreeListClass";
 

var permissions = JSON.parse(localStorage.getItem("permissions"))
function handleCheck(val) {
    return  (permissions ?? []).some(item => val === item.permission);
}

function RouterPage(){

    return(
        <Switch>
            {
                handleCheck("Afficher tableau de bord") == true && (
                    <Route exact path="/" component={Dashboard} />
                )
            }
            {
                handleCheck("Afficher paiement") == true && (
                    <Route  path="/:schoolfeecategory_id/schoolfees" component={Schoolfee} />
                )
            }
            {
                handleCheck("Afficher paiement") == true && (
                    <Route  path="/schoolfeesall" component={SchoolfreeListClass} />
                )
            }
            {
                handleCheck("Afficher paiement") == true && (
                    <Route  path="/schoolfees/:type" component={SchoolfreeDette} />
                )
            }
            {
                handleCheck("Afficher élève") == true && (
                    <Route  path="/students/:student_id" component={SchoolfeeStudent} />
                )
            }
            {
                handleCheck("Afficher paiement") == true && (
                    <Route  path="/schoolfees" component={Schoolfees} />
                )
            }
            {
                handleCheck("Afficher caisse") == true && (
                    <Route  path="/chartaccounts" component={Chartaccount} />
                )
            }

            {
                handleCheck("Ajouter élève") == true && (
                    <Route  path="/sms" component={Sms} />
                )
            }

            {
                handleCheck("Afficher don") == true && (
                    <Route  path="/gifts" component={Gift} />
                )
            }
            {
                handleCheck("Afficher caisse") == true && (
                    <Route  path="/accountingbalances" component={Accountingbalance} />
                )
            }
            {
                handleCheck("Afficher depense") == true && (
                    <Route  path="/expenses" component={Expense} />
                )
            }
            {
                handleCheck("Afficher salaire") == true && (
                    <Route  path="/wages" component={Wage} />
                )
            }
            {
                handleCheck("Afficher corbeille") == true && (
                    <Route  path="/trash" component={Trash} />
                )
            } 
            {
                handleCheck("Afficher archive") == true && (
                    <Route  path="/archives/paiement/:year" component={ArchiveDetail} />
                )
            }
            {
                handleCheck("Afficher archive") == true && (
                    <Route  path="/archives" component={Archive} />
                )
            }

            {
                handleCheck("Afficher rapport") == true && (
                    <Route  path="/reports" component={Report} />
                )
            }
            
            {
                handleCheck("Afficher type de paiement") == true && (
                    <Route  path="/schoolfeecategories" component={Schoolfeecategory} />
                )
            }

            {
                handleCheck("Afficher type de salaire") == true && (
                    <Route  path="/typesalaries" component={Typesalary} />
                )
            }

            <Route  path="/search/:search" component={ Search } />
            <Route  path="/get/:user_id" component={Login} />
            <Route  path="/login" component={Login} />
            <Route  path="/profile" component={Profile} />
            <Route  path="/notifications" component={Notification} />
            <Route component={NotFound} />
        </Switch>
    )
}

export default RouterPage;