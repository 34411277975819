import axios from "axios";
import React, {Component} from "react";
import { toast } from "react-toastify";

class   Sms extends Component{

    state = {
        isModal : false,
        loading : false,
        formData : "",
        sms : [],
    }

    componentDidMount(){
        this.getSms();
    }

    storeSms  = (sms) =>{
        axios.post("sms", { id : sms});
        this.getSms();
    }

    storeSmsall = () => {
        axios.post("smsall");
        this.getSms();
    }
    
    async getSms(){
        var sms = await axios.get('sms');
        console.log(sms)
        if(sms.status === 200)
        {
            this.setState({sms : sms.data, loading : true}) 
        }
    }


    delete = (sms) =>{
        axios.delete("sms/"+sms).then((response) => {
            this.getSms()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    render(){
        
        return(
            <div className="">
            <div className="h5 font-monospace mb-2">SMS en attante d'envoi</div>
                <div className="card shadown mb-2">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-striped table-sm table-bordered ">
                                <thead>
                                    <tr>
                                        <td>ID</td>
                                        <td>Sms</td>
                                        <td>Destinateur</td>
                                        <td>#</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.sms.map((sms) => {
                                            return(
                                                <tr>
                                                    <td>{sms.id}</td>
                                                    <td>
                                                        <textarea className="form-control form-control-sm border border-0" rows={3} value={sms.message}></textarea>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-start">
                                                            <img src={ sms.user.avatar} className="rounded-circle mr-1" alt="Fiona Green" width="40" height="40" />
                                                            <div className="flex-grow-1 ml-3">
                                                                { sms.user.last_name} <br /> { sms.user.tel }, { sms.user.email }
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-primary btn-sm me-2" onClick={() => this.storeSms(sms.id)}>Envoyer</button>
                                                        <button className="btn btn-danger btn-sm" onClick={() => this.delete(sms.id)}>Annuler</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="text-end"> <button className="btn btn-primary btn-sm me-2" onClick={() => this.storeSmsall()}>Send all </button></div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Sms;