import axios from "axios";
import React, {Component} from "react";
import GiftForm from "./GiftForm";
import FeatherIcon from 'feather-icons-react';
import Listprint from "../../Listprint";
import { toast } from "react-toastify";

class   Gift extends Component{

    state = {
        isModal : false,
        formData : null,
        rows : [],
        columns : [
            {
                label: 'Fournisseur',
                field: 'supplier',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Personnel',
                field: 'user',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Titre',
                field: 'entitled_gift',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Quantité',
                field: 'quantity',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Specification',
                field: 'specification',
                sort: 'asc',
                width: 150
            },
            // {
            //     label: 'Document',
            //     field: 'file',
            //     sort: 'asc',
            //     width: 120
            // },
            {
                label: 'Date',
                field: 'created_at',
                sort: 'asc',
                width: 120
            },
            {
                label: '#',
                field: 'action',
                sort: 'asc',
                width: 120
            }
        ]
    }

    componentDidMount(){
        this.getGifts();
    }

    async getGifts(){
        this.setState({
            rows : []
        })
        var gifts = await axios.get('gifts');
        if(gifts.status === 200)
        {
            var rows = [];
            gifts.data.map((gift) => {
                rows.push({
                    supplier : gift.supplier.name,
                    user : gift.supplier.user.first_name +" "+ gift.supplier.user.last_name,
                    entitled_gift : gift.entitled_gift,
                    quantity : gift.quantity,
                    specification : gift.specification,
                    file : gift.file,
                    created_at : <small className="small">{ gift.createdAt.slice(0, 10) } </small>,
                    action : <span className="table_action">
                        {
                            this.handleCheck("Modifier don") == true && (
                                <button onClick={ () => this.showModal(gift)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Modifier cet enregistrement"><FeatherIcon icon="edit-3" size={16}></FeatherIcon> </button>
                            )
                        }
                        {
                            this.handleCheck("Supprimer don") == true && (
                                <button onClick={ () => this.delete(gift.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="delete" size={16}></FeatherIcon> </button>
                            )
                        }
                    </span>
                })
            })
            this.setState({
                rows : rows
            })
            
        }
    }

    showModal = (gift = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : gift,
        })
        this.getGifts()
    }

    delete = (gift) =>{
        axios.delete("gifts/"+gift).then((response) => {
            this.getGifts()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    handleCheck(val) {
        var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
         return  permissions.some(item => val === item.permission);
     }
      
    render(){
        return(
            <div className="overflow-hidden">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Structure des Dons</h5>
                {
                    this.handleCheck("Ajouter don") == true && (
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="btn-group me-2">
                                <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><FeatherIcon icon="plus"></FeatherIcon> Don</button>
                            </div>
                        </div>
                    )
                }
            </div>
            {
                this.state.rows.length > 0 && (
                    <Listprint columns={this.state.columns} rows={this.state.rows} title={"Structure des Dons"}/>
                )
            }

            <GiftForm 
                isModal={this.state.isModal} 
                formData={this.state.formData} 
                showModal={this.showModal}
            />
            
        </div>
        )
    }
}
export default Gift;