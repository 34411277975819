import React, {Component} from "react";
import moment from "moment";

class   Reportdetail extends Component{

    render(){

        return(
            <div className=" pt-2">
                <h5 className="text-center font-monospace">Opérations effectuées</h5>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-sm">
                        <thead>
                            <tr>
                                <th>Bénéficiaire</th>
                                <th>Opérateur</th>
                                <th>Montant</th>
                                <th>Type</th>
                                <th>mois</th>
                                <th>annee</th>
                                <th>motif</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                
                                this.props.formData.map((expensedetail) => {
                                    return(
                                        <tr key={expensedetail.id}>
                                            <td>{ expensedetail.expense.staff ? expensedetail.expense.staff.user.last_name + " " + expensedetail.expense.staff.user.first_name : "" }</td>
                                            <td>
                                                <span className="text-right d-inline ">{expensedetail.staff ? expensedetail.staff.user.last_name +" "+ expensedetail.staff.user.first_name : "" }</span>
                                            </td>
                                            <td>{  expensedetail.amount+""+this.props.currency  }</td>
                                            <td>{ expensedetail.typesalary ? expensedetail.typesalary.type : "Depense" }</td>
                                            <td>{ expensedetail.expense.month.month }</td>
                                            <td>{ expensedetail.expense.year.year }</td>
                                            <td>{ expensedetail.reason }</td>
                                            <td>{ moment(expensedetail.createdAt).format('DD-MM-YYYY') }</td>
                                        </tr>
                                    )
                                }) 
                            }
                            
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
export default Reportdetail;