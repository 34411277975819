import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import Select from 'react-select';
import { toast } from "react-toastify";

class   ShoolfeeForm extends Component{

    state = {
        id : "",
        responsible: "",
        profile : "",
        classe : "",
        student : "",
        schoolfeecategory : "",
        account : "",
        month : null,
        pay_mount : null,
        remaining_amount : null,
        scolary_year : null,
        years : [],
        sectschoolfeecatstudcategories : [],
        error : "",

        isModal : false,
        sections : [],
        section :"",
        responsibles : [],
        students : [],
        months : [],
        schoolfeecategories : [],
        chartaccounts : [],
        sms : 1,
        price : 1,
        title : "",
    }
    


    handleInputChange = (select) => {
        this.setState({
            classe : select.value,
            students : select.value.students,
            student : "",
        });
        // this.students(select.value)
    }

    handleInputMax = (e) => {
        
        if(this.state.schoolfeecategory && e.target.value > 0 && e.target.value <= this.state.month.length * this.state.price)
        {
            this.setState({
                [e.target.name]: e.target.value
            });
        }
        else{
            this.setState({
                [e.target.name]: ""
            }); 
        } 
    }

    storeShoolfee = ( val = null) =>{
        var data = {
                userId: this.state.responsible,
                profile : this.state.profile,
                sectionclasseId : this.state.classe ? this.state.classe.id : this.props.classe.sectionclasse,
                studentId : this.state.student,
                sectionschoolfeecategoryId : this.state.schoolfeecategory,
                chartaccountId : this.state.account,
                monthId : this.state.month ? this.state.month : null,
                pay_mount : this.state.pay_mount ? this.state.pay_mount : null,
                remaining_amount : this.state.remaining_amount ?  this.state.remaining_amount : 0,
                yearId : this.state.scolary_year ? this.state.scolary_year : null,
                sms : this.state.sms,
                operation : this.state.id ? (val === 1 ? "Archiver" : "Editer" ) : "Ajouter",
            }

        var request = this.state.id ? axios.put('schoolfees/'+this.state.id, data) : axios.post('schoolfees', data);
        request.then((response) =>{
            this.props.showModal()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    async responsibles(){
        var request = await axios.get('users');
        if(request.status === 200)
            this.setState({responsibles : request.data})
    }

    async months(){
        var request = await axios.get('months');
        if(request.status === 200)
            this.setState({months : request.data})
    }

    async schoolfeecategories(){
        var request = await axios.get('schoolfeecategories');
        if(request.status === 200)
            this.setState({
                schoolfeecategories : request.data, 
                // schoolfeecategory : request.data[0].sectionschoolfeecategories[0].id,
                // price : request.data[0].sectionschoolfeecategories[0].price,
            })
    }

    async sections(){
        var request = await axios.get('sections');
        if(request.status === 200)
            this.setState({sections : request.data})
    }

    async chartaccounts(){
        var request = await axios.get('chartaccounts');
        if(request.status === 200)
            this.setState({chartaccounts : request.data})
    }
    
    async getYear(){
        var data=[], years = await axios.get('years');
        if(years.status === 200)
        {
            years.data.map((year) => {
                data.push({value : year.id, label : year.year})
            })
            
            this.setState({
                years : data
            })
        }
    }

    edit=()=>  {
        this.form()
        this.responsibles()
        this.sections()
        this.months()
        this.schoolfeecategories()
        this.chartaccounts()
        this.getYear()
    }

    form=()=>{
        this.setState({
            id : this.props.formData ? this.props.formData.id : "",
            responsible: this.props.formData ? this.props.formData.userId : "",
            section : this.props.formData ? this.props.formData.section ? this.props.formData.section.id  : "" : "",
            student : this.props.formData ?  this.props.formData.studentId : "",
            profile : this.props.formData ? this.props.formData.profile : "",
            // schoolfeecategory : this.props.formData ? this.props.formData.schoolfeecategory ? this.props.formData.schoolfeecategory.id : this.props.schoolfeecategory.id : this.props.schoolfeecategory.id ,
            account : this.props.formData ? this.props.formData.accountingbalance ? this.props.formData.accountingbalance.chartaccount_id : "" : "",
            month : this.props.formData ? this.props.formData.month ? this.props.formData.month.id : null : null,
            pay_mount : this.props.formData ? this.props.formData.pay_mount : null,
            remaining_amount : this.props.formData ? this.props.formData.remaining_amount : null,
            scolary_year : this.props.formData ? this.props.formData.yearId : null,
            title : this.props.formData && this.props.formData.id ? "Modifier le paiement "  : "Ajouter un paiement " ,
            error : "",
        })
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
        this.responsibles();
        this.students()
        this.months()
        this.schoolfeecategories()
        this.chartaccounts()
        this.getYear()
    }

    sms = (schoolfee) => {
        axios.get('schoolfees/sms/'+schoolfee).then((response) => {
            this.props.showModal()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                errors : {
                    sms : "sms non envoyé"
                }
            })
            toast('sms non envoyé', { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    render(){
        var optionsections = [], optionsectionclasses = [], optionresponsibles= [] , optionchartaccounts= [] , optionschoolfeecategories= [] , optionmonths = [], optionprofiles= [] , optionstudents= [];
        
        this.state.sections.map((section) => {
            optionsections.push({value : section, label : section.short_entitled_section + "  "+ section.entitled_section})
        });
        
        (this.state.students ?? []).map((student) => {
            var user_id =  student.user.user.id;
            optionstudents.push({
                value : student.id, 
                label : student.matricule + " " + student.user.last_name + " " + student.user.family_name + "  " + student.user.first_name, user_id : user_id,
                sectschoolfeecatstudcategories : student.sectschoolfeecatstudcategories
            })
        })

        this.state.responsibles.map((responsible) => {
            var last_name = responsible.last_name ? responsible.last_name : "";
            var family_name = responsible.family_name ? responsible.family_name : "";
            var first_name = responsible.first_name ? responsible.first_name :"";
            var tel = responsible.tel ? responsible.tel : "";
            optionresponsibles.push({value : responsible.id, label :  last_name + "  "+  family_name + "  "+  first_name +" "+ tel})
        });

        this.state.chartaccounts.map((chartaccount) => {
            optionchartaccounts.push({value : chartaccount.id, label :  chartaccount.wording + " " + chartaccount.currency})
        });

        // if(this.state.responsible)
        // {
        //     if(this.state.responsible.profiles)
        //     {
        //         this.state.responsible.profiles.map((profile) => {
        //             optionprofiles.push({ value : profile.id, label : profile.profile})
        //         })
        //     }
        // }

        this.state.months.map((month) => {
            optionmonths.push({ value : month.id, label : month.month})
        })

        this.state.students.length > 0 && (
            this.state.schoolfeecategories.filter(dat => dat.id == this.props.schoolfeecategory.id).map((choolfeecategory) => {
                
                return(
                    choolfeecategory.sectionschoolfeecategories.filter(data => this.state.section && data.section.id == this.state.section.id).map((data) => {
                        var categorie = "", price = data.price;
                        this.state.sectschoolfeecatstudcategories.filter(val => val.sectschoolfeecatstudentcat.sectionschoolfeecategory.id == data.id).map((value) => {
                            categorie = " / "+ value.sectschoolfeecatstudentcat.price  + " "+data.currency+ " "+ value.sectschoolfeecatstudentcat.categorystudent.category_student;
                            price = value.sectschoolfeecatstudentcat.price;
                        })
                        optionschoolfeecategories.push({ value : data.id, label : choolfeecategory.category + " "+data.price + " "+data.currency + " " + categorie, price : price > 0 ? price : data.price })
                    })
                )
            })
        )

        if(this.state.section)
        {
            this.state.section.sectionclasses.map((sectionclasse) =>{
                optionsectionclasses.push({value : sectionclasse, label : sectionclasse.class.short_entitled_class + " " + sectionclasse.title})
            })
        }

        var optionsms = [];
        optionsms.push({
            value : 1, label:"Oui"
        })
        optionsms.push({
            value : 0, label:"Non"
        })
        return(
            <div>
            <Modal 
                show={this.props.isModal} 
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title className="h6 font-monospace">{ this.state.title}   <span className="text-primary">{ this.props.schoolfeecategory ? this.props.schoolfeecategory.category : "" } { this.state.price }{ this.props.schoolfeecategory ? this.props.schoolfeecategory.currency : "" } </span> </Modal.Title>
                </Modal.Header>
                    <Modal.Body className="row">
                        <div className="text-center"><span className="text-danger text-small">{ this.state.error }</span></div>
                        <div className="form-group col-md-6 mt-2">
                            <label className="font-monospace fw-normal">Section</label>
                            <Select
                                className="form-control form-control-sm p-0"
                                placeholder={this.state.section && (this.state.section.short_entitled_section)}
                                value = {
                                    optionsections.filter(option => 
                                        this.state.section && option.value && option.value.id == this.state.section.id)
                                }
                                defaultValue={this.state.section}
                                onChange={
                                    (select) => {
                                        this.setState({
                                            section : select.value,
                                            // classe : "",
                                            // optionsectionclasses : [],
                                            student : "",
                                            optionstudents : []

                                        });
                                    }
                                }
                                options={optionsections}
                            />
                        </div>

                        <div className="form-group col-md-6 mt-2">
                            <label className="font-monospace fw-normal">Classe </label>
                            <Select
                                className="form-control form-control-sm p-0"
                                placeholder={  this.state.classe ?  this.state.classe.class ? this.state.classe.class.short_entitled_class + " " + this.state.classe.title :  "" : ""}
                                value = {
                                    optionsectionclasses.filter(option => 
                                        this.state.classe && option.value && option.value.id == this.state.classe.id)
                                }
                                defaultValue={this.state.classe}
                                onChange={
                                    this.handleInputChange
                                }
                                options={optionsectionclasses}
                            />
                        </div>

                        {/* <div className="form-group col-md-4 mt-2">
                            <label className="font-monospace fw-normal">Son profil</label>
                            <div className="input-group">
                                <Select
                                    className="form-control form-control-sm"
                                    defaultValue={this.state.profile}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                profile : select.value
                                            });
                                        }
                                    }
                                    options={optionprofiles}
                                />
                            </div>
                            <span className="text-danger text-small">{ this.state.errors.profile }</span>
                        </div> */}

                        <div className="form-group col-md-6 mt-2">
                            <label className="font-monospace fw-normal">Paiement de l'élève</label>
                            <div className=" ">
                                <Select 
                                    className="form-control form-control-sm p-0"
                                    value = {
                                        optionstudents.filter(option => 
                                        option.value == this.state.student)
                                    }
                                    defaultValue={this.state.student}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                student : select.value,
                                                responsible : select.user_id,
                                                sectschoolfeecatstudcategories : select.sectschoolfeecatstudcategories,
                                                // schoolfeecategory : "",
                                            });
                                        }
                                    }
                                    options={optionstudents}
                                />
                                {/* <button className="btn btn-outline-primary" type="button" id="button-addon2" onClick={() => this.showModal()}><FeatherIcon icon="user-plus"></FeatherIcon></button> */}
                            </div>
                        </div>

                        <div className="form-group col-md-6 mt-2">
                            <label className="font-monospace fw-normal">Paiement fait par </label>
                            <div className="">
                                <Select 
                                    className="form-control form-control-sm p-0"
                                    value = {
                                        optionresponsibles.filter(option => 
                                             option.value == this.state.responsible)
                                    }
                                    defaultValue={this.state.responsible}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                responsible : select.value,
                                                profile : null,
                                            });
                                        }
                                    }
                                    options={optionresponsibles}
                                />
                                {/* <button className="btn btn-outline-primary" type="button" id="button-addon2" onClick={() => this.showModal()}><FeatherIcon icon="user-plus"></FeatherIcon></button> */}
                            </div>
                        </div>

                        <div className={"form-group col-md-4 mt-2"}>
                            <label className="font-monospace fw-normal">Type de paiement</label>
                            <div className="">
                                <Select 
                                    className="form-control form-control-sm p-0"
                                    value = {
                                        optionschoolfeecategories.filter(option => 
                                        option.value == this.state.schoolfeecategory)
                                    }
                                    defaultValue={this.state.schoolfeecategory}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                schoolfeecategory : select.value, 
                                                price : select.price
                                            });
                                        }
                                    }
                                    options={optionschoolfeecategories}
                                />
                            </div>
                        </div>

                        <div className="form-group col-md-4 mt-2">
                            <label className="font-monospace fw-normal">Mois</label>
                            <div className=" ">
                            <Select
                                className="form-control form-control-sm p-0"
                                closeMenuOnSelect={false}
                                defaultValue={[ ]}
                                isMulti
                                options={optionmonths}
                                onChange={
                                    (select) => {
                                        this.setState({
                                            month : select
                                        });
                                    }
                                } 
                                />
                             </div>
                        </div>

                        <div className="col-md-4 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Montant payé</label>
                                <div className="position-relative mt-1">
                                    <input type="number" className="form-control form-control-sm" placeholder=""  onChange={this.handleInputMax} name="pay_mount"  value={this.state.pay_mount} id="nom-id-icon" />
                                    <div className="form-control-icon">
                                        <FeatherIcon icon="trello"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="form-group col-md-4 mt-2">
                            <label className="font-monospace fw-normal">Caisse</label>
                            <Select
                                className="form-control form-control-sm p-0"
                                defaultValue={this.state.account}
                                value = {
                                    optionchartaccounts.filter(option => 
                                    option.value == this.state.account)
                                }
                                onChange={
                                    (select) => {
                                        this.setState({
                                            account : select.value
                                        });
                                    }
                                }
                                options={optionchartaccounts}
                            />
                        </div>
                        

                        <div className=" col-md-4 mt-2">
                            <label className="font-monospace fw-normal">Année scolaire</label>
                                <Select
                                    className="form-control form-control-sm p-0"
                                    value = {
                                        this.state.years.filter(option => 
                                        option.value == this.state.scolary_year)
                                    }
                                    defaultValue={this.state.scolary_year}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                scolary_year : select.value
                                            });
                                        }
                                    }
                                    options={this.state.years}
                                />
                        </div>

                        <div className="form-group col-md-4 mt-2 d-none">
                            <label className="font-monospace fw-normal">Notifier par sms</label>
                                <Select 
                                    className="form-control form-control-sm p-0"
                                    value = {
                                        optionsms.filter(option => 
                                        option.value == this.state.sms)
                                    }
                                    defaultValue={this.state.sms}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                sms : select.value
                                            });
                                        }
                                    }
                                    options={optionsms}
                                />
                                {/* {
                                    this.state.id && (
                                        <button className="btn btn-outline-primary" type="button" id="button-addon2" onClick={() => this.sms(this.state.id)}>{this.props.formData ? this.props.formData.notifiant > 0 ? this.props.formData.notifiant  : "0" : "0"}<FeatherIcon icon="mail" ></FeatherIcon></button>
                                    )
                                } */}
                        </div>
                        

                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm  p-0 px-1"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                        <button onClick={() => this.storeShoolfee()} className="btn btn-sm btn-outline-primary  p-0 px-1"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                    </Modal.Footer>
            </Modal>
            

            {/* <ParentfamilyForm 
                isModal={this.state.isModal} 
                formData={{}} 
                showModal={this.showModal}
            /> */}
        </div>
        )
    }
}
export default ShoolfeeForm;