import axios from "axios";
import React, { Component } from "react";
import Listprint from "../../Listprint";
import ShoolfeeInvoice from "./ShoolfeeInvoice";
import FeatherIcon from "feather-icons-react";
import ShoolfeeInvoiceList from "./ShoolfeeInvoiceList";
import Loading from "../../Loading";
import SchoolfeeLine from "./SchoolfeeLine";
import { toast } from "react-toastify";

class SchoolfreeDette extends Component {
  state = {
    isModal: false,
    loading: false,
    formData: null,
    currentUrl : "",
    printInvoice: false,
    input: [],
    students: [],
    currency: [],
    rows: [],
    labels: [],
    data: [],
    months : [],
  };

  componentDidMount() {
    this.setState({
      currentUrl : this.props.match.params.type
    })
    this.getMonth()
    this.orderBy();
  }

  showModalInvoice = (schoolfee = null) => {
    this.setState({
      isModal: !this.state.isModal,
      formData: schoolfee,
    });
  };

  async getMonth(){
    var months = await axios.get("months");
    if(months.status === 200){
      this.setState({
        months : months.data
      })
    }
  }
  
  async orderBy() {
    var schoolfees = await axios.get("schoolfees");
    var today = new Date();
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyy = today.getFullYear() - 1 + "/" + today.getFullYear();

    if (schoolfees.status === 200) {
      var rows = [],
        labels = [],
        data = [],
        border = "",
        currency = [],
        dette = 0,
        first_name = "";
      schoolfees.data.map((schoolfee) => {
        if (schoolfee.sectionschoolfeecategory) {
          if ((this.props.match.params.type == "dettes" && schoolfee.pay_mount < schoolfee.sectionschoolfeecategory.price) ||
              (this.props.match.params.type == "avances" && schoolfee.month && schoolfee.month.id > mm)
            ) {
            first_name = schoolfee.student ? schoolfee.student.user.first_name ? schoolfee.student.user.first_name : "" : "";
            dette =
              this.props.match.params.type == "dettes" ? 
                  schoolfee.sectionschoolfeecategory ? 
                    schoolfee.sectionschoolfeecategory.price - schoolfee.pay_mount
                  : schoolfee.sectionschoolfeecategory.price - schoolfee.pay_mount
                  : schoolfee.pay_mount - schoolfee.sectionschoolfeecategory.price;
            border =
              schoolfee.operation === "Archiver"
                ? "border-success text-success"
                : schoolfee.operation === "Supprimer"
                ? "border-danger text-danger"
                : "border-primary text-primary";
            if ( (this.props.match.params.type == "dettes" && dette > 0 && schoolfee.sectionschoolfeecategory) ||
                (this.props.match.params.type == "avances" && schoolfee.sectionschoolfeecategory)
            ){
              var curr = currency.filter( (mone) => mone.currency == schoolfee.sectionschoolfeecategory.currency.toLowerCase() ).length;
              if (curr == 0)
                currency.push({ currency: schoolfee.sectionschoolfeecategory.currency.toLowerCase() });

              if (schoolfee.month.id) {
                var count = labels.filter( (label) => label.month == schoolfee.month.month && label.currency == schoolfee.sectionschoolfeecategory.currency.toLowerCase() ).length;
                
                if (count == 0) {
                  labels.push({ 
                      month_id: schoolfee.month.id,
                      month: schoolfee.month.month,
                      currency: schoolfee.sectionschoolfeecategory.currency.toLowerCase(),
                  });
                }
                var somme = this.props.match.params.type == "dettes" ? dette : schoolfee.pay_mount;

                data.push({
                  somme: somme,
                  month: schoolfee.month.month,
                  currency: schoolfee.sectionschoolfeecategory.currency.toLowerCase(),
                });
              }

              rows.push({
                matricule: schoolfee.student && (
                  <span>
                    <a
                      href={"/students/" + schoolfee.student.id}
                      className="text-decoration-none"
                    >
                      {schoolfee.invoice}
                    </a>{" "}
                    <input
                      type={"checkbox"}
                      onChange={this.handleInput}  value={ schoolfee.id }
                      className="rounded rounded table_action"
                    />{" "}
                  </span>
                ),
                student:
                  schoolfee.student &&
                  schoolfee.student.matricule +
                    " " +
                    schoolfee.student.user.last_name +
                    " " +
                    schoolfee.student.user.family_name +
                    " " +
                    first_name,
                classe:
                  schoolfee.student.sectionclass &&
                  schoolfee.student.sectionclass.class.short_entitled_class +
                    " " +
                    schoolfee.student.sectionclass.title +
                    " " +
                    schoolfee.student.sectionclass.section.short_entitled_section,
                mount:
                  schoolfee.pay_mount?.toLocaleString() +
                  " " +
                  schoolfee.sectionschoolfeecategory.currency,
                diffence:
                  dette > 0
                    ? dette?.toLocaleString() + "" + schoolfee.sectionschoolfeecategory.currency
                    : "",
                month: schoolfee.month ? schoolfee.month.month : "",
                year: schoolfee.year.year,
                category:
                  schoolfee.sectionschoolfeecategory &&
                  schoolfee.sectionschoolfeecategory.schoolfeecategory.category,
                caisse:
                  schoolfee.accountingbalance &&
                  schoolfee.accountingbalance.chartaccount &&
                  schoolfee.accountingbalance.chartaccount.wording + " "+ schoolfee.accountingbalance.chartaccount.currency,
                created_at: (
                  <small className="size-13">
                    {schoolfee.operation === "Ajouter"
                      ? schoolfee.createdAt.slice(0 ,10)
                      : schoolfee.updatedAt.slice(0 ,10) }{" "}
                    <span
                      className={
                        "size-10 px-1 border border-1 table_action rounded rounded-3 " +
                        border
                      }
                    >
                      {schoolfee.operation}
                    </span>
                    <button
                      onClick={() => this.showModalInvoice(schoolfee)}
                      className="btn btn-outline-primary rounded-3 btn-sm mx-1 table_action"
                      title="facture de ce enregistrement"
                    >
                      <FeatherIcon icon="file" size={16}></FeatherIcon>{" "}
                    </button>
                  </small>
                ),
              });
            }
          }
        }
      });

      var columns =
        this.props.match.params.type == "dettes"
          ? [
              {
                label: "Fact",
                field: "matricule",
                sort: "asc",
                width: 200,
              },
              {
                label: "Eleve",
                field: "student",
                sort: "asc",
                width: 200,
              },
              {
                label: "Classe",
                field: "classe",
                sort: "asc",
                width: 150,
              },
              {
                label: "Montant",
                field: "mount",
                sort: "asc",
                width: 150,
              },
              {
                label: "Dette",
                field: "diffence",
                sort: "asc",
                width: 150,
              },
              {
                label: "Mois",
                field: "month",
                sort: "asc",
                width: 150,
              },
              {
                label: "Année",
                field: "year",
                sort: "asc",
                width: 150,
              },
              {
                label: "Frais",
                field: "category",
                sort: "asc",
                width: 120,
              },
              {
                label: "Caisse",
                field: "caisse",
                sort: "asc",
                width: 120,
              },
              {
                label: "Date",
                field: "created_at",
                sort: "asc",
                width: 120,
              },
            ]
          : [
              {
                label: "Fact",
                field: "matricule",
                sort: "asc",
                width: 200,
              },
              {
                label: "Eleve",
                field: "student",
                sort: "asc",
                width: 200,
              },
              {
                label: "Classe",
                field: "classe",
                sort: "asc",
                width: 150,
              },
              {
                label: "Montant",
                field: "mount",
                sort: "asc",
                width: 150,
              },
              {
                label: "Mois",
                field: "month",
                sort: "asc",
                width: 150,
              },
              {
                label: "Année",
                field: "year",
                sort: "asc",
                width: 150,
              },
              {
                label: "Frais",
                field: "category",
                sort: "asc",
                width: 120,
              },
              {
                label: "Caisse",
                field: "caisse",
                sort: "asc",
                width: 120,
              },
              {
                label: "Date",
                field: "created_at",
                sort: "asc",
                width: 120,
              },
            ];

      this.setState({
        currency: currency,
        data: data,
        labels: labels,
        rows: rows,
        loading: true,
        columns: columns,
      });
    }
  }


  handleInput = (e) => {

    var data = [];
    var input = this.state.input;
    this.setState({
        input : []
    })
    var schoolfee =  e.target.value;
    var count = input.some(item => schoolfee=== item)
    if(count)
    {
        data = input.filter(item => item != schoolfee);
    }
    else{
        data = input;
        data.push(schoolfee)
    }
    
    this.setState({
        input : data
    })
}

  printInvoice = () => {
    this.setState({
      printInvoice: !this.state.printInvoice,
    });
  };

  delete = (schoolfee) => {
    axios
      .delete("schoolfees/" + schoolfee)
      .then((response) => {
        this.getStudents();
        toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
      })
      .catch((error) => {
        toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
      });
  };

  render() {
    if(this.state.currentUrl != "" && this.state.currentUrl !== this.props.match.params.type)
    window.location.replace("/schoolfees/" + this.props.match.params.type )

    return (
      <div className="overflow-hidden">
        <div className=" pt-3 pb-2 mb-3 border-bottom">
          <h5 className="h5 font-monospace">
            Liste des paiements {this.props.match.params.type} des élèves
          </h5>
        </div>
        {!this.state.loading && <Loading />}

        {this.state.rows.length > 0 && (
          <div className="overflow-hidden small">
            <Listprint
              columns={this.state.columns}
              rows={this.state.rows}
              title={
                "Liste des paiements " +
                this.props.match.params.type +
                " des élèves"
              }
            />
          </div>
        )}

        {this.state.input.length > 0 && (
          <div>
            <button
              className="btn btn-primary btn-sm"
              onClick={() => this.printInvoice()}
            >
              Imprimer la selection
            </button>
          </div>
        )}

        <div className="row">
        {this.state.currency.length > 0 &&
          this.state.currency.map((current) => {
            var labels = [],
              some = 0,
              data = [];
              this.state.months.map(mon =>{
                this.state.labels.filter((money) => mon.month == money.month && money.currency == current.currency).map((label) => {
                    some = 0;
                    this.state.data.filter((mon) =>mon.month == label.month && mon.currency == label.currency).sort((a, b) => (a.month_id > b.month_id ? 1 : -1)).map((val) => {
                        some = some + val.somme;
                      });
                    data.push(some);
                    labels.push(mon.month);
                })
                if(this.state.labels.filter((money) => mon.month == money.month && money.currency == current.currency).length === 0)
                {
                  data.push(0);
                  labels.push(mon.month) ;
                }
              });
            return (
              <div className="" key={ current.currency }>
                <div className="card shadow overflow-hidden  mb-3">
                <h5 className="text-center my-2 font-monspace">
                  {"Statistique des paiements " +
                    this.props.match.params.type +
                    " " +
                    current.currency.toUpperCase()}
                </h5>
                <SchoolfeeLine
                  labels={labels}
                  data={data}
                  title={
                    "Statistique des paiements " +
                    this.props.match.params.type +
                    " " +
                    current.currency.toUpperCase()
                  }
                />
                </div>
              </div>
            );
          })}
        </div>
        {this.state.formData && this.state.formData.id && (
          <ShoolfeeInvoice
            isModal={this.state.isModal}
            formData={this.state.formData.id}
            schoolfeecategory={this.state.schoolfeecategory}
            showModal={this.showModalInvoice}
          />
        )}

        {this.state.input.length > 0 && (
          <ShoolfeeInvoiceList
            isModal={this.state.printInvoice}
            formData={this.state.input}
            showModal={this.printInvoice}
          />
        )}
      </div>
    );
  }
}
export default SchoolfreeDette;
