import axios from "axios";
import React, {Component} from "react";
import Resizer from "react-image-file-resizer";
import Loading from "../Loading";

class ProfilImage extends Component{

    state = {
        id :  null,
        loading : false,
        loadingForm : false,
        btn : false,
        dataImage : "",
        image : [],
        error : "",
        errorImg : "",
        valid : "",
        user : {},
    }

    componentDidMount(){
    }

    handleInputFile = (e) => {

        this.setState({
            image : [],
            btn : false,
            dataImage : e.target.files[0],
        })
        for(var i = 0 ; i < e.target.files.length; i++)
        {
            this.setState({
                loading : true
            })
            var img = "";
            try {
            img =  Resizer.imageFileResizer(
                  e.target.files[i],
                  1500,
                  1500,
                  "JPEG",
                  100,
                  0,
                  (uri) => {
                        this.state.image.push(uri);
                    this.setState({ image: this.state.image });
                  },
                  "base64",
                  1000,
                  1000
                );
            } catch (err) {
                console.log(err);
            }
        }
        this.setState({
            errorImg : "",
            loading : false,
            loadingForm : false,
            dataImage : e.target.files[0],
        })
    }

    storeImage = async (e) => {
        e.preventDefault()
        this.setState({
            errorImg : "",
            loading : true,
            btn : !this.state.btn
        })

        let formData = new FormData()
        formData.append('files', this.state.dataImage)
        formData.append('userId', this.props.userId)

        const response = await fetch(process.env.REACT_APP_APIEVIVESOMA + '/files', {
            method: "POST",
            body: formData 
        })
        
        if (response.status === 200)
        {
            this.setState({
                loading : false,
                errorImg : "saved image",
                btn : !this.state.btn
            })
            var user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : false;
            if(user && user.id == this.props.userId)
                this.refreshToken();
        }
        else{
            this.setState({
                loading : false,
                errorImg : "error",
                btn : !this.state.btn
            })
        }
    }
  
    async refreshToken () {
        try{
            var token = await axios.get('token');
            if(token.status == 200)
            {
                var user = (token.data.data);
                localStorage.setItem("user", JSON.stringify(user));
                window.location.replace(process.env.REACT_APP_EVIVESOMA_COMPTA + `/profile`)
            }
            else{
                axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
                window.localStorage.clear();
                window.location.replace(process.env.REACT_APP_EVIVESOMA_COMPTA + `/login`)
            }
        }catch(error) {
            axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
            window.localStorage.clear();
            window.location.replace(process.env.REACT_APP_EVIVESOMA_COMPTA + `/login`)
        }
    }

    render(){
       
        return( 
            <div className=" ">
                <div className="card shadow overflow-hidden mb-3">
                    <div className="card-body">
                    <form onSubmit={this.storeImage}>
    
                        <h6 className="h6 font-monospace">Photo de profil</h6>
                        <div className="">
                            <label>Photo</label>
                            {
                                this.state.image.map((img, index) => {
                                    return(
                                        <img src={img} alt="" key={index} className="rounded-circle img-fluid" width={"100"} />
                                    )
                                })
                            }
                            <input type={"file"} className="form-control form-control-sm" name="file" onChange={this.handleInputFile} />
                        </div>
                            {
                                this.state.loading && (
                                    <Loading />
                                )
                            }
                        <div className=" pt-2 text-center">
                            <span className="text-danger"> { this.state.errorImg }</span>
                            <button className="btn btn-sm btn-primary py-1 rounded rounded-3"  disabled={this.state.btn}  type='submit' >Modifier la photo</button>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        )
    }
}
export default ProfilImage;