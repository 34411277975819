import React, {Component} from "react";
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js'
import FeatherIcon from 'feather-icons-react';
import { Helmet } from 'react-helmet';
import SchoolfeeList from "./cp/schoolfees/SchoolfeeList";
import axios from "axios";
import Listprint from "./Listprint";
import SchoolfeeLine from "./cp/schoolfees/SchoolfeeLine";

Chart.register(...registerables)

class Dashboard extends Component{

    state = {
        schoolfees : [],
        expenses : [],
        wages : [],
        months: [],
        datasets: [
          {
            label: 'Rainfall',
            fill: false,
            lineTension: 0.5,
            backgroundColor: 'rgba(75,192,192,1)',
            borderColor: '#045fc0',
            borderWidth: 2,
            data: [47, 35, 66, 52, 33, 44, 20, 66, 12, 22, 44, 59]
          }
        ]
      }

      componentDidMount(){
        this.refreshToken()
        this.getStudents()
        this.expenses()
        this.getMonths()
      }

      async getMonths(){
        var months = await axios.get("months");
        if(months.status === 200)
        {
            this.setState({
                months : months.data
            })
        }
    }

      async refreshToken () {
        try{
            var token = await axios.get('token');
            if(token.status === 200)
            {
                var user = (token.data.data);
                localStorage.setItem("user", JSON.stringify(user));
            }
            else{
                axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
                window.localStorage.clear();
                window.location.replace(process.env.REACT_APP_EVIVESOMA_COMPTA + `/login`)
            }
        }catch(error) {
            axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
            window.localStorage.clear();
            window.location.replace(process.env.REACT_APP_EVIVESOMA_COMPTA + `/login`)
        }
    }

      async getStudents(){
        const schoolfees = await axios.get('schoolfees')
        if(schoolfees.status === 200){
            this.setState({
                schoolfees : schoolfees.data
            })
        }
      }

      async expenses(){
          const expenses = await axios.get('expenses/operation/all')

          if(expenses.status === 200)
          {
              var expenseData = [] , wage = [];
              expenses.data.map((expense) => {
                  
                    var somme = 0, currency ="";
                    expense.expensedetails.map((expensedetail) =>{
                        somme = somme + expensedetail.amount
                        currency = expensedetail.chartaccount.currency

                        if(expense.type_expense == "Expense")
                        {

                            expenseData.push({
                                staff : expensedetail.staff && (expensedetail.staff.user.last_name +" "+ expensedetail.staff.user.first_name),
                                accounting : expensedetail.chartaccount.wording,
                                mount : expensedetail.amount + " " + currency,
                                reason : expensedetail.reason,
                                month : expense.month.month,
                                year : expense.year.year, 
                            })
                        }
                        else if(expense.type_expense == "Wage"){
                            wage.push({
                                accounting : expensedetail.chartaccount.wording,
                                user : expense.staff && (expense.staff.user.last_name +" "+ expense.staff.user.first_name),
                                staff : expensedetail.staff && (expensedetail.staff.user.last_name +" "+ expensedetail.staff.user.first_name),
                                mount : expensedetail.amount + " " + currency,
                                month : expense.month.month,
                                year : expense.year.year,
                            })
                            
                        }
                    })
                })
 
              
              this.setState({
                  wages : wage,
                  expenses : expenseData
              })

              
          }
      }

    render(){
        var 
        currency = [],
        labels = [],
        data = [];

            this.state.schoolfees.sort((secondItem, firstItem) => firstItem.scolary_year - secondItem.scolary_year).map((schoolfee, index) => {
                    var curr = currency.filter( (mone) => schoolfee.sectionschoolfeecategory && mone.currency ==  schoolfee.sectionschoolfeecategory.currency.toLowerCase()  ).length;
                    if (curr == 0)
                      currency.push({ currency: schoolfee.sectionschoolfeecategory ? schoolfee.sectionschoolfeecategory.currency.toLowerCase() :  "" });
                    
                    if (schoolfee.month) {
                      var count = labels.filter( (label) => label.month == schoolfee.month.month && schoolfee.schoolfeecategory && label.currency == schoolfee.schoolfeecategory.currency ? schoolfee.schoolfeecategory.currency.toLowerCase() : ""  ).length;
                      if (count == 0) {
                        labels.push({
                          month_id: schoolfee.month.id,
                          month: schoolfee.month.month,
                          currency: schoolfee.sectionschoolfeecategory ? schoolfee.sectionschoolfeecategory.currency.toLowerCase() :  "",
                        });
                      }
                      data.push({
                        somme: schoolfee.pay_mount,
                        accountingbalanceexpensedetail : schoolfee.accountingbalance ? schoolfee.accountingbalance.accountingbalanceexpensedetails : [],
                        expense : schoolfee.accountingbalance ? schoolfee.accountingbalance.credit > 0 ? schoolfee.accountingbalance.credit : 0 : "",
                        month: schoolfee.month.id,
                        currency: schoolfee.sectionschoolfeecategory ? schoolfee.sectionschoolfeecategory.currency.toLowerCase() :  "",
                      });
                    }
                    
                }) 
        
        return(
            <div>
                <Helmet>
                    <title>{ process.env.REACT_APP_NAME } | dashboard paiement</title>
                </Helmet>

                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom mb-2">
                    <h5 className="h5 font-monospace">Dashboard Paiement</h5>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        
                        <button type="button" className="btn btn-sm btn-outline-teal dropdown-toggle">
                            <FeatherIcon icon="calendar"  size="17" className={"text-dark"}></FeatherIcon>
                            Année actuelle
                        </button>
                    </div>
                </div>

                <div className="">
          {currency.length > 0 &&
            currency.map((current) => {
                
              var labelss = [],
                some = 0,
                datas = [];
                this.state.months.map((month) =>{
                        some = 0;
                        
                        data.filter((mon) => mon.month == month.id && mon.currency == current.currency).map((val) => {
                                some = some + val.somme;
                            });
                            
                        datas.push(some);
                        labelss.push(month.month);
                })
              if(datas.length > 0){
                return (
                <div className=" card shadow mb-3 overflow-hidden">
                    <div className="card-body">
                    <h6 className="text-center my-2 font-monspace"> {"Paiement mensuel en " + current.currency.toUpperCase() }</h6>
                    <SchoolfeeLine
                        labels={labelss}
                        data={datas}
                        title={"Paiement mensuel en " + current.currency.toUpperCase() }
                    />
                    </div>
                </div>
                );
              }
            })}
        </div>


            <div className="row mt-4">

                <div className="col-md-12">
                    <SchoolfeeList orderBy={"all"} title="Dernières opérations sur paiements" />
                </div>



                <div className="col-md-6">
                    <div className="card shadow mb-4  overflow-hidden rounded rounded-3">
                        <div className="card-body">
                        <div className="h6 border-bottom pb-2 font-monospace">Dernières opérations salaires</div>
                        {
                            this.state.wages.length > 0 && (
                                <Listprint columns={[
                                    {
                                        label: 'Personnel',
                                        field: 'user',
                                        sort: 'asc',
                                        width: 200
                                    },
                                    {
                                        label: 'Montant',
                                        field: 'mount',
                                        sort: 'asc',
                                        width: 150
                                    },
                                    {
                                        label: 'Caisse',
                                        field: 'accounting',
                                        sort: 'asc',
                                        width: 150
                                    },
                                    {
                                        label: 'Mois',
                                        field: 'month',
                                        sort: 'asc',
                                        width: 150
                                    },
                                    {
                                        label: 'Année',
                                        field: 'year',
                                        sort: 'asc',
                                        width: 120
                                    },
                                    {
                                        label: 'Opérateur',
                                        field: 'staff',
                                        sort: 'asc',
                                        width: 150
                                    },
                                ]} rows={this.state.wages} title={"Dernières opérations salaires"}/>
                            )
                        }
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card shadow mb-4  overflow-hidden rounded rounded-3">
                        <div className="card-body">
                        <div className="h6 border-bottom pb-2 font-monospace">Dernières opérations depenses</div>
                        {
                            this.state.expenses.length > 0 && (
                                <Listprint columns={[
                                    {
                                        label: 'Montant',
                                        field: 'mount',
                                        sort: 'asc',
                                        width: 150
                                    },{
                                        label: 'Motif',
                                        field: 'reason',
                                        sort: 'asc',
                                        width: 150
                                    },
                                    {
                                        label: 'Compte',
                                        field: 'accounting',
                                        sort: 'asc',
                                        width: 200
                                    },
                                    {
                                        label: 'Mois',
                                        field: 'month',
                                        sort: 'asc',
                                        width: 150
                                    },
                                    {
                                        label: 'Année',
                                        field: 'year',
                                        sort: 'asc',
                                        width: 120
                                    },
                                    {
                                        label: 'Opérateur',
                                        field: 'staff',
                                        sort: 'asc',
                                        width: 150
                                    },
                                ]} rows={this.state.expenses} title={"Dernières opérations depenses"}/>
                            )
                        }
                        </div>
                    </div>
                    
                </div>

            </div>

            </div>
        )
    }
}
export default Dashboard;