import React, {Component} from "react";
import SchoolfeeList from "../schoolfees/SchoolfeeList";

class Report extends Component{
    state = {
        start : "",
        end : "",
    }
    inputHundle = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    render(){
        return(
            <div>
                {/* <Helmet>
                    <title>{ process.env.REACT_APP_NAME } | reports payments students</title>
                </Helmet> */}
                <div className=" align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h5 className="h5 font-monospace">Rapport des paiements</h5>
                </div>
                <div className="col-md-12 overflow-hidden">
                    <SchoolfeeList orderBy={"getAll"} title="Rapport des paiements" page="report" />
                </div>
            </div>
        )
    }
}
export default Report