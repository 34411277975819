import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import { toast } from "react-toastify";

class   SchoolfeecategoryForm extends Component{

    state = {
        id : "",
        category : "",
        error : "",
        
        isModal : false,
        title : "",
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    storeSchoolfeecategory = (val = null) =>{
        var data = {
                category : this.state.category,
                operation : this.state.id ? (val === 1 ? "Archiver" : "Editer" ) : "Ajouter",
            }

        var request = this.state.id ? axios.put('schoolfeecategories/'+this.state.id, data) : axios.post('schoolfeecategories', data);
        request.then((response) =>{
            this.props.showModal()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }
  

    edit=()=>  {
        this.form()
    }

    form=()=>{
        this.setState({
            id: this.props.formData ? this.props.formData.id : "",
            category: this.props.formData ? this.props.formData.category : "",
            title : this.props.formData ? "Modifier le type de paiement" : "Ajouter le type de paiement",
            error : "",
        })
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

    render(){
        
        return(
            <div>
            <Modal 
                show={this.props.isModal} 
                onHide={this.props.showModal}
                
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title className="h6 font-monospace">{ this.state.title}</Modal.Title>
                </Modal.Header>
                    <Modal.Body className="row">


                    <div className=" mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Type de paiement</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm" onChange={this.handleInput} name="category"  value={this.state.category} id="nom-id-icon" />
                                    <div className="form-control-icon">
                                        <FeatherIcon icon="box"></FeatherIcon>
                                    </div>
                                </div>
                                <span className="text-danger text-small small">{ this.state.error }</span>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm p-0 px-1"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                        <button onClick={() => this.storeSchoolfeecategory()} className="btn btn-sm btn-outline-primary p-0 px-1"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                    </Modal.Footer>
            </Modal>
            
 
        </div>
        )
    }
}
export default SchoolfeecategoryForm;