import React, {Component} from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ProfilImage from "./ProfileImage";
import { toast } from "react-toastify";

class Profil extends Component{

    state = {
        id :  null,
        loading : false,
        btn : false,
        password : "",
        confirmed : "",
        first_name : "",
        last_name : "",
        family_name : "",
        email : null,
        tel : null,
        adress : "",
        error : "",
        valid : "",
        user : {},
    }

    componentDidMount(){
        this.user();
    }

    async user(){
        
        var user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : false;
        if(user){
            this.setState({ 
                id : user.id,
                first_name : user.first_name,
                last_name : user.last_name,
                family_name : user.family_name,
                avatar : user.avatar,
                tel : user.tel,
                email : user.email,
                adress : user.adress,
            })
        }
    }

    storeProfile = () =>{

        if(this.state.password == this.state.confirmed)
        {
            this.setState({
                error:  "",
                btn : !this.state.btn
            });

            var data = {
                    first_name : this.state.first_name,
                    last_name : this.state.last_name,
                    family_name : this.state.family_name,
                    email : this.state.email ? this.state.email : null,
                    tel : this.state.tel ? this.state.tel : null,
                    password : this.state.password ? this.state.password : null,
                    adress : this.state.adress,
                }

            var request = this.state.id ? axios.put('users/'+this.state.id, data) : window.location.replace(process.env.REACT_APP_EVIVESOMA_COMPTA + `/login`);
            request.then((response) =>{
                toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
                axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
                axios.post("login", {
                    username: this.state.tel > 0 ? this.state.tel : this.state.email,
                    password: this.state.password
                }).then((login) => {
                    localStorage.setItem("user", JSON.stringify(login.data.data));
                    this.setState({ 
                        id : login.data.data.id,
                        first_name : login.data.data.first_name,
                        last_name : login.data.data.last_name,
                        family_name : login.data.data.family_name,
                        avatar : login.data.data.avatar,
                        tel : login.data.data.tel,
                        email : login.data.data.email,
                        adress : login.data.data.adress,
                        error : "success",
                        btn : !this.state.btn
                    })
                }).catch((error) => {
                    window.localStorage.clear();
                    this.setState({
                        error: error.response.data.msg,
                        btn : !this.state.btn
                    });
                        toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
                });
            }).catch((error) => {
                this.setState({
                    error : error.response.data.msg,
                    btn : !this.state.btn
                })
                toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
            })
        }
        else{
            this.setState({
                error:  "Mot de passes differents"
            });
        }
    }
    
    storePassword = () =>{

        if(this.state.password == this.state.confirmed)
        {
            this.setState({
                error:  "",
                btn : !this.state.btn
            });

            var data = {
                password : this.state.password ? this.state.password : null,
            }

            axios.put('users/'+this.state.id, data).then((response) =>{
                this.setState({
                    error:  "succcess",
                    btn : !this.state.btn,
                    password : "",
                    confirm : ""
                });
                toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            }).catch((error) => {
                this.setState({
                    error : error.response.data.msg,
                    btn : !this.state.btn
                })
                toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
            })
        }
        else{
            this.setState({
                error:  "Mot de passes differents"
            });
        }
    }

    hundelInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render(){
        var profile = JSON.parse(localStorage.getItem('profile')) ? JSON.parse(localStorage.getItem('profile')) : {};
       
        return(
            <div className="row">
                <h5 className="h5 font-monospace">Profil</h5>
                <div className="col-md-7 col">
                    <div className="card shadow overflow-hidden mb-3 overflow-hidden rounded rounded-3">
                        <div className="card-body d-flex">
                            <div className="image mr-3">
                                <Link to={'#'}  className="text-decoration-none">
                                    <img src={ this.state.avatar } className="rounded-circle img-fluid me-2" width={(  this.state.avatar) && ("100px")} />
                                </Link>
                            </div>
                            <div>
                                <div>{  this.state.last_name  } {  this.state.family_name  } {  this.state.first_name  }</div>
                                <div><a href={"tel:"+  this.state.tel  } rel="noopener noreferrer" target="_blank" className="text-dark text-decoration-none"> <i className="ti ti-phone"></i> {  this.state.tel  }</a> </div>
                                <div><a href={"mailto:"+ this.state.email } rel="noopener noreferrer" target="_blank" className="text-dark text-capitalize text-decoration-none"> <i className="ti ti-mail"></i> { this.state.email}</a> </div>
                                <div><a href="#" rel="noopener noreferrer"   className="text-dark text-capitalize text-decoration-none"> <i className="ti ti-maps"></i> { this.state.adress}</a> </div>
                                <div className="text-center m-1">
                                    <span  className="badge bg-success small rounded-3 mx-1" type="button">{  profile.profile ? profile.profile.profile : "" }</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-5">
                    <ProfilImage userId={this.state.id} />
                </div>
 
                <div className="col-md-8">
                    <div className="card shadow overflow-hidden mb-3">
                        <div className="card-body row">
                            <h6 className="h6 font-monospace">Profil</h6>
                            <span className="text-primary text-small">{this.state.valid}</span>
                            <div className="col-md-4">
                                <label>Nom</label>
                                <input type={"text"} className="form-control form-control-sm" required={true} name="last_name" value={ this.state.last_name} onChange={this.hundelInput} />
                            </div>
                            <div className="col-md-4">
                                <label>Post-nom</label>
                                <input type={"text"} className="form-control form-control-sm" required={true} name="family_name" value={ this.state.family_name} onChange={this.hundelInput} />
                            </div>
                            <div className="col-md-4">
                                <label>Prénom</label>
                                <input type={"text"} className="form-control form-control-sm" required={true} name="first_name" value={ this.state.first_name} onChange={this.hundelInput} />
                            </div>
                            <div className="col-md-4">
                                <label>Adresse</label>
                                <input type={"text"} className="form-control form-control-sm" required={true} name="adress" value={ this.state.adress} onChange={this.hundelInput} />
                            </div>
                            <div className="col-md-4">
                                <label>Téléphone</label>
                                <input type={"number"} className="form-control form-control-sm" required={true} name="tel" value={ this.state.tel} onChange={this.hundelInput} />
                            </div>
                            <div className="col-md-4">
                                <label>Email </label>
                                <input type={"text"} className="form-control form-control-sm" required={true} name="email" value={ this.state.email} onChange={this.hundelInput} />
                            </div>
                            
                            <div className=" pt-2 text-center d-none">
                                <span className="text-danger text-small">{this.state.error}</span>
                                <button className="btn btn-sm btn-primary py-1 rounded rounded-3" disabled={this.state.btn}  onClick={() => this.storeProfile()}>Modifier le profil</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="card shadow overflow-hidden mb-3">
                        <div className="card-body row">
                            <h6 className="h6 font-monospace">Mot de passe</h6>
                            <span className="text-primary text-small">{this.state.valid}</span>
                            
                            <div className="col-md-6">
                                <label>Nouveau</label>
                                <input type={"password"} className="form-control form-control-sm" required={true} name="password" onChange={this.hundelInput} />
                        </div>
                            <div className="col-md-6">
                                <label>Confirmer</label>
                                <input type={"password"} className="form-control form-control-sm" required={true} name="confirmed" onChange={this.hundelInput} />
                            </div>
                            <div className=" pt-2 text-center">
                                <span className="text-danger text-small">{this.state.error}</span>
                                <button className="btn btn-sm btn-primary py-1 rounded rounded-3" disabled={this.state.btn}  onClick={() => this.storePassword()}>Modifier le mot de passe</button>
                            </div>
                        </div>
                    </div>
                </div> 

            </div>
        )
    }
}
export default Profil;