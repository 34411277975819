import React, {Component} from "react";
import { Link } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import axios from "axios";
import Collapsible from 'react-collapsible';
import Modal from "react-bootstrap/Modal";
import Loading from "../pages/Loading";

class Sidebar extends Component{

  state = {
    countData : 0,
    modal : false,
    isModal : false,
    schoolfeecategories : [],
    table : []
  }
  
  componentDidMount(){
    this.getSchoolfeecategories();
    this.getData()
  }

  async getSchoolfeecategories(){
    var schoolfeecategories = await axios.get('schoolfeecategories');
    if(schoolfeecategories.status === 200)
    {
        this.setState({schoolfeecategories : schoolfeecategories.data}) 
    }
  }

  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }

  logout = (e) => {
    axios.post('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
    window.localStorage.clear();
    window.location.replace('/')
  }

  async getData(){
    var data = await axios.get('datamigrates');
    if(data.status == 200)
    {
      this.setState({
        countData : data.data.length,
      })
    }
  }

  refresh = () => {
    this.setState({
      modal : true,
      isModal : false
    })
    axios.get('datamigrates/pull').then((response) => {
        this.setState({
          modal : true,
          isModal : true
        })
    }).catch((error) => {
      console.log(error)
    })
    this.refreshsend()
  }

  refreshsend = () => {
    this.setState({
      modal : true,
      isModal : false
    })
    axios.get('datamigrates/push').then((response) => {
      
        this.setState({
          modal : true,
          isModal : true
        })
    }).catch((error) => {
      console.log(error)
      // this.refresh()
    })
  }

  modal = () =>{
    this.setState({
      modal : false,
      isModal : false
    })
  }


  change = (val) => {
    this.setState({
      table : []
    })
    var table = [];
    table.push(val)
    this.setState({
      table : table
    })
    this.getData()
  }


    render(){
      var schoolfeecategories = 
        this.state.schoolfeecategories.map((schoolfeecategory) => {
          return(
            <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == schoolfeecategory.category).map((val) => { return "active" })} onClick={() => this.change(schoolfeecategory.category)}   key={schoolfeecategory.id} aria-current="page" to={"/" + schoolfeecategory.id + "/schoolfees"}>
              <FeatherIcon icon="credit-card" size={"16"}></FeatherIcon>
              <span className="ms-2 d-none d-sm-inline">{ schoolfeecategory.category }</span>
            </Link>
          )
        })

        return(

          <div className="col-auto col-md-3 col-xl-2 bg-white p-0 border border-top-0">
                <div className="sticky-top d-flex flex-column text-white min-vh-100">
                    <a href="/" className="text-white text-decoration-none shadow text-lef font-weight-bold"  style={{ backgroundColor : "rgb(0, 108, 189)", fontWeight : 700}}>
                        <span className="fs-4 d-sm-inline"><img src="/logo192.png" alt="logo evive" className="" height={39+"px"}  /></span>
                        <span className="ms-2 fs-6 d-none d-sm-inline">{ process.env.REACT_APP_NAME }</span>
                    </a>
                    
                    <ul className="nav nav-pills flex-column mb-sm-auto ms-2 mt-2" id="menu">
                    {
                      this.handleCheck("Afficher tableau de bord") == true && (
                        <li className="nav-item">
                          <Link title="Dashboard" className={"nav-link p-2 "+ this.state.table.filter(data => data == "/").map((val) => { return "active" })} onClick={() => this.change("/")} aria-current="page" to={"/"}>
                            <FeatherIcon icon="activity"  size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Dashboard</span>
                          </Link>
                        </li>
                      )
                    }
                    
                    {
                      this.handleCheck("Afficher paiement") == true && (
                        <li className="nav-item">
                          <Link className="nav-link p-2 d-flex bd-highlight" aria-current="page" to={"#"}>
                            <Link title="Paiements" to={"/schoolfees"} className={"text-dark "+ this.state.table.filter(data => data == "schoolfees").map((val) => { return "active" })} onClick={() => this.change("schoolfees")}><FeatherIcon icon="credit-card" size={"16"} className="flex-shrink-1 bd-highlight"></FeatherIcon></Link>
                            <span className="ms-2 d-none d-sm-inline w-100 bd-highlight bg-white">
                              <Collapsible trigger={" Frais scolaire"} className="text-dark">
                                <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "schoolfeesall").map((val) => { return "active" })} onClick={() => this.change("schoolfeesall")}  aria-current="page" to={"/schoolfeesall"}>
                                  <FeatherIcon icon="folder" size={"16"}></FeatherIcon>
                                  <span className="ms-2 d-none d-sm-inline">{"Recouvrements"}</span>
                                </Link>
                                <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "schoolfees").map((val) => { return "active" })} onClick={() => this.change("schoolfees")}  aria-current="page" to={"/schoolfees"}>
                                  <FeatherIcon icon="bookmark" size={"16"}></FeatherIcon>
                                  <span className="ms-2 d-none d-sm-inline">{"Tous paiements"}</span>
                                </Link>
                                  { schoolfeecategories }
                                <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "schoolfees/dettes").map((val) => { return "active" })} onClick={() => this.change("schoolfees/dettes")}  aria-current="page" to={"/schoolfees/dettes"}>
                                  <FeatherIcon icon="folder" size={"16"}></FeatherIcon>
                                  <span className="ms-2 d-none d-sm-inline">{"Dettes"}</span>
                                </Link>
                                <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "schoolfees/avances").map((val) => { return "active" })} onClick={() => this.change("schoolfees/avances")}  aria-current="page" to={"/schoolfees/avances"}>
                                  <FeatherIcon icon="briefcase" size={"16"}></FeatherIcon>
                                  <span className="ms-2 d-none d-sm-inline">{"Avances"}</span>
                                </Link>
                              </Collapsible>
                            </span>
                          </Link>
                        </li>
                      )
                    }
                    {
                      this.handleCheck("Afficher don") == true && (
                        <li className="nav-item">
                          <Link title="Don" className={"nav-link p-2 "+ this.state.table.filter(data => data == "gifts").map((val) => { return "active" })} onClick={() => this.change("gifts")} aria-current="page" to={"/gifts"}>
                            <FeatherIcon icon="layout"  size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Don</span>
                          </Link>
                        </li>
                      )
                    }
                    {
                      this.handleCheck("Afficher caisse") == true && (
                        <li className="nav-item">
                          <Link title="Caisse" className={"nav-link p-2 "+ this.state.table.filter(data => data == "chartaccounts").map((val) => { return "active" })} onClick={() => this.change("chartaccounts")} aria-current="page" to={"/chartaccounts"}>
                            <FeatherIcon icon="codepen"  size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Etat financier</span>
                          </Link>
                        </li>
                      )
                    }

                    {
                      this.handleCheck("Afficher livre caisse") == true && (
                        <li className="nav-item">
                          <Link title="livre caisse" className={"nav-link p-2 "+ this.state.table.filter(data => data == "accountingbalances").map((val) => { return "active" })} onClick={() => this.change("accountingbalances")} aria-current="page" to={"/accountingbalances"}>
                            <FeatherIcon icon="codesandbox"  size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Livre de caisse</span>
                          </Link>
                        </li>
                      )
                    }
                    {
                      this.handleCheck("Afficher salaire") == true && (
                        <li className="nav-item">
                          <Link title="Salaire" className={"nav-link p-2 "+ this.state.table.filter(data => data == "wages").map((val) => { return "active" })} onClick={() => this.change("wages")} aria-current="page" to={"/wages"}>
                            <FeatherIcon icon="box"  size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Fiche de paie</span>
                          </Link>
                        </li>
                      )
                    }
                    {
                      this.handleCheck("Afficher depense") == true && (
                        <li className="nav-item">
                          <Link title="Depense" className={"nav-link p-2 "+ this.state.table.filter(data => data == "expenses").map((val) => { return "active" })} onClick={() => this.change("expenses")} aria-current="page" to={"/expenses"}>
                            <FeatherIcon icon="framer"  size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Depenses</span>
                          </Link>
                        </li>
                      )
                    }

                    {
                      this.handleCheck("Afficher type de paiement") == true && (
                        <li className="nav-item">
                          <Link title="Type paiement" className={"nav-link p-2 "+ this.state.table.filter(data => data == "schoolfeecategories").map((val) => { return "active" })} onClick={() => this.change("schoolfeecategories")} aria-current="page" to={"/schoolfeecategories"}>
                            <FeatherIcon icon="divide"  size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Type frais scolaire</span>
                          </Link>
                        </li>
                      )
                    }
                    {
                      this.handleCheck("Afficher type de salaire") == true && (
                        <li className="nav-item">
                          <Link title="Type salaire" className={"nav-link p-2 "+ this.state.table.filter(data => data == "typesalaries").map((val) => { return "active" })} onClick={() => this.change("typesalaries")} aria-current="page" to={"/typesalaries"}>
                            <FeatherIcon icon="dollar-sign"  size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Type salaire</span>
                          </Link>
                        </li>
                      )
                    }

                    {/* {
                      this.handleCheck("Afficher type de paiement") == true && (
                        <li className="nav-item">
                          <Link title="Type paiement" className={"nav-link p-2 "+ this.state.table.filter(data => data == "schoolfee").map((val) => { return "active" })} onClick={() => this.change("schoolfee")} aria-current="page" to={"/schoolfee"}>
                            <FeatherIcon icon="divide"  size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Fiche de paiement</span>
                          </Link>
                        </li>
                      )
                    } */}

                    {
                      this.handleCheck("Afficher livre caisse") == true && (
                        <li className="nav-item d-none">
                          <Link title="Type paiement" className={"nav-link p-2 "+ this.state.table.filter(data => data == "accountingbalances").map((val) => { return "active" })} onClick={() => this.change("accountingbalances")} aria-current="page" to={"/accountingbalances"}>
                            <FeatherIcon icon="folder"  size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Livre de caisse</span>
                          </Link>
                        </li>
                      )
                    }

                    {
                      this.handleCheck("Ajouter paiement") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "sms").map((val) => { return "active" })} onClick={() => this.change("sms")} aria-current="page" to={"/sms"}>
                            <FeatherIcon icon="mail" size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">SMS</span>
                          </Link>
                        </li>
                      )
                    }

                    {
                      this.handleCheck("Afficher rapport") == true && (
                        <li className="nav-item">
                          <Link title="Rapport" className={"nav-link p-2 "+ this.state.table.filter(data => data == "reports").map((val) => { return "active" })} onClick={() => this.change("reports")} aria-current="page" to={"/reports"}>
                            <FeatherIcon icon="server" size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Rapports</span>
                          </Link>
                        </li>
                      )
                    }
                    {
                      this.handleCheck("Afficher corbeille") == true && (
                        <li className="nav-item">
                          <Link title="Corbeille" className={"nav-link p-2 "+ this.state.table.filter(data => data == "trash").map((val) => { return "active" })} onClick={() => this.change("trash")} aria-current="page" to={"/trash"}>
                            <FeatherIcon icon="trash"  size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Corbeille</span>
                          </Link>
                        </li>
                      )
                    }
                    {
                      this.handleCheck("Afficher archive") == true && (
                        <li className="nav-item">
                          <Link title="Archive" className={"nav-link p-2 "+ this.state.table.filter(data => data == "archives").map((val) => { return "active" })} onClick={() => this.change("archives")} aria-current="page" to={"/archives"}>
                            <FeatherIcon icon="archive"  size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Archive</span>
                          </Link>
                        </li>
                      )
                    }

                    {/* {
                      this.handleCheck("Afficher tableau de bord") == true && (

                        <li className="nav-item">
                          <Link title="Refresh" className={"nav-link p-2 "}  onClick={() => this.refresh()}>
                            <FeatherIcon icon="share-2" size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Refresh ({this.state.countData > 0 ? this.state.countData : "0"})</span>
                          </Link>
                        </li>
                      )} */}

                      <li className="nav-item">
                        <Link title="Déconnexion" className="nav-link p-2 " onClick={() => this.logout()} aria-current="page" to={"#"}>
                          <FeatherIcon icon="lock" size={"16"}></FeatherIcon>
                          <span className="ms-2 d-none d-sm-inline">Déconnexion</span>
                        </Link>
                      </li>
                        
                    </ul>
                    <hr />
                    
                </div>
                <Modal
                  show={this.state.modal} 
                  onHide={this.state.modal}
                  backdrop={"static"}>
                  <Modal.Header>
                      <Modal.Title className="h6 font-monospace">Actualisation des données</Modal.Title>
                  </Modal.Header>
                    <Modal.Body>
                        <div className="text-center font-monospace">{ this.state.isModal ? "" : <div><Loading /></div>}</div>
                      </Modal.Body>
                    <Modal.Footer>
                      {
                        this.state.isModal && (
                          <button onClick={() => this.modal() } className="btn btn-outline-secondary btn-sm"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                        )
                      }
                    </Modal.Footer>
                </Modal>
            </div>

 
             
        )
    }
}
export default Sidebar;