import axios from "axios";
import FeatherIcon from 'feather-icons-react';
import React, {Component} from "react";
import ReactToPrint from "react-to-print";
import Loading from "../../Loading";
import Headerpdf from "../../users/Headerpdf";
import UserIdentity from "../../users/UserIdentity";

class   SchoolfreeListClass extends Component{

    state = {
        isModal : false,
        formData : null,
        loading : false,
        schoolfeecategoryId : null,
        sectionclassId : null,
        schoolfees : [],
        schoolfeecategories : [],
        months : [],
    }

    componentDidMount(){
        this.getMonths();
        this.getSchoolfreeCategories();
    }

    async getMonths(){
        var months = await axios.get("months");
        if(months.status === 200)
        {
            this.setState({
                months : months.data
            })
        }
    }

    getSchoolfrees = async() =>{
        let schoolfeecategoryId = localStorage.getItem("schoolfeecategoryId") ? JSON.parse(localStorage.getItem("schoolfeecategoryId")) : this.state.schoolfeecategoryId;
        let sectionclassId = localStorage.getItem("sectionclassId") ? JSON.parse(localStorage.getItem("sectionclassId")) : this.state.sectionclassId;
        this.setState({
            loading : false,
            schoolfeecategoryId : schoolfeecategoryId,
            sectionclassId : sectionclassId,
        })
        let schoolfees = await axios.get('/students/schoolfee/' + sectionclassId +'/' + schoolfeecategoryId)
        if(schoolfees.status === 200)
            this.setState({ schoolfees : schoolfees.data , loading : true })
    }

    handleInput = (e) => {
        this.getSchoolfreeCategories(e.target.value);
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    async getSchoolfreeCategories(){
        this.setState({schoolfeecategories : [] , loading : false}) 
        var schoolfeecategories = await axios.get('schoolfeecategories_/');
        if(schoolfeecategories.status === 200)
        {
            this.setState({schoolfeecategories : schoolfeecategories.data , loading : true}) 
        }
    }

    render(){
        var amount = 0 
        return(
            <div className="overflow-hidden">
            <div className="align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Recouvrements <span className="text-primary"> { this.state.schoolfeecategories?.filter(val => val.id == this.state.schoolfeecategoryId)[0]?.category } </span></h5>
            <div className="text-end">
                <select name="schoolfeecategoryId" value={ this.state.schoolfeecategoryId} className="me-2 text-primary" onChange={this.handleInput}>
                    <option value={ null }>selectionner frais </option>
                    {
                        this.state.schoolfeecategories.map((schoolfeecategory) => {
                            return(
                                <option value={schoolfeecategory.id}>{ schoolfeecategory.category }</option>
                            )
                        })
                    }
                </select>
                <select name="sectionclassId" value={ this.state.sectionclassId} className="me-2 text-primary" onChange={this.handleInput}>
                    <option value={ null }>selectionner  </option>
                    {
                        this.state.schoolfeecategories.filter(val => val.id == this.state.schoolfeecategoryId ).map((schoolfeecategory) => {
                            return(
                                schoolfeecategory.sectionschoolfeecategories?.map( sect => (
                                    sect.transport ? <option value={ sect.transport?.id}>{ sect.transport?.axe }</option> :
                                    sect.section?.sectionclasses?.map(sectionclass =>{
                                        return (<option value={sectionclass.id}>{ sectionclass.class?.short_entitled_class } { sectionclass.title } { sect.section?.entitled_section } { sect?.price }{ sect?.currency }</option>)
                                    })
                                ))
                            )
                        })
                    }
                </select>
                <button type="button" className="btn btn-sm btn-outline-primary me-4" onClick={() => this.getSchoolfrees()}>Afficher</button>
                <ReactToPrint
                    trigger={() => <button className="btn btn-sm btn-outline-primary" type="button"><FeatherIcon icon={"printer"} size="22"></FeatherIcon> <span className="d-none d-sm-inline">Imprimer</span></button>}
                    content={() =>  this.componentRef}
                />
            </div>
            </div>
            <div  ref={el => (this.componentRef = el)}>
                    <Headerpdf />
                    <UserIdentity title={ "Recouvrements " +  this.state.schoolfeecategories?.filter(val => val.id == this.state.schoolfeecategoryId)[0]?.category } />
            <div className="table-responsive">
                <table className="table table-striped table-sm table-bordered ">
                <thead>
                    <tr>
                        <th>#</th>
                        <th scope="col">Elève</th>
                        {
                            this.state.months?.map(val =>(
                                <th>{ val.month }</th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody style={{  fontSize : 13 +"px" }}>
                    { !this.state.loading && (<Loading />)}
                    {
                        this.state.schoolfees.map((student, count) => {
                            amount = "";
                             
                            return(
                                <tr key={student.id}>
                                    <td>{ count + 1}</td>
                                    <td>{ student?.matricule } { student?.user?.last_name } { student?.user?.first_name }</td>
                                    {
                                        this.state.months?.map((month) =>{
                                            let mount = 0
                                            student.schoolfees?.filter(val => val.monthId == month.id)?.map((schoolfree) => {
                                                mount += schoolfree.pay_mount
                                            })
                                            return(
                                                <td>{ mount > 0 ? mount?.toLocaleString() : '' }</td>
                                            )
                                        })
                                    }
                                    
                                </tr>
                            )
                        })
                    }
                </tbody>
                </table>
            </div>
            </div>

            
        </div>
        )
    }
}
export default SchoolfreeListClass;