import axios from "axios";
import React, { Component } from "react";
import ReactToPrint from "react-to-print";
import Listprint from "../../Listprint";
import Loading from "../../Loading";
import SchoolfeeLine from "./SchoolfeeLine";
import ShoolfeeInvoice from "./ShoolfeeInvoice";
import FeatherIcon from 'feather-icons-react';
import Headerpdf from "../../users/Headerpdf";

class SchoolfeeList extends Component {
  state = {
    isModal: false,
    loading: false,
    start : "",
    end : "",
    display : false,
    formData: {},
    currency: [],
    check : [],
    labels: [],
    data: [],
    currencyS : [],
    dataS : [],
    months : [],
    columns : [
      {
          label: 'Fact',
          field: 'matricule',
          sort: 'asc',
          width: 200
      },
      {
          label: 'Eleve',
          field: 'student',
          sort: 'asc',
          width: 200
      },
      // {
      //     label: 'Categorie',
      //     field: 'category',
      //     sort: 'asc',
      //     width: 200
      // },
      {
          label: 'Classe',
          field: 'classe',
          sort: 'asc',
          width: 150
      },
      {
          label: 'Montant',
          field: 'mount',
          sort: 'asc',
          width: 150
      },
      {
          label: 'Mois',
          field: 'month',
          sort: 'asc',
          width: 150
      },
      {
          label: 'Année',
          field: 'year',
          sort: 'asc',
          width: 150
      },
      {
          label: 'Frais',
          field: 'schoolfreecategory',
          sort: 'asc',
          width: 120
      },
      {
          label: 'Caisse',
          field: 'caisse',
          sort: 'asc',
          width: 120
      },
      {
          label: 'Date',
          field: 'created_at',
          sort: 'asc',
          width: 120
      }
  ],
    rows: [],
  };

  componentDidMount() {
    this.orderBy();
    this.getMonths();
  }

  async getMonths(){
      var months = await axios.get("months");
      if(months.status === 200)
      {
          this.setState({
              months : months.data
          })
      }
  }

  getData(){
    this.setState({
      currency: [],
      data: [],
      labels: [],
      rows: [],
      loading: false,
      display : true,
    });
    this.orderBy();
  }

  async orderBy() {
    
    var schoolfees = "";
    if(this.state.start && this.state.end)
      schoolfees = await axios.get("schoolfees/date/"+this.state.start+"/"+this.state.end);
    else
      schoolfees = await axios.get( "schoolfees/operation/" + this.props.orderBy );


    if (schoolfees.status === 200) {
      var rows = [],
        border = "",
        first_name = "",
        currency = [],
        labels = [],
        data = [];
      schoolfees.data.map((schoolfee) => {
        
        if (this.props.orderBy != "getAll") {
                first_name = schoolfee.student ? schoolfee.student.user.first_name  : "";
                border = schoolfee.operation === "Archiver" ? "border-success text-success" : (schoolfee.operation === "Supprimer" ? "border-danger text-danger" : "border-primary text-primary")
                return (
                    schoolfee.sectionschoolfeecategory && (
                        rows.push(
                            {
                                matricule : schoolfee.student && (<span><a href={'/students/'+schoolfee.student.id}  className="text-decoration-none">{schoolfee.invoice}</a> </span>),
                                student : schoolfee.student && (schoolfee.student.matricule + " " + schoolfee.student.user.last_name +" "+ schoolfee.student.user.family_name +" "+ first_name ),
                                classe :  schoolfee.student.sectionclass && (  schoolfee.student.sectionclass.class.short_entitled_class + " "+ schoolfee.student.sectionclass.title + " "+schoolfee.student.sectionclass.section.short_entitled_section ),
                                mount : schoolfee.pay_mount + "" + (schoolfee.sectionschoolfeecategory ? schoolfee.sectionschoolfeecategory.currency : ""),
                                month :  schoolfee.month ? (schoolfee.month.month) : "" ,
                                year : schoolfee.year ? schoolfee.year.year : "",
                                schoolfreecategory : schoolfee.sectionschoolfeecategory && (schoolfee.sectionschoolfeecategory.schoolfeecategory.category),
                                caisse : schoolfee.accountingbalance && (schoolfee.accountingbalance.chartaccount && (schoolfee.accountingbalance.chartaccount.wording + " "+ schoolfee.accountingbalance.chartaccount.currency )),
                                created_at : 
                                <small className="size-13">
                                    { schoolfee.operation ==="Ajouter" ? schoolfee.createdAt.slice(0, 10) : schoolfee.updatedAt.slice(0, 10)} <span className={"size-10 px-1 border border-1 table_action rounded rounded-3 " + border}>{ schoolfee.operation }</span> 
                                    <button onClick={ () => this.showModalInvoice(schoolfee)} className="btn btn-outline-primary rounded-3 btn-sm mx-1 table_action" title="facture de ce enregistrement"><FeatherIcon icon="file" size={16}></FeatherIcon> </button>
                                </small>
                            }
                        )
                    )
                )
        } else if (schoolfee.sectionschoolfeecategory) {
          
          first_name = schoolfee.student ?  schoolfee.student.user.last_name ? schoolfee.student.user.last_name : "" : "";
        
          var curr = currency.filter( (mone) => mone.currency == schoolfee.sectionschoolfeecategory.currency.toLowerCase()).length;
          if (curr == 0)
            currency.push({ currency: schoolfee.sectionschoolfeecategory.currency.toLowerCase()});

          if (schoolfee.month) {
            var count = labels.filter( (label) => label.month == schoolfee.month.month && label.currency == schoolfee.sectionschoolfeecategory.currency.toLowerCase()  ).length;
            if (count == 0) {
              labels.push({
                month_id: schoolfee.month.id,
                month: schoolfee.month.month,
                currency: schoolfee.sectionschoolfeecategory.currency.toLowerCase(),
              });
            }
            data.push({
              somme: schoolfee.pay_mount,
              accountingbalanceexpensedetail : schoolfee.accountingbalance ? schoolfee.accountingbalance.accountingbalanceexpensedetails : [],
              expense : schoolfee.accountingbalance && schoolfee.accountingbalance.credit > 0 ? schoolfee.accountingbalance.credit : 0,
              month: schoolfee.month.month,
              currency: schoolfee.sectionschoolfeecategory.currency.toLowerCase(),
            });
          }


              first_name = schoolfee.student ? schoolfee.student.user.first_name  : "";
                border = schoolfee.operation === "Archiver" ? "border-success text-success" : (schoolfee.operation === "Supprimer" ? "border-danger text-danger" : "border-primary text-primary")
                return (
                    schoolfee.sectionschoolfeecategory && (
                        rows.push(
                            {
                                matricule : schoolfee.student && (<span><a href={'/students/'+schoolfee.student.id}  className="text-decoration-none">{schoolfee.invoice}</a> </span>),
                                student : schoolfee.student && (schoolfee.student.matricule + " " + schoolfee.student.user.last_name +" "+ schoolfee.student.user.family_name +" "+ first_name ),
                                classe :  schoolfee.student.sectionclass && (  schoolfee.student.sectionclass.class.short_entitled_class + " "+ schoolfee.student.sectionclass.title + " "+schoolfee.student.sectionclass.section.short_entitled_section ),
                                mount : schoolfee.pay_mount + "" + (schoolfee.sectionschoolfeecategory ? schoolfee.sectionschoolfeecategory.currency : ""),
                                month :  schoolfee.month ? (schoolfee.month.month) : "" ,
                                year : schoolfee.year ? schoolfee.year.year : "",
                                schoolfreecategory : schoolfee.sectionschoolfeecategory && (schoolfee.sectionschoolfeecategory.schoolfeecategory.category),
                                caisse : schoolfee.accountingbalance && (schoolfee.accountingbalance.chartaccount && (schoolfee.accountingbalance.chartaccount.wording )),
                                created_at : 
                                <small className="size-13">
                                    { schoolfee.operation ==="Ajouter" ? schoolfee.createdAt.slice(0, 10) : schoolfee.updatedAt.slice(0, 10)} <span className={"size-10 px-1 border border-1 table_action rounded rounded-3 " + border}>{ schoolfee.operation }</span> 
                                    <button onClick={ () => this.showModalInvoice(schoolfee)} className="btn btn-outline-primary rounded-3 btn-sm mx-1 table_action" title="facture de ce enregistrement"><FeatherIcon icon="file" size={16}></FeatherIcon> </button>
                                </small>
                            }
                        )
                    )
                )
        }
      });
      this.setState({
        currency: currency,
        data: data,
        labels: labels,
        columns: this.state.columns,
        rows: rows,
        loading: true,
      });
    }
  }

  showModalInvoice = (schoolfee = null) => {
    this.setState({
      isModal: !this.state.isModal,
      formData: schoolfee,
    });
  };

  InputHindel = (e) => {
    var currency = this.state.currency;
    this.setState({ currency : []})
    if(this.state.check[e.target.value])
      this.state.check[[e.target.value]] = false;
    else  
      this.state.check[[e.target.value]] = true

      this.setState({
        currency : currency
      })
  }

  inputHundle = (e) => {
    this.setState({
      [e.target.name] : e.target.value
    })
  }

  render() {

    return (
      <div>
        {
          this.props.page == "report" && (
            <div className="text-end me-2">
                <input type={"date"} name="start" onChange={this.inputHundle} value={ this.state.start} className="form-control-sm border-white m-2" />
                <input type={"date"} name="end" onChange={this.inputHundle} value={ this.state.end} className="form-control-sm border-white m-2" />
                <button className="btn btn-sm btn-outline-primary mx-2" type="button" onClick={ () => this.getData()}><FeatherIcon icon={"server"} size="22"></FeatherIcon>Afficher</button>
                {/* <ReactToPrint
                    trigger={() => <button className="btn btn-sm btn-outline-primary" type="button"><FeatherIcon icon={"printer"} size="22"></FeatherIcon> <span className="d-none d-sm-inline">Imprimer</span></button>}
                    content={() =>  this.componentRef}
                /> */}
              </div>
          )
        }

        {
            this.state.currency.length > 0 && (
              <div>
                <div className="" ref={el => (this.componentRef = el)}>
                    <Headerpdf />
                    {
                            this.state.currencyS.map((current) => {
                                var some = 0, expense = 0, data = [], max = 0, accountingbalanceexpensedetail = [];
                                this.state.labels.filter(money => money.chartaccount == current.id).sort((a, b) => (a.month_id > b.month_id ? 1 : -1)).map((label) =>{
                                    some = 0;
                                    expense = 0;
                                    
                                    this.state.dataS.filter(mon => mon.month == label.month && mon.chartaccount == label.chartaccount).map((val) => {
                                       some = val.somme > 0 ? (some +  val.somme) : 0;
                                        expense = expense + val.expense;
                                        if(val.accountingbalanceexpensedetail && val.accountingbalanceexpensedetail.length > 0)
                                            accountingbalanceexpensedetail.push(val.accountingbalanceexpensedetail);
                                    })
                                    data.push({ some : some, expense : expense, month : label.month, accountingbalanceexpensedetails : accountingbalanceexpensedetail});
                                })
                                var expenseD = 0;
                                return(
                                    <div className="card shadow mb-4 overflow-hidden" key={current.chartaccount}>
                                        <div className="card-body">
                                            <h5 className="text-center my-2 font-monspace">{"Rapport en  "+ current.chartaccount.toUpperCase() }</h5>
                                            <div className="text-center"> { this.state.display ?  "Entre " + this.state.start + " et " + this.state.end : "" }</div>
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-sm">
                                                    <thead>
                                                        <tr>
                                                            {
                                                                this.state.months.map((month) => {
                                                                    return(
                                                                        <th key={month.month+"-"+current.currency}>{ month.month}</th>
                                                                    )
                                                                })
                                                            }
                                                            <th>Total</th>
                                                            <th>Depense</th>
                                                            <th>Solde</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            {
                                                                this.state.months.map((dat) => {
                                                                    return(
                                                                        <td key={dat.month+"-"+current.currency+"evive"}>
                                                                        {
                                                                            data.filter(val => val.month === dat.month).map((month) => {
                                                                                max += month.some > 0 ? month.some : 0;
                                                                                expenseD += month.expense;
                                                                                return(
                                                                                    month.some+""+current.currency
                                                                                )
                                                                            })
                                                                        }
                                                                        </td>
                                                                    )
                                                                })
                                                            }
                                                            <td>{ max+""+current.currency }</td>
                                                            <td>{ expenseD+""+current.currency }</td>
                                                            <td>{ max - expenseD+""+current.currency }</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <span className="table_action">
                                              <input type={"checkbox"}   onChange={this.InputHindel} checked={this.state.check[current.currency]} value={current.currency} className="rounded rounded-circle" id={"check"+current.currency} />
                                              <label htmlFor={"check"+current.currency}>Afficher le detail</label>
                                            </span>
                                            
                                          
                                          
                    
                                        </div>
                                    </div>
                                )
                            })
                        }
                </div>
              </div>
            )
        }

        <div className="card shadow mb-4  overflow-hidden rounded rounded-3">
          <div className="card-body">
            <div className="h6 border-bottom pb-2 font-monospace">
              {this.props.title}
            </div>
            {!this.state.loading && <Loading />}
            {this.state.rows.length > 0 && (
              <Listprint
                columns={this.state.columns}
                rows={this.state.rows}
                title={this.props.title}
              />
            )}
          </div>

          {this.state.formData && this.state.formData.id && (
            <ShoolfeeInvoice
              isModal={this.state.isModal}
              formData={this.state.formData.id}
              schoolfeecategory={this.state.schoolfeecategory}
              showModal={this.showModalInvoice}
            />
          )}
        </div>
        <div className="">
          {this.state.currency.length > 0 &&
            this.state.currency.map((current) => {
              var labels = [],
                some = 0,
                data = [];
              this.state.labels
                .filter((money) => money.currency == current.currency)
                .sort((a, b) => (a.month_id > b.month_id ? 1 : -1))
                .map((label) => {
                  some = 0;
                  this.state.data
                    .filter(
                      (mon) =>
                        mon.month == label.month &&
                        mon.currency == label.currency
                    )
                    .map((val) => {
                      some = some + val.somme;
                    });
                  data.push(some);
                  labels.push(label.month);
                });
              return (
                <div className=" card shadow mb-3 overflow-hidden">
                  <div className="card-body">
                    <h5 className="text-center my-2 font-monspace"> {"Statistique rapport en " + current.currency.toUpperCase() }</h5>
                    <div className="text-center"> {  this.state.display ?  "Entre " + this.state.start + " et " + this.state.end : "" }</div>
                    <SchoolfeeLine
                      labels={labels}
                      data={data}
                      title={"Statistique rapport en " + current.currency.toUpperCase() }
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
export default SchoolfeeList;
