import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import { toast } from "react-toastify";

class   ChartaccountForm extends Component{

    state = {
        id : "",
        coded: "",
        accounting_code : "",
        wording : "",
        collectif : "",
        chartaccount_id : "",
        currency : "",
        error : "",

        isModal : false,
        title : "",
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    storeShoolfee = (val = null) =>{
        var data = {
                coded: this.state.coded,
                accounting_code : this.state.accounting_code,
                wording : this.state.wording,
                collectif : this.state.collectif,
                chartaccount_id : this.state.chartaccount_id,
                currency : this.state.currency,
                operation : this.state.id ? (val === 1 ? "Archiver" : "Editer" ) : "Ajouter",
            }
        var request = this.state.id ? axios.put('chartaccounts/'+this.state.id, data) : axios.post('chartaccounts', data);
        request.then((response) =>{
            this.props.showModal()
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg
            })
            toast(error.response.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }
    
    edit=()=>  {
        this.form()
    }

    form=()=>{
        this.setState({
            id : this.props.formData ? this.props.formData.id : "",
            coded: this.props.formData ? this.props.formData.coded : "",
            collectif : this.props.formData ? this.props.formData.collectif : "Collectif",
            accounting_code : this.props.formData ? this.props.formData.accounting_code : "",
            chartaccount_id : this.props.formData ? this.props.formData.chartaccount_id : "",
            currency: this.props.formData ? this.props.formData.currency : "",
            wording : this.props.formData ? this.props.formData.wording : "",
            title : this.props.formData ? "Modifier  caisse" : "Ajouter  caisse",
            error : "",
        })
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

    render(){
        
        return(
            <div>
            <Modal 
                show={this.props.isModal} 
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title className="h6 font-monospace ">{ this.state.title}</Modal.Title>
                </Modal.Header>
                    <Modal.Body className="row">
                        <div className="text-center"><span className="text-danger text-small small">{ this.state.error }</span></div>
                        <div className="col-md-6 mt-2 mt-2 d-none">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Code</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm" placeholder="Code"  onChange={this.handleInput} name="coded"  value={this.state.coded} id="nom-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="trello"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-6 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="postnom-id-icon">Libelle du caisse</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm"  onChange={this.handleInput} name="wording"  value={this.state.wording} id="postnom-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="package"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="postnom-id-icon">Reference du caisse</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm"  onChange={this.handleInput} name="accounting_code"  value={this.state.accounting_code} id="postnom-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="package"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Devise</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control" placeholder="Monnaie"  onChange={this.handleInput} name="currency"  value={this.state.currency} id="nom-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="trello"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mt-2 d-none">
                            <div className="form-group has-icon-left">
                                <label htmlFor="postnom-id-icon">Type</label>
                                <div className="position-relative mt-1">
                                    <input type="radio"  className="rounded" id="collectif"  onChange={this.handleInput} name="collectif"  value="Collectif" />
                                    <label htmlFor="collectif" className="me-3">Collectif</label>
                                    <input type="radio" id="non-collectif" className="rounded"  onChange={this.handleInput} name="collectif"  value="Non collectif" />
                                    <label htmlFor="non-collectif">Non collectif</label>
                                </div>
                            </div>
                        </div>
                        

                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm p-0 px-1"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                        <button onClick={() => this.storeShoolfee()} className="btn btn-sm btn-outline-primary p-0 px-1"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                    </Modal.Footer>
            </Modal>
            

            {/* <ParentfamilyForm 
                isModal={this.state.isModal} 
                formData={{}} 
                showModal={this.showModal}
            /> */}
        </div>
        )
    }
}
export default ChartaccountForm;