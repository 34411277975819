import axios from "axios";
import React, {Component} from "react";
import ExpenseForm from "./ExpenseForm";
import FeatherIcon from 'feather-icons-react';
import Listprint from "../../Listprint";
import SchoolfeeLine from "../schoolfees/SchoolfeeLine";
import Loading from "../../Loading";
import { toast } from "react-toastify";

class   Expense extends Component{

    state = {
        isModal : false,
        loading : false,
        formData : null,
        data : [],
        rows : [],
        months : [],
        currency : [],
        columns : [
            {
                label: 'Opérateur',
                field: 'staff',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Débiter',
                field: 'accounting',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Montant',
                field: 'mount',
                sort: 'asc',
                width: 150
            },{
                label: 'Motif',
                field: 'reason',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Mois',
                field: 'month',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Année',
                field: 'year',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Date',
                field: 'created_at',
                sort: 'asc',
                width: 120
            },
            {
                label: '#',
                field: 'action',
                sort: 'asc',
                width: 120
            }
        ]
    }

    componentDidMount(){
        this.getMonths();
        this.getExpenses();
    }

    async getMonths(){
        var months = await axios.get("months");
        if(months.status === 200)
        {
            this.setState({
                months : months.data
            })
        }
    }

    async getExpenses(){
        
        this.setState({
            rows : [],
            labels : [],
            data : [],
            loading : false
        })
        var expenses = await axios.get('expenses');
        
        if(expenses.status === 200)
        {
            var rows = [], data = [], currency =[];
            expenses.data.filter(expense => expense.type_expense === 'Expense').map((expense) => {
                
                return(
                    expense.expensedetails.map((expensedetail) =>{
                        var curr = currency.filter( (mone) => expensedetail.chartaccount.currency && mone.currency ===  expensedetail.chartaccount.currency.toLowerCase()  ).length;
                    if (curr == 0)
                      currency.push({ currency:expensedetail.chartaccount ? expensedetail.chartaccount.currency.toLowerCase() :  "" });
                    
                        rows.push({
                            staff : expensedetail.staff && (expensedetail.staff.user.last_name +" "+ expensedetail.staff.user.first_name),
                            accounting : expensedetail.chartaccount.wording,
                            mount : expensedetail.amount  + " " + expensedetail.chartaccount.currency,
                            reason : expensedetail.reason ,
                            month : expense.month.month,
                            year : expense.year.year, 
                            created_at : expensedetail.createdAt.slice(0, 10), 
                            action : <span className="table_action">
                                    {
                                        this.handleCheck("Supprimer depense") == true && (
                                          <button onClick={ () => this.deleteDetail(expensedetail.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="delete" size={16}></FeatherIcon> </button>
                                        )
                                    }
                                </span>,
                        })
                    })
                )
            })
            
            this.state.months.map((month) => {

                currency.map((money) => {
                    var amount = 0;
                    expenses.data.filter(expens => expens.type_expense === 'Expense' && expens.month.id === month.id).map((expenseD) => {
                        expenseD.expensedetails.filter(dat => dat.chartaccount.currency.toLowerCase() === money.currency ).map((expensedetailD) =>{
                            amount += expensedetailD.amount;
                        })
                    })
                    data.push({ amount : amount, currency : money.currency, month : month.month })
                })
            })
            this.setState({
                currency : currency,
                rows : rows,
                data : data,
                loading : true
            })
            
        }
    }

    showModal = (expense = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : expense,
        })
        this.getExpenses()
    }

    delete = (expense) =>{
        axios.delete("expenses/"+expense).then((response) => {
            this.getExpenses()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    deleteDetail = (expense) =>{
        axios.delete("expensedetails/"+expense).then((response) => {
            this.getExpenses()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    handleCheck(val) {
        var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
         return  permissions.some(item => val === item.permission);
     }
      
    render(){
        var data = [], label = [];

        return(
            <div className="">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Liste des depenses</h5>
                {
                    this.handleCheck("Ajouter depense") == true && (
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="btn-group me-2">
                                <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><FeatherIcon icon="plus-circle" ></FeatherIcon> Depense</button>
                            </div>
                        </div>
                    )
                }
            </div>
             {
                !this.state.loading && (<Loading />)
             }
            {
                this.state.rows.length > 0 && (
                    <div className="overflow-hidden">
                        <Listprint columns={this.state.columns} rows={this.state.rows} title={"Depense mensuelle"}/>
                    </div>
                )
            }

            {
                this.state.data.length > 0 && (
                    this.state.currency.map((money) => {
                        label = [];
                        data = [];
                        this.state.data.filter((dat => dat.currency == money.currency)).map((val) => {
                            label.push(val.month)
                            data.push(val.amount)
                        })
                        if(label.length > 0)
                        {
                            return(
                                <div className="card shadow mb-3">
                                    <div className="card-body">
                                        <div className="h6 text-monospace text-center">{"Statistique depenses mensuelles en " + money.currency}</div>
                                        <SchoolfeeLine
                                            key={money.currency}
                                            labels={label}
                                            data={data}
                                            title={"Statistique depenses mensuelles en " + money.currency}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    })
                    
                )
            }

            <ExpenseForm 
                isModal={this.state.isModal} 
                formData={this.state.formData} 
                showModal={this.showModal}
            />
            

        </div>
        )
    }
}
export default Expense;