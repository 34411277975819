import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import Select from 'react-select';
import { toast } from "react-toastify";

class   giftForm extends Component{

    state = {
        id : "",
        supplierId: "",
        entitled_gift : "",
        quantity : "",
        specification : "",
        file : "",
        error : "",

        isModal : false,
        suppliers : [],
        title : "",
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    storeGift = (val = null) =>{
        var data = {
                supplierId: this.state.supplierId ,
                entitled_gift : this.state.entitled_gift,
                quantity : this.state.quantity ? this.state.quantity : null,
                specification : this.state.specification,
                file : this.state.file,
                operation : this.state.id ? (val === 1 ? "Archiver" : "Editer" ) : "Ajouter",
            }
            
        var request = this.state.id ? axios.put('gifts/'+this.state.id, data) : axios.post('gifts', data);
        request.then((response) =>{
            this.props.showModal()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    async suppliers(){
        var request = await axios.get('suppliers');
        if(request.status === 200)
            this.setState({suppliers : request.data})
    }
    
    edit=()=>  {
        this.form()
        this.suppliers()
    }

    form=()=>{
        this.setState({
            id : this.props.formData ? this.props.formData.id : "",
            supplierId: this.props.formData ? this.props.formData.supplier ? this.props.formData.supplier.id : "" : "",
            entitled_gift : this.props.formData ? this.props.formData.entitled_gift : "",
            quantity : this.props.formData ? this.props.formData.quantity : "",
            specification : this.props.formData ? this.props.formData.specification : "",
            file : this.props.formData ? this.props.formData.file : "",
            title : this.props.formData ? "Modifier le don" : "Ajouter un don",
            errors : {},
        })
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
        this.suppliers()
    }

    render(){
        var optionsuppliers = [];
        
        this.state.suppliers.map((supplier) => {
            optionsuppliers.push({ value : supplier.id, label : supplier.name })
        });
        
        return(
            <div>
            <Modal 
                show={this.props.isModal} 
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title className="h6 font-monospace">{ this.state.title}</Modal.Title>
                </Modal.Header>
                    <Modal.Body className="row">
                        <div className="text-center"><span className="text-danger text-small">{ this.state.error }</span></div>
                        <div className="form-group col-md-6 mt-2">
                            <label className="font-monospace fw-normal">Fournisseur</label>
                            <Select
                            className="form-control form-control-sm p-0"
                                defaultValue={this.state.supplierId}
                                onChange={
                                    (select) => {
                                        this.setState({
                                            supplierId : select.value
                                        });
                                    }
                                }
                                options={optionsuppliers}
                            />
                            
                        </div>

                        <div className="col-6 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Titre du don</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm" placeholder="don"  onChange={this.handleInput} name="entitled_gift"  value={this.state.entitled_gift} id="nom-id-icon" />
                                    <div className="form-control-icon">
                                        <FeatherIcon icon="box"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        

                        <div className="col-6 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="postnom-id-icon">Quantité</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm"  onChange={this.handleInput} name="quantity"  value={this.state.quantity} id="postnom-id-icon" />
                                    <div className="form-control-icon">
                                        <FeatherIcon icon="package"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-6 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="postnom-id-icon">Document</label>
                                <div className="position-relative mt-1">
                                    <input type="file" className="form-control form-control-sm"  onChange={this.handleInput} name="file"  value={this.state.file} id="postnom-id-icon" />
                                    <div className="form-control-icon">
                                        <FeatherIcon icon="file"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-12 mt-2">
                            <div className="form-group">
                                <label htmlFor="postnom-id-icon">Spécification et description</label>
                                <textarea
                                    value={this.state.specification}
                                    onChange={this.handleInput}
                                    name="specification"
                                    rows={3}
                                    cols={4}
                                    className="form-control  "
                                />
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm p-0 px-1"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                        <button onClick={() => this.storeGift()} className="btn btn-sm btn-outline-primary p-0 px-1"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                    </Modal.Footer>
            </Modal>
            

            {/* <ParentfamilyForm 
                isModal={this.state.isModal} 
                formData={{}} 
                showModal={this.showModal}
            /> */}
        </div>
        )
    }
}
export default giftForm;