import axios from "axios";
import React, {Component} from "react";
import Listprint from "../../Listprint";
import ShoolfeeInvoice from "./ShoolfeeInvoice";
import FeatherIcon from 'feather-icons-react';
import ShoolfeeInvoiceList from "./ShoolfeeInvoiceList";
import Loading from "../../Loading";
import { toast } from "react-toastify";

class   Schoolfees extends Component{

    state = {
        isModal : false,
        printInvoice : false,
        formData : null,
        students : [],
        input : [],
        loading : false,
        rows : [],
        columns : [
            {
                label: 'Fact',
                field: 'matricule',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Eleve',
                field: 'student',
                sort: 'asc',
                width: 200
            },
            // {
            //     label: 'Categorie',
            //     field: 'category',
            //     sort: 'asc',
            //     width: 200
            // },
            {
                label: 'Classe',
                field: 'classe',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Montant',
                field: 'mount',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Mois',
                field: 'month',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Année',
                field: 'year',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Frais',
                field: 'schoolfreecategory',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Caisse',
                field: 'caisse',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Date',
                field: 'created_at',
                sort: 'asc',
                width: 120
            }
        ]
    }

    componentDidMount(){
        this.orderBy();
    }

    showModalInvoice = (schoolfee = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : schoolfee,
        })
    }

    handleInput = (e) => {
        var data = [];
        var input = this.state.input;
        this.setState({
            input : []
        })
        var schoolfee =  e.target.value;
        var count = input.some(item => schoolfee=== item)
        if(count)
        {
            data = input.filter(item => item != schoolfee);
        }
        else{
            data = input;
            data.push(schoolfee)
        }
        
        this.setState({
            input : data
        })
    }

    async orderBy(){
        var year = this.props.yearId ? "/year/" + this.props.yearId : "";
        var schoolfees = await axios.get("schoolfees" + year);

        if(schoolfees.status === 200)
        {
            var data = [], border="", first_name = "";
            
            schoolfees.data.map((schoolfee) => {
                
                first_name = schoolfee.student ? schoolfee.student.user.first_name  : "";
                border = schoolfee.operation === "Archiver" ? "border-success text-success" : (schoolfee.operation === "Supprimer" ? "border-danger text-danger" : "border-primary text-primary")
                return (
                    schoolfee.sectionschoolfeecategory && (
                        data.push(
                            {
                                matricule : schoolfee.student && (<span><a href={'/students/'+schoolfee.student.id}  className="text-decoration-none">{schoolfee.invoice}</a> <input type={"checkbox"} onChange={this.handleInput}  value={schoolfee.id } className="rounded rounded table_action" /> </span>),
                                student : schoolfee.student && (schoolfee.student.matricule + " " + schoolfee.student.user.last_name +" "+ schoolfee.student.user.family_name +" "+ first_name ),
                                classe :  schoolfee.student.sectionclass && (  schoolfee.student.sectionclass.class.short_entitled_class + " "+ schoolfee.student.sectionclass.title + " "+schoolfee.student.sectionclass.section.short_entitled_section ),
                                mount : schoolfee.pay_mount + "" + (schoolfee.sectionschoolfeecategory ? schoolfee.sectionschoolfeecategory.currency : ""),
                                month :  schoolfee.month ? (schoolfee.month.month) : "" ,
                                year : schoolfee.year ? schoolfee.year.year : "",
                                schoolfreecategory : schoolfee.sectionschoolfeecategory && (schoolfee.sectionschoolfeecategory.schoolfeecategory.category),
                                caisse : schoolfee.accountingbalance && (schoolfee.accountingbalance.chartaccount && (schoolfee.accountingbalance.chartaccount.wording + " "+ schoolfee.accountingbalance.chartaccount.currency )),
                                created_at : 
                                <small className="size-13">
                                    { schoolfee.operation ==="Ajouter" ? schoolfee.createdAt.slice(0, 10) : schoolfee.updatedAt.slice(0, 10) } <span className={"size-10 px-1 border border-1 table_action rounded rounded-3 " + border}>{ schoolfee.operation }</span> 
                                    <button onClick={ () => this.showModalInvoice(schoolfee)} className="btn btn-outline-primary rounded-3 btn-sm mx-1 table_action" title="facture de ce enregistrement"><FeatherIcon icon="file" size={16}></FeatherIcon> </button>
                                </small>
                            }
                        )
                    )
                )
            })
            this.setState({
                columns : this.state.columns,
                rows : data,
                loading : true
            })
        }
    }

    printInvoice = () => {
        this.setState({
            printInvoice : !this.state.printInvoice
        })
    }

    delete = (schoolfee) =>{
        axios.delete("schoolfees/"+schoolfee).then((response) => {
            this.getStudents()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    render(){
        
        return(
            <div className="overflow-hidden">
            <div className=" pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Liste des paiements élèves</h5>
            </div>
            {
                !this.state.loading && (<Loading />)
            }
            <div className="font-11">
             {
                 this.state.rows.length > 0 && (
                    <Listprint columns={this.state.columns} rows={this.state.rows} title={"Liste des paiements élèves"}/>
                 )
             }
             </div>
             {
                 this.state.input.length > 0 && (
                    <div>
                        <button className="btn btn-primary btn-sm" onClick={() => this.printInvoice() }>Imprimer la selection</button>
                    </div>
                 )
             }
            
            {
                this.state.formData &&  (this.state.formData.id && (
                    <ShoolfeeInvoice
                        isModal={this.state.isModal} 
                        formData={this.state.formData.id}
                        schoolfeecategory={this.state.schoolfeecategory}
                        showModal={this.showModalInvoice}
                    />
                ))
            }

            {
                this.state.input.length > 0 && (
                    <ShoolfeeInvoiceList
                        isModal={this.state.printInvoice} 
                        formData={this.state.input}
                        showModal={this.printInvoice}
                    />
                )
            }


        </div>
        )
    }
}
export default Schoolfees;