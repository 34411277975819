import React, {Component} from "react";
import { Link } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import axios from "axios";

class Navbar extends Component{

    state = {
        search : "",
        count : ""
    }
    componentDidMount(){
        // var user = JSON.parse(localStorage.getItem('user'));
        this.getBoxes();
        
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    async getBoxes(){
        var userid = JSON.parse(localStorage.getItem('user'))
        try{
            var boxes = await axios.get('chats/user/'+userid.id)
            if(boxes.status === 200)
            {
                var exist = [], count = 0;
                boxes.data.map((box) => {
                    
                    if(exist.fill(dat => dat == box.id).length == 0)
                    {
                        if(box.read_at){
                            count = count;
                        }
                        else if(box.recever_id == userid.id){
                            count = count + 1;
                        }
                        this.setState({
                            count : count
                        })
                    }
                })
            }
        }catch(error){
            if(error.response.status == 401){
                axios.post('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
                window.localStorage.clear();
                window.location.replace(process.env.REACT_APP_EVIVESOMA_COMPTA)
            }
        }
    }

    handleCheck(val) {
        let permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
        return  permissions.some(item => val === item.permission);
     }

    render(){
        var user = JSON.parse(localStorage.getItem('user'))
        return(
            <div className="d-flex justify-content-end pt-1 sticky-top shadow mb-3 pb-1"  style={{ backgroundColor : "#045fc0"}}>
                {/* <input className="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search" /> */}
                <div className={ this.handleCheck("Profil admin") == true ? "input-group w-25 ms-2" : "d-none"}>
                    <select className="form-control form-control-sm" name="redirect" onChange={(e) => { 
                        if(e.target.value)
                            window.localStorage.clear();
                        window.location.replace(e.target.value)
                    }} >
                        <option value={process.env.REACT_APP_EVIVESOMA_COMPTA }>{ process.env.REACT_APP_NAME } Compta</option>
                        <option value={process.env.REACT_APP_EVIVESOMA_RH + "/?redirect=" + user.id}>{ process.env.REACT_APP_NAME } RH</option>
                        <option value={process.env.REACT_APP_EVIVESOMA_STAT +"/?redirect=" + user.id}>{ process.env.REACT_APP_NAME } Stat</option>
                        <option value={ process.env.REACT_APP_EVIVESOMA_BIBLIO + "/?redirect=" + user.id}>{ process.env.REACT_APP_NAME } Biblio</option>
                        <option value={ process.env.REACT_APP_EVIVESOMA + "/?redirect=" + user.id}>{ process.env.REACT_APP_NAME } portail</option>
                    </select>
                </div>

                <form action={"/search/" + this.state.search} method="GET" className="input-group w-50 mx-4 ">
                    <input type="search" className="form-control form-control-dark form-control-sm  input-search" placeholder="Rechercher" name="search" onChange={this.handleInput} value={this.state.search} aria-label="Search"
                        aria-describedby="button-addon20" />
                    <button type="submit" className="btn btn-outline-light btn-light text-primary btn-sm input-search2"  id="button-addon2"><FeatherIcon icon="search" className={"16"}></FeatherIcon></button>
                </form> 

                <div className="">
                    <Link to={"/notifications"} className="p-1 pt-0 pb-2 text-white rounded-circle position-relative me-4">
                        <FeatherIcon icon="inbox"></FeatherIcon>
                        <span className="position-absolute top-0 mt-1 ms-2 start-100 translate-middle badge rounded-pill bg-danger">
                            {this.state.count > 9 ? "+9" : this.state.count > 0 ? this.state.count : "" }
                        </span>
                    </Link>

                </div>
                
                <div className=" me-2">
                    <Link to={"/profile"} className="text-decoration-none ">
                        <img src={ user.avatar } alt={ user.first_name } width="30" height="30"
                            className="rounded-circle" title={ user.first_name } />
                    </Link>
                </div>
            </div>
        )
    }
}
export default Navbar;