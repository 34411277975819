import axios from "axios";
import React, {Component} from "react";
import SchoolfeeLine from "../schoolfees/SchoolfeeLine";

class   ChartaccountStat extends Component{

    state = {
        chartaccounts : [],
    }

    componentDidMount(){
        this.getChartaccounts()
    }

    async getChartaccounts(){
        var chartaccounts = await axios.get("chartaccounts");
        if(chartaccounts.status === 200)
        {
            this.setState({
                chartaccounts : chartaccounts.data
            })
        }
    }

    render(){
        var lbls=[], tbs=[], tab = [], labels = [], label = null, credit = 0, index = 0, currency =[];
        return(
            <div className="">
             
                {
                    this.state.chartaccounts.map((chartaccount) => {
                        
                        label = null;
                        credit = 0;
                        index = 0;
                         
                        chartaccount.accountingbalances.filter(val => val.schoolfee != null ).sort((a, b) => (a.schoolfee != null && b.schoolfee != null && a.schoolfee.sectionschoolfeecategory.schoolfeecategory.category > b.schoolfee.sectionschoolfeecategory.schoolfeecategory.category ? 1 : -1)).map((accountingbalance) =>{
                            
                            index += 1;

                            if(currency.filter(curr => curr == accountingbalance.schoolfee.sectionschoolfeecategory.currency).length == 0)
                                currency.push(accountingbalance.schoolfee.sectionschoolfeecategory.currency);

                            if(label != null && label != accountingbalance.schoolfee.sectionschoolfeecategory.schoolfeecategory.category)
                            {
                                labels.push({
                                    currency :  accountingbalance.schoolfee.sectionschoolfeecategory.currency,
                                    wording : accountingbalance.schoolfee.sectionschoolfeecategory.schoolfeecategory.category
                                } );
                                tab.push( credit )
                                credit = 0;
                            }
                            else if(chartaccount.accountingbalances.filter(val => val.schoolfee != null && val.schoolfee.sectionschoolfeecategory.schoolfeecategory.category == accountingbalance.schoolfee.sectionschoolfeecategory.schoolfeecategory.category).length == index)
                            {
                                credit = parseFloat(credit) + parseFloat(accountingbalance.credit);
                                label = accountingbalance.schoolfee.sectionschoolfeecategory.currency
                                labels.push({
                                    currency :  accountingbalance.schoolfee.sectionschoolfeecategory.currency,
                                    wording : accountingbalance.schoolfee.sectionschoolfeecategory.schoolfeecategory.category
                                } );
                                tab.push( credit )
                            }
                            credit = parseFloat(credit) + parseFloat(accountingbalance.credit);
                            label = accountingbalance.schoolfee.sectionschoolfeecategory.schoolfeecategory.category
                        })
                        
                    })
                }
 
            
            {
                currency.map(curr => {
                    lbls =[];
                    tbs = [];
                    
                    labels.map((lbl, index) => {
                        if(lbl.currency == curr)
                        {
                            lbls.push(labels[index].wording)
                            tbs.push(tab[index])
                        }
                    })

                    return(
                        <div className=" ">
                            <div className="card shadow overflow-hidden  mb-3">
                                <h5 className="text-center my-2 font-monspace">{"Statistique caisse en " + curr.toUpperCase()}</h5>
                                <SchoolfeeLine
                                    labels={lbls}
                                    data={tbs}
                                    title={"Statistique caisse en " + curr.toUpperCase()}
                                />
                            </div>
                        </div>
                    )
                })
            }
                
        </div>
        )
    }
}
export default ChartaccountStat;