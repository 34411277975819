import axios from "axios";
import React, {Component} from "react";
import '../../assets/css/chat.css'
import { toast } from "react-toastify";

class Chat extends Component{

    state = {
        id : "",
        user : {},
        userRecever : {},
        chat_id : "",
        recever_id : "",
        chat : "",
        sms : "",
        loading : true,
        file : "",
        boxes : [],
        chats : [],
        users : [],
        error : ""
    }

    componentDidMount(){
        var user = JSON.parse(localStorage.getItem('user'))
        this.setState({
            user : user,
            userRecever : this.props.userRecever ? this.props.userRecever : {}
        })
        this.getUser()
        this.getBoxes()
        this.users();
    }
    
    async getUser(){
        var userid = JSON.parse(localStorage.getItem('user'))
        var user = await axios.get('users/'+userid.id)
        if(user.status === 200)
        {
            this.setState({
                user : user.data
            })
        }
    }

    async getBoxes(){
        var userid = JSON.parse(localStorage.getItem('user'))
        var boxes = await axios.get('chats/user/'+userid.id)
        if(boxes.status === 200)
        {
            this.setState({
                boxes : boxes.data
            })
        }
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    async users () {
        var users = await axios.get('users');
        if(users.status == 200)
        {
            this.setState({
                users : users.data
            })
        }
    }

    handleInputSearch = (e) => {
        var length = e.target.value.length;
        if(length > 0)
        {
            var box = this.state.users.filter(user => user.first_name.toLowerCase() == e.target.value.toLowerCase() || user.last_name.toLowerCase() == e.target.value.toLowerCase() || user.email.toLowerCase() == e.target.value.toLowerCase() || user.phone_number == e.target.value  || user.family_name.toLowerCase() == e.target.value.toLowerCase());
            
            this.setState({
                boxes : box
            })
        }
        else{
            this.getBoxes();
        }
    }

    async getRecever(recever)
    {
        var userRecever = await axios.get('users/'+recever)
        if(userRecever.status === 200)
        {
            axios.put('chats/' + recever);
            this.setState({
                userRecever : userRecever.data,
                recever_id : userRecever.data.id
            })
            this.getChats(recever)
        }
    }
    

    async getChats(recever)
    {
        var chats = await axios.get('chats/chats/' + recever);
        if(chats.status == 200)
        {
            this.setState({
                chats : chats.data,
            })
        }
    }

    storeChat = (e) => {
        e.preventDefault();
        var file = new FormData() 
        file.append('datafiles', this.state.file);
        
        var data = {
            chat_id : this.state.chat_id,
            recever_id : this.props.userRecever ? this.props.userRecever.id : this.state.recever_id,
            sender_id : this.state.user.id,
            file : file,
            chat : this.state.chat,
            sms : this.state.sms,
            read_at : this.state.read_at,
            last : 1,
        }

        var url = this.state.id ? axios.put('chats/' + this.state.id, data) : axios.post('chats', data);
        url.then((response) => {
            this.getBoxes()
            this.setState({
                chat : "",
                read_at : "",
                error : ""
            })
            this.getChats(this.props.userRecever ? this.props.userRecever.id : this.state.recever_id);
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                error : "une erreur s'est produite"
            })
            toast('Une erreur s est produite.', { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    handleInputFile = (e) => {
        this.setState({
            file : e.target.files[0]
        })
    }

    handleInputsms = (e) => {
        this.setState({
            sms: !this.state.sms
        }); 
    }


    render(){
        var userid = JSON.parse(localStorage.getItem('user'))
        var exist = [], font="";
        return (
            <div className="container p-0">
                    <div className="card">
                        <div className="row g-0">
                            {
                                this.props.display && (
                                    <div className="col-12 col-lg-5 col-xl-3 border bg-white mb-3  pb-2 chat-messages">

                                        <div className="px-4 d-md-block">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-grow-1">
                                                    <input type="text" search="search" onChange={this.handleInputSearch} className="form-control my-3" placeholder="Rechercher..." />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.state.boxes.filter(data => data.id != this.state.userRecever.id).map((box) => {
                                                
                                                if(exist.filter(dat => dat == box.id).length == 0 && !box.model )
                                                {
                                                    if(box.read_at){
                                                        font = ""
                                                    }
                                                    else{
                                                        font = "fw-bolder"
                                                    }
                                                    exist.push(box.id)
                                                    return(
                                                        <a href="#" key={box.id} className={" list-group-item list-group-item-action border-0"} onClick={ () => this.getRecever(box.id)}>
                                                            <div className="d-flex align-items-start">
                                                                <img src={ box.user.avatar} className="rounded-circle mr-1" alt="Fiona Green" width="40" height="40" />
                                                                <div className="flex-grow-1 ml-3">
                                                                    { box.user.first_name + " " + box.user.last_name}
                                                                    <p className={ font + " small"}><small>{ box.chat}</small></p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    )
                                                }
                                            })
                                        }
                                        <hr className="d-block d-lg-none mt-1 mb-0" />
                                    </div>
                                 )
                            }
                            <div className={ this.props.display ? "col-12 col-lg-7 col-xl-9" : ""}>
                                <div className="py-2 px-4 border-bottom">
                                    <div className="d-flex align-items-center py-1">
                                        <div className="position-relative">
                                            <img src={ this.state.userRecever.avatar } className="rounded-circle mr-1" alt={ this.state.userRecever.first_name } width="40" height="40" />
                                        </div>
                                        <div className="flex-grow-1 pl-3">
                                            <strong className="font-monospace">{ this.state.userRecever.first_name} { this.state.userRecever.last_name}</strong>
                                            <div className="text-muted small"><em>Message...</em></div>
                                        </div>
                                        <div>
                                            <button className="btn btn-primary btn-sm mr-1 "><svg xmlns="#http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-phone feather-lg"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg></button>
                                        </div>
                                        
                                    </div>
                                </div>

                                <div className="position-relative">
                                    <div className="chat-messages p-4">

                                        {
                                            this.state.chats.map((chat) => {
                                                if(this.state.userRecever.id == chat.recever.id)
                                                {
                                                    return (
                                                        <div className="chat-message-right mb-4" key={chat.id}>
                                                            <div>
                                                                {/* <img src={ this.state.userRecever.avatar} className="rounded-circle mr-1" alt="i" width="40" height="40" /> */}
                                                                <div className="text-muted small text-nowrap mt-2">{chat.time}</div>
                                                            </div>
                                                            <div className="flex-shrink-1 rounded py-1 px-3 mr-3 border border-2 border-light  rounded rounded-3">
                                                                {chat.chat}
                                                            </div>
                                                        </div>
                                                    )
                                                }else{
                                                    return(
                                                        
                                                        <div className="chat-message-left pb-4" key={chat.id}>
                                                            <div className="flex-shrink-1 bg-light rounded py-2 px-3 mr-3   rounded rounded-3">
                                                                    {chat.chat}
                                                            </div>
                                                            <div>
                                                                {/* <img src={ chat.avatar} className="rounded-circle mr-1" alt="." width="40" height="40" /> */}
                                                                <div className="text-muted small text-nowrap mt-2">{chat.time}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }

                                         

                                        

                                    </div>
                                </div>

                                {
                                    this.state.userRecever.id && (
                                        <form onSubmit={this.storeChat} encType="multipart/form-data" method="POST">
                                            <div className="flex-grow-0 py-3 px-4 border-top">
                                                <div className="text-danger">{this.state.error}</div>
                                                <div className="d-flex flex-row-reverse bd-highlight">
                                                    {/* <div className="p-2 bd-highlight">
                                                        <div className="">
                                                            <input type={"file"} onChange={this.handleInputFile} className="form-control form-control-sm" />
                                                        </div>
                                                    </div> */}
                                                    <div className="p-2 bd-highlight">
                                                        <input type={"checkbox"} onChange={this.handleInputsms} id="sms" className="rounded rounded-3" /><label htmlFor="sms" className="text-primary">Par SMS</label>
                                                        <input type={"checkbox"} onChange={this.handleInputsms} id="mail" className="rounded rounded-3 ms-3" /><label htmlFor="mail" className="text-primary">Par Mail</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <textarea className="form-control"  onChange={this.handleInput} name="chat" value={this.state.chat}  placeholder="Taper ici ..."/>
                                                    <div className="text-end">
                                                        <button className="btn btn-primary btn-sm py-1" type="submit">Envoyer</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}
export default Chat