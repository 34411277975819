import React, {Component} from "react";

class UserIdentity extends Component{

    render(){
        var matricule = this.props.matricule;
        var name = this.props.name;
        var classe = this.props.classe;
        return(
            <div>
                <div className="userIdentity">
                
                    
                    <div className="text-center h6 font-monospace mx-2">{ this.props.title} </div>
                    {
                        matricule && (
                            <div className="font-monospace mx-2">
                                <label className="h6">Matricule :</label>
                                <label>{ this.props.matricule}</label>
                            </div>
                        )
                    }
                    {
                        name && (
                            <div className="font-monospace mx-2" >
                                <label className="h6">Nom :</label>
                                <label>{ this.props.name}</label>
                            </div>
                        )
                    }
                    {
                        classe && (
                            <div className="font-monospace mx-2">
                                <label className="h6">Classe :</label>
                                <label>{ this.props.classe}</label>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }
}
export default UserIdentity;