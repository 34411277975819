import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import Select from 'react-select';
import { toast } from "react-toastify";

class   SectionschoolfeecategorystudentcategoryForm extends Component{

    state = {
        categorystudent_id : "",
        price : "",
        status : "",
        errors : {},
        
        isModal : false,
        categorystudents : [],
        title : "",
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    store = (val = null) =>{
        var data = {
                price : this.state.price > 0 ? this.state.price : null,
                currency : this.state.currency,
                status : "Actif",
                categorystudent_id : this.state.categorystudent_id,
                establishmentsectionschoolfeecategory_id : this.props.formData.id,
            }

        var request = axios.post('estsectschoolcatstudcats', data);
        request.then((response) =>{
            this.props.showModal()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                errors : error.response.data.errors
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }
    
    async getCategorystudents(){
        var categorystudents = await axios.get('categorystudents');
        if(categorystudents.status === 200)
        {
            this.setState({categorystudents : categorystudents.data}) 
        }
    }

    edit=()=>  {
        this.form()
        this.getCategorystudents()
    }

    form=()=>{
        this.setState({
            title :"Attacher une categorie d'élève " + this.props.formData.category + " " + this.props.formData.section.short_entitled_section,
            errors : {},
        })
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

    render(){
        var optioncategorystudents = [] ; 
        this.state.categorystudents.map((categorystudent) => {
            optioncategorystudents.push({
                value : categorystudent.id,
                label : categorystudent.category_student
            })
        })
        
        return(
            <div>
            <Modal 
                show={this.props.isModal} 
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title className="h6">{ this.state.title}</Modal.Title>
                </Modal.Header>
                    <Modal.Body className="row">

                    <div className="text-center"><span className="text-danger text-small small">{ this.state.error }</span></div>
                    <div className="form-group col-md-12 mt-2">
                        <label className="font-monospace fw-normal">Catégorie</label>
                        <Select
                            placeholder="Categorie"
                            value = {
                                optioncategorystudents.filter(option => 
                                    option.value == this.state.categorystudent_id)
                            }
                            defaultValue={this.state.categorystudent_id}
                            onChange={
                                (select) => {
                                    this.setState({
                                        categorystudent_id : select.value
                                    });
                                }
                            }
                            options={optioncategorystudents}
                        />
                        
                    </div>
 

                        <div className="col-12 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Prix</label>
                                <div className="position-relative mt-1">
                                    <input type="number" className="form-control" placeholder="Prix"  onChange={this.handleInput} name="price"  value={this.state.price} id="nom-id-icon" />
                                    <div className="form-control-icon">
                                        <FeatherIcon icon="trello"></FeatherIcon>
                                    </div>
                                </div>
                                <span className="text-danger text-small small">{ this.state.errors.price }</span>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                        <button onClick={() => this.store()} className="btn btn-sm btn-outline-primary"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                    </Modal.Footer>
            </Modal>
            
 
        </div>
        )
    }
}
export default SectionschoolfeecategorystudentcategoryForm;