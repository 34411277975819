import axios from "axios";
import React, {Component} from "react";
import FeatherIcon from 'feather-icons-react';
import { Helmet } from 'react-helmet';
import Listprint from "../Listprint";
import { toast } from "react-toastify";

class Trash extends Component{

    state = {
        rowsschoolfees : [],
        rowstypesalaries : [],
        rowsgifts : [],
        rowschartaccounts : [],
        rowsschoolfeecategories : [],
    }

    componentDidMount(){
        this.getDataschoolfees()
        this.getDatagifts()
        this.getDatatypesalaries()
        this.getChartaccounts()
        this.getSchoolfeecategories()
    }

    async getDataschoolfees(){
        var data = await axios.get("schoolfees/operation/Supprimer");
        this.setState({
            rowsschoolfees : []
        })
        if(data.status === 200)
        {
            var rows = [], first_name = "";
            data.data.map((schoolfee) => {
                first_name = schoolfee.student ? schoolfee.student.user.first_name ? schoolfee.student.user.first_name : "" : "";
                rows.push(
                    {
                        matricule : schoolfee.student && (<a href={'/students/'+schoolfee.student.id}  className="text-decoration-none">{schoolfee.invoice}</a>),
                        student : schoolfee.student && (schoolfee.student.matricule + " " + schoolfee.student.user.last_name +" "+ schoolfee.student.user.family_name +" "+ first_name ),
                        mount : <small className=""><span className={schoolfee.schoolfeecategory ? ((schoolfee.schoolfeecategory.price > schoolfee.pay_mount) ? "text-danger" : "" ): "" }> {schoolfee.pay_mount}</span> {schoolfee.schoolfeecategory ? (schoolfee.schoolfeecategory.currency ? (schoolfee.schoolfeecategory.currency) : "") : ""}</small>,
                        month : <small className="">{ schoolfee.month ? (schoolfee.month.month) : "" }</small>,
                        created_at : <small className="fw-light small">{schoolfee.createdAt.slice(0, 10)}</small>,
                        action : 
                        <span className="table_action">    
                            <button onClick={ () => this.restaure("schoolfees", schoolfee.id)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Restaurer cet enregistrement"><FeatherIcon icon="corner-up-left" size={"13"}></FeatherIcon> </button>
                            <button onClick={ () => this.delete("schoolfees", schoolfee.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="delete" size={"13"}></FeatherIcon> </button>
                        </span>,
                    }
                )
            })

            this.setState({
                rowsschoolfees : rows
            })
        }
    }

    async getDatagifts(){
        var data = await axios.get("gifts/operation/Supprimer");
        this.setState({
            rowsgifts : [],
        })
        if(data.status === 200)
        {
            var rows = [];
            data.data.map((gift) => {
                rows.push(
                    {
                        entitled_gift : gift.entitled_gift,
                        updated_at : <small className="fw-light size-13">{gift.updatedAt.slice(0, 10)}</small>,
                        action : 
                        <span className="table_action">    
                            <button onClick={ () => this.restaure("gifts", gift.id)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Restaurer cet enregistrement"><FeatherIcon icon="corner-up-left" size={"13"}></FeatherIcon> </button>
                            <button onClick={ () => this.delete("gifts", gift.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="delete" size={"13"}></FeatherIcon> </button>
                        </span>,
                    }
                )
            })

            this.setState({
                rowsgifts : rows
            })
        }
    }

    async getDatatypesalaries(){
        var data = await axios.get("typesalaries/operation/Supprimer");
        this.setState({
            rowstypesalaries : [],
        })
       if(data.status === 200)
        {
            var rows = [];
            data.data.map((type) => {
                rows.push(
                    {
                        type : type.type,
                        updatedAt : type.updatedAt.slice(0, 10),
                        action : 
                        <span className="table_action">    
                            <button onClick={ () => this.restaure("typesalaries", type.id)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Restaurer cet enregistrement"><FeatherIcon icon="corner-up-left" size={"13"}></FeatherIcon> </button>
                            <button onClick={ () => this.delete("typesalaries", type.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="delete" size={"13"}></FeatherIcon> </button>
                        </span>,
                    }
                )
            })

            this.setState({
                rowstypesalaries : rows
            })
        }
    }

    async getChartaccounts(){
        var data = await axios.get("chartaccounts/operation/Supprimer");
        this.setState({
            rowschartaccounts : [],
        })
        if(data.status === 200)
        {
            var rows = [] ;
            data.data.map((chartaccount) => {
                rows.push({
                    wording : chartaccount.wording,
                    quantity : chartaccount.amount + " " + chartaccount.currency,
                    action : <span className="table_action">
                            <button onClick={ () => this.restaure("chartaccounts", chartaccount.id)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Restaurer cet enregistrement"><FeatherIcon icon="corner-up-left" size={"13"}></FeatherIcon> </button>
                            <button onClick={ () => this.delete("chartaccounts", chartaccount.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="x-octagon" size={"13"}></FeatherIcon> </button>
                        </span>
                })
            })

            this.setState({
                rowschartaccounts : rows
            })
        }
    }
    

    async getSchoolfeecategories(){
        this.setState({
            rowsschoolfeecategories : [],
        })
        var data = await axios.get("schoolfeecategories/operation/Supprimer");
        if(data.status === 200)
        {
            var rows = [];
            data.data.map((categoy) => {
                rows.push(
                    {
                        category : categoy.category,
                        action : 
                        <span className="table_action">    
                            <button onClick={ () => this.restaure("schoolfeecategories", categoy.id)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Restaurer cet enregistrement"><FeatherIcon icon="corner-up-left" size={"13"}></FeatherIcon> </button>
                            <button onClick={ () => this.delete("schoolfeecategories", categoy.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="x-octagon" size={"13"}></FeatherIcon> </button>
                        </span>,
                    }
                )
            })

            this.setState({
                rowsschoolfeecategories : rows
            })
        }
    }


    delete = (model, data) =>{
        axios.delete(model+"/"+data).then((response) => {
            this.getDataschoolfees();
            this.getDatagifts();
            this.getDatatypesalaries();
            this.getChartaccounts();
            this.getSchoolfeecategories();
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }


    restaure = (model, data) =>{
        axios.put("restaure/"+ model +"/" +data).then((response) => {
            this.getDataschoolfees()
            this.getDatagifts()
            this.getDatatypesalaries()
            this.getChartaccounts()
            this.getSchoolfeecategories()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }



    render(){
        return(
            <div className="overflow-hidden">
                <Helmet>
                    <title>{ process.env.REACT_APP_NAME } | payment trash</title>
                </Helmet>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card shadow mb-4  overflow-hidden rounded rounded-3">
                            <div className="card-body">
                                <div className="align-items-center pt-3 pb-2 border-bottom mb-2">
                                    <h6 className="h6 font-monospace fw-bold">Corbeille paiements</h6>
                                </div>
                                {
                                    this.state.rowsschoolfees.length > 0 && (
                                        <Listprint
                                            rows={this.state.rowsschoolfees} 
                                            columns={[
                                                {
                                                    label: 'Fact',
                                                    field: 'matricule',
                                                    sort: 'asc',
                                                    width: 50
                                                },
                                                {
                                                    label: 'Eleve',
                                                    field: 'student',
                                                    sort: 'asc',
                                                    width: 200
                                                },
                                                {
                                                    label: 'Montant',
                                                    field: 'mount',
                                                    sort: 'asc',
                                                    width: 150
                                                },
                                                {
                                                    label: 'Mois',
                                                    field: 'month',
                                                    sort: 'asc',
                                                    width: 150
                                                },
                                                {
                                                    label: 'Date',
                                                    field: 'created_at',
                                                    sort: 'asc',
                                                    width: 120
                                                },
                                                {
                                                    label: '#',
                                                    field: 'action',
                                                    sort: 'asc',
                                                    width: 120
                                                }
                                            ]} 
                                            title={"Corbeille paiements"} 
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="card shadow mb-4  overflow-hidden rounded rounded-3">
                            <div className="card-body">
                                <div className="align-items-center pt-3 pb-2 border-bottom mb-2">
                                    <h6 className="h6 font-monospace fw-bold">Corbeille dons</h6>
                                </div>
                                {
                                    this.state.rowsgifts.length > 0 && (
                                        <Listprint 
                                            rows={this.state.rowsgifts} 
                                            columns={[
                                                {
                                                    label: 'Titre',
                                                    field: 'entitled_gift',
                                                    sort: 'asc',
                                                    width: 150
                                                },
                                                {
                                                    label: 'Date',
                                                    field: 'updated_at',
                                                    sort: 'asc',
                                                    width: 120
                                                },
                                                {
                                                    label: '#',
                                                    field: 'action',
                                                    sort: 'asc',
                                                    width: 120
                                                }
                                            ]} 
                                            title={"Corbeille dons"} 
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="card shadow mb-4  overflow-hidden rounded rounded-3">
                            <div className="card-body">
                                <div className="align-items-center pt-3 pb-2 border-bottom mb-2">
                                    <h6 className="h6 font-monospace fw-bold">Corbeille type des salaires</h6>
                                </div>
                                {
                                    this.state.rowstypesalaries.length  > 0 && (
                                        <Listprint 
                                            rows={this.state.rowstypesalaries }
                                            columns={[
                                                {
                                                    label: 'Type',
                                                    field: 'type',
                                                    sort: 'asc',
                                                    width: 150
                                                },
                                                {
                                                    label: 'Date',
                                                    field: 'updatedAt',
                                                    sort: 'asc',
                                                    width: 150
                                                },
                                                {
                                                    label: '#',
                                                    field: 'action',
                                                    sort: 'asc',
                                                    width: 120
                                                }
                                            ]} 
                                            title={"Corbeille type des salaires"} 
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-7">
                        <div className="card shadow mb-4  overflow-hidden rounded rounded-3">
                            <div className="card-body">
                                <div className="align-items-center pt-3 pb-2 border-bottom mb-2">
                                    <h6 className="h6 font-monospace fw-bold">Corbeille caisse</h6>
                                </div>
                                {
                                    this.state.rowschartaccounts.length > 0 && (
                                        <Listprint 
                                            rows={this.state.rowschartaccounts} 
                                            columns={[
                                                {
                                                    label: 'Caisse',
                                                    field: 'wording',
                                                    sort: 'asc',
                                                    width: 150
                                                },
                                                {
                                                    label: 'Montant',
                                                    field: 'quantity',
                                                    sort: 'asc',
                                                    width: 150
                                                },
                                                {
                                                    label: '#',
                                                    field: 'action',
                                                    sort: 'asc',
                                                    width: 120
                                                }
                                            ]} 
                                            title={"Corbeille caisse"} 
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-5">
                        <div className="card shadow mb-4  overflow-hidden rounded rounded-3">
                            <div className="card-body">
                                <div className="h6 alig-items-center pt-3 pb-2 border-bottom mb-2">
                                    <h6 className="h6 font-monospace fw-bold">Corbeille type paiements</h6>
                                </div>
                                {
                                    this.state.rowsschoolfeecategories.length > 0 && (
                                        <Listprint
                                            rows={this.state.rowsschoolfeecategories} 
                                            columns={[
                                                {
                                                    label: 'Categorie',
                                                    field: 'category',
                                                    sort: 'asc',
                                                    width: 150
                                                },
                                                {
                                                    label: '#',
                                                    field: 'action',
                                                    sort: 'asc',
                                                    width: 120
                                                }
                                            ]} 
                                            title={"Corbeille type paiements"} 
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>

                </div>
 
                
            </div>
        )
    }
}

export default Trash;