import axios from "axios";
import React, {Component} from "react";
import SchoolfeecategoryForm from "./SchoolfeecategoryForm";
import FeatherIcon from 'feather-icons-react';
import SectionschoolfeecategoryForm from "./SectionschoolfeecategoryForm";
import SectionschoolfeecategorystudentcategoryForm from "./SectionschoolfeecategorystudentcategoryForm";
import Loading from "../../Loading";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

class   Schoolfeecategory extends Component{

    state = {
        isModal : false,
        loading : true,
        formData : null,
        formDataStudent : null,
        formDataSection : null,
        formdataCategory : null,
        formDataSectionStudent : null,
        modalSectionStudent : false,
        modalSection : false,
        schoolfeecategories : [],
        isModalTransport : false,
        idtransport : null,
        axe : "",
        transports : [],
        sections : [],
        error : "",
    }

    componentDidMount(){
        this.getSchoolfeecategories();
        this.getSections();
        this.getTransports();
    }

    async getSchoolfeecategories(){

        this.setState({schoolfeecategories : [], loading : false})
        var schoolfeecategories = await axios.get('schoolfeecategories');
        if(schoolfeecategories.status === 200)
        {
            this.setState({schoolfeecategories : schoolfeecategories.data, loading : true}) 
        }
    }

    async getSections(){
        var sections = await axios.get('sections');
        if(sections.status === 200)
        {
            this.setState({sections : sections.data }) 
        }
    }

    async getTransports(){
        var transports = await axios.get('transports');
        if(transports.status === 200)
        {
            this.setState({transports : transports.data }) 
        }
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    storeTransport  = () =>{
        var data = { 
            axe : this.state.axe 
        }
        
        var request = this.state.idtransport ? axios.put('transports/'+this.state.idtransport, data) : axios.post('transports', data);
        request.then((response) =>{
            this.showModalTransport()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }
    showModalTransport = (transport = null) => {
        this.setState({
            isModalTransport : !this.state.isModalTransport,
            axe : transport ? transport.axe : transport,
            idtransport : transport ? transport.id : transport,
        })
        if(!this.state.isModalTransport)
            this.getTransports()
    }

    showModal = (schoolfeecategory = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : schoolfeecategory,
        })
        if(!this.state.isModal)
            this.getSchoolfeecategories()
    }

    showModalSection = (schoolfeecategory = null) => {
        this.setState({
            modalSection : !this.state.modalSection,
            formDataSection : schoolfeecategory
        })
        if(!this.state.modalSection)
            this.getSchoolfeecategories()
    }

    showModalSectionStudent = (schoolfeecategorysection = null, category = null) => {
        this.setState({
            modalSectionStudent : !this.state.modalSectionStudent,
            formDataSectionStudent : schoolfeecategorysection,
            formdataCategory : category
        })
        if(!this.state.modalSectionStudent)
            this.getSchoolfeecategories()
    }

    delete = (schoolfeecategory) =>{
        axios.delete("schoolfeecategories/"+schoolfeecategory).then((response) => {
            this.getSchoolfeecategories()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    deleteTransport = (transport) =>{
        axios.delete("transports/"+transport).then((response) => {
            this.getTransports()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    deleteEst = (sectionschoolfeecategory) => {
        axios.delete("sectionschoolfeecategories/"+sectionschoolfeecategory).then((response) => {
            this.getSchoolfeecategories()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        }) 
    }

    deletesectschoolfeecatstudentcat = (sectschoolfeecatstudentcat) => {
        axios.delete("estsectschoolcatstudcats/"+sectschoolfeecatstudentcat).then((response) => {
            this.getSchoolfeecategories()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        }) 
    }

    handleCheck(val) {
        var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.permission);
    }

    render(){
        var schoolfeecategories =
        this.state.schoolfeecategories.map((schoolfeecategory) => {

            return(
                <div className="col-md-6" key={schoolfeecategory.id}>
                    <div className="card shadow mb-2 rounded rounded-3 overflow-hidden">
                        <div className="card-body">
                            <div>
                                <div className="text-center m-2 font-monospace "> 
                                    <button className="btn btn-sm btn-outline-secondary">{schoolfeecategory.category} </button>
                                    <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModalSection(schoolfeecategory)}><FeatherIcon icon="plus-circle" size={16} ></FeatherIcon> Section</button>
                                </div>
 
                                <div className="text-center mb-2">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>Section, axe</th>
                                                    {/* <th>Catégorie</th> */}
                                                    <th>Prix</th>
                                                    <th>statut</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            
                                                {
                                                    schoolfeecategory.sectionschoolfeecategories.map((sectionschoolfeecategory) => {
                                                        return(<>
                                                            <tr>
                                                                <td>{ sectionschoolfeecategory.transport ? sectionschoolfeecategory.transport.axe : sectionschoolfeecategory.section.entitled_section }</td>
                                                                {/* <td>Défaut</td> */}
                                                                <td>{ sectionschoolfeecategory.price + "" + sectionschoolfeecategory.currency  }</td>
                                                                <td>{ sectionschoolfeecategory.status }</td>
                                                                <td>
                                                                    {/* {
                                                                        sectionschoolfeecategory.status == "Actif" && (
                                                                            this.handleCheck("Ajouter type de paiement") == true && (
                                                                                <button onClick={ () => this.showModalSectionStudent(sectionschoolfeecategory, schoolfeecategory.category )} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Ajouter une categorie"><FeatherIcon icon="plus" size={16}></FeatherIcon> Catégorie </button>
                                                                            )
                                                                        )
                                                                    } */}
                                                                    {
                                                                        sectionschoolfeecategory.status == "Actif" && (
                                                                            this.handleCheck("Supprimer type de paiement") == true && (
                                                                                <button onClick={ () => this.deleteEst(sectionschoolfeecategory.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="delete" size={16}></FeatherIcon> </button>
                                                                            )
                                                                        )
                                                                    }
                                                                </td>
                                                            </tr>
                                                            {
                                                                sectionschoolfeecategory.sectschoolfeecatstudentcats.map((sectschoolfeecatstudentcat) => {
                                                                    return(
                                                                        <tr>
                                                                            <td></td>
                                                                            <td>{sectschoolfeecatstudentcat.category_student}</td>
                                                                            <td>{sectschoolfeecatstudentcat.price + "" + sectionschoolfeecategory.currency }</td>
                                                                            <td>{sectschoolfeecatstudentcat.status}</td>
                                                                            <td>{
                                                                                    sectschoolfeecatstudentcat.status == "Actif" && (
                                                                                        this.handleCheck("Supprimer type de paiement") == true && (
                                                                                            <button onClick={ () => this.deletesectschoolfeecatstudentcat(sectschoolfeecatstudentcat.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="delete" size={16}></FeatherIcon> </button>
                                                                                        )
                                                                                    )
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                                <div className="text-end text-success small"> <small>{schoolfeecategory.operation}</small> </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return(
            <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Type des paiements</h5>
                {
                    this.handleCheck("Ajouter type de paiement") == true && (
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="btn-group me-2">
                                <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><FeatherIcon icon="plus-circle" ></FeatherIcon> Type de paiement</button>
                            </div>
                        </div>
                    )
                }
            </div>

            <div className="row">
                <div className="col-md-8">
                    <div className="row">
                        { this.state.loading ? schoolfeecategories : <Loading />}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card shadow mb-2 rounded rounded-2">
                        <div className="card-body">
                        <div className="h6 font-italic">Paiement</div>
                        {
                            this.state.schoolfeecategories.map((schoolfeecategory) => {
                                return(
                                    <li className={" widget-todo-item list-group-item m-1 border rounded-3"}>
                                        <div
                                            className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                                            <div className="widget-todo-title-area d-flex align-items-center">
                                                <FeatherIcon icon="chevrons-right" className='cursor-move'></FeatherIcon>
                                                <label htmlFor={schoolfeecategory.id}><span className="widget-todo-title ml-50">{ schoolfeecategory.category}</span></label>
                                            </div>
                                            <div className="widget-todo-item-action d-flex align-items-center">
                                            {
                                                this.handleCheck("Modifier paiement") == true && (
                                                    <button onClick={ () => this.showModal(schoolfeecategory)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Modifier cet enregistrement"><FeatherIcon icon="edit-3" size={13}></FeatherIcon> </button>
                                                )
                                            }

                                            {
                                                this.handleCheck("Supprimer paiement") == true && (
                                                    <button onClick={ () => this.delete(schoolfeecategory.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="delete" size={13}></FeatherIcon> </button>
                                                )
                                            }
                                            </div>
                                        </div>
                                    </li>
                                )
                            })
                        }
                        </div>
                    </div>

                    <div className="card shadow mb-2 rounded rounded-2">
                        <div className="card-body">
                        <div className="h6 font-italic">Section</div>
                        {
                            this.state.sections.map((section) => {
                                return(
                                    <li className={" widget-todo-item list-group-item m-1 border rounded-3"}>
                                        <div
                                            className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                                            <div className="widget-todo-title-area d-flex align-items-center">
                                                <FeatherIcon icon="box" className='cursor-move'></FeatherIcon>
                                                <label htmlFor={section.id}><span className="widget-todo-title ml-50">{ section.entitled_section}</span></label>
                                            </div>
                                            <div className="widget-todo-item-action d-flex align-items-center">
                                                { section.short_entitled_section}
                                            </div>
                                        </div>
                                    </li>
                                )
                            })
                        }
                        </div>
                    </div>
                
                    <div className="card shadow mb-2 rounded rounded-2">
                        <div className="card-body">
                        <div className="h6 font-italic">Transport
                            {
                                this.handleCheck("Ajouter type de paiement") == true && (
                                    <button type="button" className="btn btn-sm ms-2 btn-outline-primary" onClick={() => this.showModalTransport(null)}><FeatherIcon icon="plus"></FeatherIcon> </button>
                                )
                            }
                        </div>
                        {
                            this.state.transports.map((transport) => {
                                return(
                                    <li className={" widget-todo-item list-group-item m-1 border rounded-3"}>
                                        <div
                                            className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                                            <div className="widget-todo-title-area d-flex align-items-center">
                                                <FeatherIcon icon="chevrons-right" className='cursor-move'></FeatherIcon>
                                                <label htmlFor={transport.id}><span className="widget-todo-title ml-50">{ transport.axe}</span></label>
                                            </div>
                                            
                                            <div className="widget-todo-item-action d-flex align-items-center">
                                            {
                                                this.handleCheck("Modifier type de paiement") == true && (
                                                    <button onClick={ () => this.showModalTransport(transport)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Modifier cet enregistrement"><FeatherIcon icon="edit-3" size={13}></FeatherIcon> </button>
                                                )
                                            }

                                            {
                                                this.handleCheck("Supprimer type de paiement") == true && (
                                                    <button onClick={ () => this.deleteTransport(transport.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="delete" size={13}></FeatherIcon> </button>
                                                )
                                            }
                                            </div>
                                        </div>
                                    </li>
                                )
                            })
                        }
                        </div>
                    </div>
                
                </div>
            </div>

            <SchoolfeecategoryForm 
                isModal={this.state.isModal} 
                formData={this.state.formData} 
                showModal={this.showModal}
            />


            {
                this.state.modalSection && (
                    <SectionschoolfeecategoryForm 
                        isModal={this.state.modalSection} 
                        formData={this.state.formDataSection} 
                        showModal={this.showModalSection}
                    />
                )
            }

            {
                this.state.isModalTransport && (
                    <Modal 
                        show={this.state.isModalTransport} 
                        onHide={this.isModalTransport}
                        size="lg"
                        backdrop={"static"}>
                        <Modal.Header>
                            <Modal.Title className="h6 font-monospace">Transport</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="row">

                            <span className="text-danger text-small small">{ this.state.error }</span>
                            <div className="col-md-12 mt-2 mt-2">
                                <div className="form-group has-icon-left">
                                    <label htmlFor="nom-id-icon">Axe</label>
                                    <div className="position-relative mt-1">
                                        <input type="text" className="form-control form-control-sm"  onChange={this.handleInput} name="axe"  value={this.state.axe} id="nom-id-icon" />
                                        <div className="form-control-icon">
                                            <FeatherIcon icon="activity"></FeatherIcon>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <button onClick={() => this.showModalTransport()} className="btn btn-outline-secondary btn-sm p-0 px-1"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                            <button onClick={() => this.storeTransport()} className="btn btn-sm btn-outline-primary p-0 px-1"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                        </Modal.Footer>
                    </Modal>
                )
            }

            {
                this.state.modalSectionStudent && (
                    <SectionschoolfeecategorystudentcategoryForm
                        isModal={this.state.modalSectionStudent} 
                        formData={this.state.formDataSectionStudent} 
                        category={this.state.formdataCategory}
                        showModal={this.showModalSectionStudent}
                    />
                )
            }
        </div>
        )
    }
}
export default Schoolfeecategory;