import axios from "axios";
import React, {Component} from "react";
import WageForm from "./WageForm";
import FeatherIcon from 'feather-icons-react';
import SchoolfeeLine from "../schoolfees/SchoolfeeLine";
import Loading from "../../Loading";
import { toast } from "react-toastify";

class   Wage extends Component{

    state = {
        isModal : false,
        isModalDetail : false,
        loading : false,
        formData : "",
        labels : [],
        currencyS : [],
        months : [],
        check : [],
        staffs : [],
        wages : [],
    }

    componentDidMount(){
        this.months();
        this.staffs();
        this.getWages();
    }

    InputHindel = (e) => {
        
        this.setState({ currency : []})
        if(this.state.check[e.target.value])
          this.state.check[[e.target.value]] = false;
        else  
          this.state.check[[e.target.value]] = true
     
      }

    async months(){
        var request = await axios.get('months');
        if(request.status === 200)
            this.setState({months : request.data})
    }

    async staffs(){
        var request = await axios.get('staffs');
        if(request.status === 200)
            this.setState({staffs : request.data})
    }

    async getWages(){
        this.setState({
            wages : [],
            loading : false,
        })
        var wages = await axios.get('expenses');
        
        if(wages.status === 200)
        {
            this.setState({
                wages : wages.data,
                labels : [],
                loading : true
            })

            var labels = [], currencyS = [], currency = null;

            wages.data.filter(expense => expense.type_expense == 'Wage').map((wage) => {
                
                wage.expensedetails.map((expensedetail) =>{
                    labels.push({
                        month : wage.month.month,
                        currency : expensedetail.chartaccount.currency,
                        amount : expensedetail.amount
                    })
                    currency = expensedetail.chartaccount.currency
                })

                var exist = currencyS.filter(data => data.currency == currency && currency != null).length
                if(exist == 0)
                {
                    currencyS.push({ currency : currency})
                    currency = null;
                }
            })
            this.setState({
                wages : wages.data,
                labels : labels,
                currencyS : currencyS,
                loading : true
            })
                
        }
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal
        })
        this.getWages()
    }

    showModalDetail = (wage) => {
        this.setState({
            isModalDetail : !this.state.isModalDetail,
            formData : wage,
        })
        this.getWages()
    }

    delete = (wage) =>{
        axios.delete("expensedetails/"+wage).then((response) => {
            this.getWages()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    handleCheck(val) {
        var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
         return  permissions.some(item => val === item.permission);
     }

    render(){
        
        var ths = [], staffs = [], currency = [],  totalmonth = 0;

            ths.push("Personnel")
            this.state.months.map((month) => {
                ths.push(month.month)
            });
            ths.push("Total")
            
            var obj = {};
            this.state.staffs.map((staff) => {
                obj = {};
                obj["id"] = staff.id ;
                obj["staff"] = staff.matricule + " " + staff.user.last_name + " " + staff.user.first_name ;
                this.state.months.map((month) => {
                    var some = [];
                    this.state.wages.filter(expense => expense.staff && expense.staff.id === staff.id &&  expense.month.id == month.id &&  expense.type_expense == 'Wage').map((wage) =>{
                        wage.expensedetails.map((expensedetail) => {
                            if(currency.filter(val => val.currency == expensedetail.chartaccount.currency.toLowerCase()).length == 0)
                                currency.push({ currency : expensedetail.chartaccount.currency.toLowerCase()})
 
                            some.push({
                                id : expensedetail.id,
                                staffId : staff.id,
                                amount : expensedetail.amount,
                                currency : expensedetail.chartaccount.currency.toLowerCase(),
                                chartaccount : expensedetail.chartaccount.wording,
                                createdAt : expensedetail.createdAt,
                                reason : expensedetail.reason,
                                type : expensedetail.typesalary ? expensedetail.typesalary.type : "",
                                year : wage.year.year,
                                month : wage.month.month,
                                staff : expensedetail.staff.matricule + " " + expensedetail.staff.user.last_name + " " + expensedetail.staff.user.first_name
                            })
                        })
                    })
                    obj[month.month] = some;
                });
                obj["total"] = 12;
                staffs.push(obj)
            })
            
        return(
            <div className="overflow-hidden">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Salaire mensuel du personnel</h5>
                {
                    this.handleCheck("Ajouter salaire") == true && (
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="btn-group me-2">
                                <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal()}><FeatherIcon icon="plus-circle" ></FeatherIcon> Salaire</button>
                            </div>
                        </div>
                    )
                }
            </div>
            {
                !this.state.loading && (<Loading />)
            }


            <div className="overflow-hidden">
                <div className="shadow mb-2">
                    <div className="">

                        <div className="table-responsive">
                            <table className="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr>
                                        {
                                            ths.map(th => {
                                                return(
                                                    <th key={th} className="border p-1">{ th }</th>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        staffs.map((staff) => {
                                            return(
                                            <>
                                                <tr key={staff.id}>
                                                    <td className="border p-1">
                                                        { staff.staff }
                                                        <span className="table_action">
                                                            <input type={"checkbox"}   onChange={this.InputHindel} checked={this.state.check[staff.id]} value={staff.id} className="rounded rounded-circle" />
                                                        </span>
                                                    </td>
                                                    {
                                                        this.state.months.map((month) => {
                                                            return(
                                                                <td className="border p-1" align="right">{ 
                                                                    currency.map(money => {
                                                                        var some = 0;
                                                                        return(<>
                                                                            {
                                                                                staff[month.month].filter(val => val.staffId == staff.id && val.currency == money.currency).map(som => {
                                                                                    some += som.amount
                                                                                })
                                                                            }
                                                                            {some > 0 ? some + " "+ money.currency : "" }<br />
                                                                            </>
                                                                        )
                                                                    })
                                                                    
                                                                }</td>
                                                            )
                                                        })
                                                    }
                                                    <td className="border p-1 fw-bold" align="right">
                                                    { 
                                                        currency.map((money) => {
                                                        var  totalstaff = 0;
                                                            return(<>
                                                                {
                                                                    this.state.months.map((month) => {
                                                                        return(
                                                                            staff[month.month].filter(val => val.staffId == staff.id && val.currency == money.currency).map(som => {
                                                                            totalstaff += som.amount
                                                                            })
                                                                        )
                                                                    })
                                                                }
                                                                { totalstaff > 0 ? totalstaff + " " + money.currency : ""}<br/>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                    </td>
                                                </tr>
                                                {
                                                    this.state.check[staff.id] == true && (
                                                    <tr>
                                                        <td></td>
                                                        <td colSpan={13}>
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <td>Montant</td>
                                                                        <td>Type</td>
                                                                        <td>Mois</td>
                                                                        <td>Date</td>
                                                                        <td>Caisse</td>
                                                                        <td>Description</td>
                                                                        <td>Operateur</td>
                                                                        <td className="table_action" >#</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    this.state.months.map((month) => {
                                                                        return(
                                                                            staff[month.month].map((detail , index) => {
                                                                                return(
                                                                                    <tr>
                                                                                        <td>{ detail.amount + " " + detail.currency }</td>
                                                                                        <td>{ detail.type }</td>
                                                                                        <td>{ detail.month + "  " + detail.year }</td>
                                                                                        <td>{ detail.createdAt.slice(0, 10) }</td>
                                                                                        <td>{ detail.chartaccount }</td>
                                                                                        <td>{ detail.reason }</td>
                                                                                        <td>{ detail.staff }</td>
                                                                                        <td className="table_action">
                                                                                        {
                                                                                            this.handleCheck("Supprimer salaire") == true && (
                                                                                                <button onClick={ () => this.delete(detail.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="delete" size={16}></FeatherIcon> </button>
                                                                                            )
                                                                                        }
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        )
                                                                    })
                                                                }
                                                                </tbody>
                                                            </table>  
                                                        </td>
                                                    </tr>
                                                    )
                                                }
                                            </>
                                            )
                                        })
                                    }
                                    <tr>
                                        <td className="border p-1 fw-bolder">Total mensuel</td>
                                        { 
                                        
                                            this.state.months.map((month) => {
                                                return(
                                                    <td className="border p-1 fw-bolder" align="right">
                                                    {
                                                        currency.map((money) => {
                                                            var  totalmonth = 0;
                                                            return(<>{
                                                                        staffs.map((staff) => {
                                                                            return(
                                                                                staff[month.month].filter(val => val.staffId == staff.id && val.currency == money.currency).map(som => {
                                                                                    totalmonth += som.amount
                                                                                })
                                                                            )
                                                                        })
                                                                    }
                                                                    { totalmonth > 0 ? totalmonth + " " + money.currency : ""}<br/>
                                                                    </>
                                                                )
                                                            })
                                                    }
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            
            {
                this.state.labels.length > 0 && (
                    this.state.currencyS.map((currency) => {
                        var data = [], label =[]
                        this.state.months.map((month) => {
                            var somme = 0;
                            this.state.labels.filter((val => val.month == month.month && val.currency === currency.currency)).map((label) =>{
                                    somme += label.amount;
                            }) 
                            data.push(somme);
                            label.push(month.month)
                        }) 
                        return(
                            <div className="card shadow mb-2">
                                <div className="card-body">
                                    <div className="text-center text-monospace h6">{"Statistique salaire en " + currency.currency}</div>
                                    <SchoolfeeLine
                                        labels={label}
                                        data={data}
                                        title={"Statistique salaire en " + currency.currency}
                                    /> 
                                </div>
                            </div>
                        )
                    })
                )
            }

            <WageForm 
                isModal={this.state.isModal}
                showModal={this.showModal}
            />
            

        </div>
        )
    }
}
export default Wage;