import axios from "axios";
import React, {Component} from "react";
import ShoolfeeForm from "./ShoolfeeForm";
import FeatherIcon from 'feather-icons-react';
import Listprint from "../../Listprint";
import { Helmet } from 'react-helmet';
import ShoolfeeInvoice from "./ShoolfeeInvoice";
import ShoolfeeInvoiceList from "./ShoolfeeInvoiceList";
import Loading from "../../Loading";
import ShoolfeeFormtransport from "./ShoolfeeFormtransport";
import { toast } from "react-toastify";

class   Schoolfee extends Component{

    state = {
        isModal : false,
        isModalTransport : false,
        loading : false,
        formData : null,
        isModalInvoice : false,
        printInvoice : false,
        classe : null,
        formDataInvoice : null,
        input : [],
        schoolfees : [],
        schoolfee : null,
        sections : [],
        schoolfeecategory : {
            category : ""
        },
        columns : [
            {
                label: 'Fact',
                field: 'invoice',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Eleve',
                field: 'student',
                sort: 'asc',
                width: 200
            },
            // {
            //     label: 'Categorie',
            //     field: 'category',
            //     sort: 'asc',
            //     width: 200
            // },
            {
                label: 'Payé par',
                field: 'responsible',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Montant',
                field: 'mount',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Mois',
                field: 'month',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Caisse',
                field: 'chartaccount',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Date',
                field: 'created_at',
                sort: 'asc',
                width: 120
            },
            {
                label: '#',
                field: 'action',
                sort: 'asc',
                width: 120
            }
        ]
    }
    
    componentDidMount(){
        this.getSchoolfeecategory()
        this.getSections();
    }

    async getSchoolfeecategory(){
        var schoolfeecategory = await axios.get('schoolfeecategories/' +this.props.match.params.schoolfeecategory_id);
        if(schoolfeecategory.status === 200)
        {
            this.setState({schoolfeecategory : schoolfeecategory.data}) 
        }
    }
    
    async getSections(){
        this.setState({ sections : [], loading : false})
        var sections = await axios.get('sectionshoolfrees');
        if(sections.status === 200)
        {
            this.setState({
                sections : sections.data,
                loading : true,
            })
        }
    }

    showModal = (schoolfee = null, classe = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : schoolfee,
            classe : classe
        })
        this.getSections()
    }

    showModalTransport = (schoolfee = null) => {
        this.setState({
            isModalTransport : !this.state.isModalTransport,
            formData : schoolfee,
        })
        this.getSections()
    }

    showModalInvoice = (schoolfee = null) => {
        this.setState({
            isModalInvoice : !this.state.isModalInvoice,
            formDataInvoice : schoolfee,
        })
    }

    async getSchoolfee(id){
        
    }

     handleInput = (e) => {

        var data = [];
        var input = this.state.input;
        this.setState({
            input : []
        })
        var schoolfee =  e.target.value;
        var count = input.some(item => schoolfee=== item)
        if(count)
        {
            data = input.filter(item => item != schoolfee);
        }
        else{
            data = input;
            data.push(schoolfee)
        }
        
        this.setState({
            input : data
        })
    }

    printInvoice = () => {
        this.setState({
            printInvoice : !this.state.printInvoice
        })
    }

    delete = (schoolfee) =>{
        axios.delete("schoolfees/"+schoolfee).then((response) => {
            this.getSections()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    handleCheck(val) {
        var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
         return  permissions.some(item => val === item.permission);
     }

    render(){
        
        var sections =  "", rows= [];
        sections = 
        this.state.sections.map((section) => {
            return( 
                this.state.schoolfeecategory && this.state.schoolfeecategory.sectionschoolfeecategories && this.state.schoolfeecategory.sectionschoolfeecategories.length > 0 && (
                this.state.schoolfeecategory.sectionschoolfeecategories.filter(data => data.section.id == section.id && data.transport == null ).length > 0 ? (
                <div className="card mb-2 shadow rounded  rounded-3 " key={section.id}>
                    <div className="card-body row">
                        <h6 className="fw-bolder font-monospace ">{section.entitled_section + " "+section.short_entitled_section}</h6>
                        
                        {
                            section.sectionclasses.map((sectionclasse) => {
                                rows = []
                                var sectionclass = {section : section , sectionclasse :  sectionclasse }
                                return(
                                    <div className="col-md-12 mb-4" key={sectionclasse.id}>
                                        <div className="card  mb-2 rounded rounded-3">
                                            <div className="card-body">

                                                <div className="text-center m-2 font-monospace "> 
                                                    <button className="btn btn-sm btn-outline-secondary">{this.state.schoolfeecategory.category + " " + sectionclasse.class.short_entitled_class} {sectionclasse.title} <small>{section.short_entitled_section} </small> </button>
                                                    {
                                                        this.state.schoolfeecategory.sectionschoolfeecategories.filter(data => data.section.id == section.id && data.status == "Actif").length > 0 && (
                                                            this.handleCheck("Ajouter paiement") == true && (
                                                                <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null, sectionclass)}><FeatherIcon icon="plus" size={20}></FeatherIcon> Paiement</button>
                                                            )
                                                        )
                                                    }
                                                </div>
                                                {/* <div className="text-end text-primary"> {sectionclasse.status}</div> */}
                                                </div>
                                                {
                                                    sectionclasse.students.filter(student => student.operation != "Supprimer" && student.operation != "Archiver").map((student) => {
                                                        return(
                                                            student.schoolfees.filter(schoolfee => schoolfee.sectionschoolfeecategory != null && schoolfee.sectionschoolfeecategory.schoolfeecategory.id == this.state.schoolfeecategory.id && schoolfee.operation != "Supprimer" && schoolfee.operation != "Archiver").map((schoolfee) => {
                                                                var last_name = student.user ? student.user.last_name ? student.user.last_name : "" : "";
                                                                var first_name = student.user ? student.user.first_name ? student.user.first_name : "" : "";
                                                                var family_name = student.user ? student.user.family_name ? student.user.family_name : "" : "";
                                                                rows.push(
                                                                    {
                                                                        invoice : <span><a href={'/students/'+student.id}  className="text-decoration-none text-primary">{schoolfee.invoice}</a>  <input type={"checkbox"} onChange={this.handleInput}  value={ schoolfee.id } className="rounded rounded table_action" /> </span>,
                                                                        student : student.matricule +  " " + last_name +" "+ family_name +" "+ first_name,
                                                                        category :  student.categorystudent && (student.categorystudent.category_student),
                                                                        responsible : schoolfee.user && (schoolfee.user.last_name + " " +schoolfee.user.first_name),
                                                                        mount :  schoolfee.pay_mount + "" +  schoolfee.sectionschoolfeecategory.currency ,
                                                                        month :  schoolfee.month ? (schoolfee.month.month) : "",
                                                                        chartaccount : schoolfee.accountingbalance && (schoolfee.accountingbalance.chartaccount && (schoolfee.accountingbalance.chartaccount.wording + " "+ schoolfee.accountingbalance.chartaccount.currency )),
                                                                        created_at : new Date(schoolfee.createdAt)?.toLocaleDateString("en-GB"),
                                                                        action : <span className="table_action">
                                                                            <button onClick={ () => this.showModalInvoice(schoolfee)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="facture de ce enregistrement"><FeatherIcon icon="file" size={16}></FeatherIcon> </button>
                                                                            {
                                                                                this.handleCheck("Modifier paiement") == true && (
                                                                                    <button onClick={ () => this.showModal(schoolfee, sectionclass)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Modifier cet enregistrement"><FeatherIcon icon="edit-3" size={16}></FeatherIcon> </button>
                                                                                )
                                                                            }
                                                                            {
                                                                                this.handleCheck("Supprimer paiement") == true && (
                                                                                    <button onClick={ () => this.delete(schoolfee.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="delete" size={16}></FeatherIcon> </button>
                                                                                )
                                                                            }
                                                                        </span>,
                                                                    }
                                                                )
                                                            })
                                                        )
                                                    })
                                                }
                                                
                                                <div>
                                                {
                                                    rows.length > 0  && (
                                                        <Listprint columns={this.state.columns} rows={rows} title={"Eleves de " + sectionclasse.class.short_entitled_class + " "+ sectionclasse.title + "  "+ section.short_entitled_section}/>
                                                    )
                                                }

                                                {
                                                    this.state.input.length > 0 && (
                                                        <div>
                                                            <button className="btn btn-primary btn-sm" onClick={() => this.printInvoice() }>Imprimer la selection</button>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        
                    </div>
                </div>
            ) : 
            <div>
            {
                this.state.schoolfeecategory.sectionschoolfeecategories.filter(data => data.transport && data.transport.id != null ).map(sectionschoolfeecategory => {
                    return(
                            <div className="col-md-12 mb-4" key={sectionschoolfeecategory.id}>
                                <div className="card  mb-2 rounded rounded-3">
                                    <div className="card-body">

                                        <div className="text-center m-2 font-monospace "> 
                                            <button className="btn btn-sm btn-outline-secondary"> {sectionschoolfeecategory.transport.axe + " " + section.short_entitled_section}  </button>
                                            {
                                                this.state.schoolfeecategory.sectionschoolfeecategories.filter(data => data.transport != null && data.status == "Actif").length > 0 && (
                                                    this.handleCheck("Ajouter paiement") == true && (
                                                        <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModalTransport(null)}><FeatherIcon icon="plus" size={20}></FeatherIcon> Paiement</button>
                                                    )
                                                )
                                            }
                                        </div>
                                        {/* <div className="text-end text-primary"> {sectionclasse.status}</div> */}
                                        {
                                            section.sectionclasses.map((sectionclasse) => {
                                                rows = []
                                                var sectionclass = {section : section , sectionclasse :  sectionclasse }
                                            
                                                return(
                                                    <>{
                                                    sectionclasse.students.filter(student => student.operation != "Supprimer" && student.operation != "Archiver").map((student) => {
                                                        return(
                                                            student.schoolfees.filter(schoolfee => schoolfee.sectionschoolfeecategory != null && schoolfee.sectionschoolfeecategory.schoolfeecategory.id == this.state.schoolfeecategory.id && schoolfee.sectionschoolfeecategory.id == sectionschoolfeecategory.id && schoolfee.operation != "Supprimer" && schoolfee.operation != "Archiver").map((schoolfee) => {
                                                                var commitment_date = new Date(schoolfee.createdAt);
                                                                    commitment_date = commitment_date.getDate() + "-" + (commitment_date.getMonth()  + 1) + "-" + commitment_date.getFullYear();
                                                                var last_name = student.user ? student.user.last_name ? student.user.last_name : "" : "";
                                                                var first_name = student.user ? student.user.first_name ? student.user.first_name : "" : "";
                                                                var family_name = student.user ? student.user.family_name ? student.user.family_name : "" : "";
                                                                rows.push(
                                                                    {
                                                                        invoice : <span><a href={'/students/'+student.id}  className="text-decoration-none text-primary">{schoolfee.invoice}</a>  <input type={"checkbox"} onChange={this.handleInput}  value={ schoolfee.id } className="rounded rounded table_action" /> </span>,
                                                                        student : student.matricule +  " " + last_name +" "+ family_name +" "+ first_name,
                                                                        category :  student.categorystudent && (student.categorystudent.category_student),
                                                                        responsible : schoolfee.user && (schoolfee.user.last_name + " " +schoolfee.user.first_name),
                                                                        mount :  schoolfee.pay_mount + "" +  schoolfee.sectionschoolfeecategory.currency ,
                                                                        month :  schoolfee.month ? (schoolfee.month.month) : "",
                                                                        chartaccount : schoolfee.accountingbalance && (schoolfee.accountingbalance.chartaccount && (schoolfee.accountingbalance.chartaccount.wording + " "+ schoolfee.accountingbalance.chartaccount.currency )),
                                                                        created_at : commitment_date,
                                                                        action : <span className="table_action">
                                                                            <button onClick={ () => this.showModalInvoice(schoolfee)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="facture de ce enregistrement"><FeatherIcon icon="file" size={16}></FeatherIcon> </button>
                                                                            {
                                                                                this.handleCheck("Modifier paiement") == true && (
                                                                                    <button onClick={ () => this.showModalTransport(schoolfee)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Modifier cet enregistrement"><FeatherIcon icon="edit-3" size={16}></FeatherIcon> </button>
                                                                                )
                                                                            }
                                                                            {
                                                                                this.handleCheck("Supprimer paiement") == true && (
                                                                                    <button onClick={ () => this.delete(schoolfee.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="delete" size={16}></FeatherIcon> </button>
                                                                                )
                                                                            }
                                                                        </span>,
                                                                    }
                                                                )
                                                            })
                                                        )
                                                    })

                                                    }
                                                    <div>
                                                        {
                                                            rows.length > 0  && (
                                                                <Listprint columns={this.state.columns} rows={rows} title={ "Eleves de " + sectionschoolfeecategory.transport.axe  }/>
                                                            )
                                                        }
                                                    </div>
                                                    </>

                                                )
                                            })
                                        }
                                        {
                                            this.state.input.length > 0 && (
                                                <div>
                                                    <button className="btn btn-primary btn-sm" onClick={() => this.printInvoice() }>Imprimer la selection</button>
                                                </div>
                                            )
                                        }
                                    </div>
                                        
                                </div>
                            </div>
                        )
                    })
            }
            </div>
            )
            )
        })
        

        return(

            <div className="overflow-hidden">
                <Helmet>
                    <title>{ process.env.REACT_APP_NAME } | students paiements { this.state.schoolfeecategory.category}</title>
                </Helmet>

            <div className="align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Liste des paiements <span className="text-primary">{ this.state.schoolfeecategory.category}</span> des élèves</h5>
                
            </div>

            <div>
                {
                    this.state.loading ? sections : <Loading />
                }
            </div>
            {
                this.state.isModal && (
                    <ShoolfeeForm 
                        isModal={this.state.isModal} 
                        formData={this.state.formData}
                        classe={this.state.classe}
                        schoolfeecategory={ this.state.schoolfeecategory ? this.state.schoolfeecategory : null}
                        showModal={this.showModal}
                    />
                )
            }
            {
                this.state.isModalTransport && (
                    <ShoolfeeFormtransport 
                        isModal={this.state.isModalTransport} 
                        formData={this.state.formData}
                        schoolfeecategory={ this.state.schoolfeecategory ? this.state.schoolfeecategory : null}
                        showModal={this.showModalTransport}
                    />
                )
            }
            {
                this.state.formDataInvoice &&  (this.state.formDataInvoice.id && (
                    <ShoolfeeInvoice
                        isModal={this.state.isModalInvoice} 
                        formData={this.state.formDataInvoice.id}
                        schoolfeecategory={ this.state.schoolfeecategory ? this.state.schoolfeecategory : null}
                        showModal={this.showModalInvoice}
                    />
                ))
            }
            {
                this.state.input.length > 0 && (
                    <ShoolfeeInvoiceList
                        isModal={this.state.printInvoice} 
                        formData={this.state.input}
                        showModal={this.printInvoice}
                    />
                )
            }
        </div>
        )
    }
}
export default Schoolfee;