import axios from "axios";
import FeatherIcon from 'feather-icons-react';
import React, {Component} from "react";
import ReactToPrint from "react-to-print";
import Loading from "../../Loading";
import Headerpdf from "../../users/Headerpdf";
import UserIdentity from "../../users/UserIdentity";

class   Accountingbalance extends Component{

    state = {
        isModal : false,
        formData : null,
        loading : false,
        monthId : null,
        accountingbalances : [],
        months : [],
        periods : ['','Janvier','Fevrier','Mars','Avril','Mai','Juin','Juillet']
    }

    componentDidMount(){
        var commitment_date = new Date();
        commitment_date = commitment_date.getMonth() + 1;
        this.setState({
            monthId : commitment_date
        })
        this.getMonths();
        this.getAccountingbalances(commitment_date);
    }

    async getMonths(){
        var months = await axios.get("months");
        if(months.status === 200)
        {
            this.setState({
                months : months.data
            })
        }
    }

    handleInput = (e) => {
        this.getAccountingbalances(e.target.value);
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    async getAccountingbalances(commitment_date){
        this.setState({accountingbalances : [] , loading : false}) 
        var accountingbalances = await axios.get('accountingbalances/' + commitment_date);
        if(accountingbalances.status === 200)
        {
            this.setState({accountingbalances : accountingbalances.data , loading : true}) 
        }
    }

    render(){
        var amount = "", currency = [];
        this.state.accountingbalances.map(curr => {
            if(currency.filter(dat => dat.currency == curr.chartaccount.currency).length == 0)
                currency.push({
                    amount : 0,
                    currency : curr.chartaccount.currency
                })
        })
        return(
            <div className="overflow-hidden">
            <div className="align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Equilibre du caisse mois <span className="text-primary"> { this.state.months[this.state.monthId-1]?.month} </span></h5>
                
            <div className="text-end">
                <select name="monthId" value={ this.state.monthId} className="me-2 text-primary" onChange={this.handleInput}>
                    {
                        this.state.months.map((month) => {
                            return(
                                <option value={month.id}>{ month.month }</option>
                            )
                        })
                    }
                </select>
                <ReactToPrint
                    trigger={() => <button className="btn btn-sm btn-outline-primary" type="button"><FeatherIcon icon={"printer"} size="22"></FeatherIcon> <span className="d-none d-sm-inline">Imprimer</span></button>}
                    content={() =>  this.componentRef}
                />
            </div>
            </div>
            <div  ref={el => (this.componentRef = el)}>
                    <Headerpdf />
                    <UserIdentity title={ "Equilibre du caisse " + this.state.monthId + " ° mois"} />
            <div className="table-responsive">
                <table className="table table-striped table-sm table-bordered ">
                <thead>
                    <tr>
                        <th>#</th>
                        <th scope="col">Caisse</th>
                        <th scope="col">Créditer</th>
                        <th scope="col">Débiter</th>
                        <th scope="col">Solde</th>
                        <th scope="col">mois</th>
                        <th scope="col">Date</th>
                        <th scope="col">Motif</th>
                        <th scope="col">Opérateur</th>
                    </tr>
                </thead>
                <tbody style={{  fontSize : 13 +"px" }}>
                    { !this.state.loading && (<Loading />)}
                    {
                        this.state.accountingbalances.map((accountingbalance, count) => {
                            amount = "";
                            currency.map((curr, index) => {
                                if(curr.currency == accountingbalance.chartaccount.currency)
                                {
                                    currency[index].amount =  accountingbalance.credit > 0 ? currency[index].amount + accountingbalance.credit : currency[index].amount - accountingbalance.debit;
                                    amount = currency[index].amount > 0 ? currency[index].amount + " " + accountingbalance.chartaccount.currency : "";  
                                }
                            })
                            return(
                                <tr key={accountingbalance.id}>
                                    <td align="right">{count + 1 }</td>
                                    <td align="center">{accountingbalance.chartaccount.wording}</td>
                                    <td align="right">{accountingbalance.credit > 0 ? accountingbalance.credit+" "+accountingbalance.chartaccount.currency : ""}</td>
                                    <td align="right">{accountingbalance.debit > 0 ? accountingbalance.debit+" "+accountingbalance.chartaccount.currency : ""}</td>
                                    <td align="right">{amount}</td>
                                    <td align="center">
                                        {accountingbalance.expensedetail ? accountingbalance.expensedetail.expense.month ? accountingbalance.expensedetail.expense.month.month + " " + accountingbalance.expensedetail.expense.year.year  : "" : ""}
                                        {accountingbalance.schoolfee ? accountingbalance.schoolfee.month ? accountingbalance.schoolfee.month.month + " " + accountingbalance.schoolfee.year.year : "" : ""}
                                    </td>
                                    <td align="center">{new Date(accountingbalance.createdAt).toLocaleDateString("en-GB")}</td>
                                    <td>
                                        { accountingbalance.expensedetail ? accountingbalance.expensedetail.typesalary ? accountingbalance.expensedetail.typesalary.type : accountingbalance.expensedetail.reason : "" }
                                        { accountingbalance.schoolfee ? accountingbalance.schoolfee.sectionschoolfeecategory ? accountingbalance.schoolfee.sectionschoolfeecategory.schoolfeecategory.category : "" : "" }
                                        { (accountingbalance.expensedetail ==null && accountingbalance.schoolfee ==null) ? "Depense" : "" }
                                    </td>
                                    <td align="">
                                    {accountingbalance.expensedetail ? 
                                        <span>
                                            {/* <img src={accountingbalance.expensedetail.staff && (accountingbalance.expensedetail.staff.user.avatar)} className="rounded img-fluid" width={accountingbalance.expensedetail.staff && (accountingbalance.expensedetail.staff.user.avatar && ("30"))} /> */}
                                            {accountingbalance.expensedetail.staff && (accountingbalance.expensedetail.staff.user.last_name +" "+ accountingbalance.expensedetail.staff.user.first_name)}
                                        </span>
                                     : ""}
                                        {accountingbalance.schoolfee ? 
                                            <span>
                                                {/* <img src={accountingbalance.schoolfee.staff && (accountingbalance.schoolfee.staff.user.avatar)} className="rounded img-fluid" width={accountingbalance.schoolfee.staff && (accountingbalance.schoolfee.staff.user.avatar && ("30"))} /> */}
                                                {accountingbalance.schoolfee.staff && (accountingbalance.schoolfee.staff.user.last_name +" "+ accountingbalance.schoolfee.staff.user.first_name)}
                                            </span>
                                         : ""}
                                    </td>
                                    
                                </tr>
                            )
                        })
                    }
                </tbody>
                </table>
            </div>
            </div>

            
        </div>
        )
    }
}
export default Accountingbalance;