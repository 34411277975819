import axios from "axios";
import React, {Component} from "react";
import ChartaccountForm from "./ChartaccountForm";
import FeatherIcon from 'feather-icons-react';
import Listprint from "../../Listprint";
import Loading from "../../Loading";
import Headerpdf from "../../users/Headerpdf";
import ReactToPrint from "react-to-print";
import Reportdetail from "../reports/Reportdetail";
import ChartaccountStat from "./ChartaccountStat";
import { toast } from "react-toastify";
class   Chartaccount extends Component{

    state = {
        isModal : false,
        loading : false,
        formData : null,
        start : "",
        end : "",
        check : [],
        currency : [],
        data : [],
        rows : [],
        labels : [],
        currencyS : [],
        dataS : [],
        months : [],
        columns : [
            {
                label: 'Reference caisse',
                field: 'accounting_code',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Libelle caisse',
                field: 'wording',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Montant net',
                field: 'quantity',
                sort: 'asc',
                width: 150
            },
            {
                label: '#',
                field: 'action',
                sort: 'asc',
                width: 120
            }
        ]
    }

    componentDidMount(){
        this.getChartaccounts();
        this.getMonths();
    }

    async getMonths(){
        var months = await axios.get("months");
        if(months.status === 200)
        {
            this.setState({
                months : months.data
            })
        }
    }

    async getChartaccounts(){
        this.setState({
            rows : [],
            data : [],
            loading : false,
        })
        var year = this.props.yearId ? "/year/" + this.props.yearId : "";
        var chartaccounts = await axios.get('chartaccounts' + year);

        if(chartaccounts.status === 200)
        {
            var category = null, rows = [], currency = [], currencyS = [], labels = [], dataS = [], credit_total = 0, debit_total = 0, val =0 , data = [];
            chartaccounts.data.map((chartaccount) => {
                
                currencyS.push({ id : chartaccount.id, chartaccount : chartaccount.wording.toLowerCase(), currency : chartaccount.currency});
            
                credit_total = 0;
                debit_total = 0;
                
                
                chartaccount.accountingbalances.sort((a, b) => (a.schoolfee && b.schoolfee && a.schoolfee.sectionschoolfeecategory.id > b.schoolfee.sectionschoolfeecategory.id ? 1 : -1)).map((accountingbalance, index) => {
                     
                    
                    debit_total =  accountingbalance.debit > 0 ? debit_total + accountingbalance.debit : debit_total;
                    
                     
                    if (accountingbalance.schoolfee && accountingbalance.schoolfee.month) {
                        var count = labels.filter( (label) => label.month == accountingbalance.schoolfee.month.month && label.chartaccount == accountingbalance.chartaccountId  ).length;
                        
                        if (count == 0) {
                            labels.push({
                                month_id: accountingbalance.schoolfee.month.id,
                                month: accountingbalance.schoolfee.month.month,
                                chartaccount: accountingbalance.chartaccountId,
                            });
                        }

                        dataS.push({
                            somme: accountingbalance.schoolfee.pay_mount,
                            expensedetail : accountingbalance.expensedetail,
                            expense : accountingbalance.debit > 0 ? accountingbalance.debit : 0,
                            month: accountingbalance.schoolfee.month.month,
                            chartaccount: accountingbalance.chartaccountId,
                        });
                    }
                    else if (accountingbalance.expensedetail && accountingbalance.expensedetail.expense && accountingbalance.expensedetail.expense.monthId) {
                        var count = labels.filter( (label) => label.month == accountingbalance.expensedetail.expense.month.month && label.chartaccount == accountingbalance.chartaccountId  ).length;
                        
                        if (count == 0) {
                            labels.push({
                                month_id: accountingbalance.expensedetail.expense.monthId,
                                month: accountingbalance.expensedetail.expense.month.month,
                                chartaccount: accountingbalance.chartaccountId,
                            });
                        }

                        dataS.push({
                            somme: accountingbalance.schoolfee ? accountingbalance.schoolfee.pay_mount : 0,
                            expensedetail : accountingbalance.expensedetail,
                            expense : accountingbalance.debit > 0 ? accountingbalance.debit : 0,
                            month: accountingbalance.expensedetail.expense.month.month,
                            chartaccount: accountingbalance.chartaccountId,
                        });
                    }
                        
                    data.push({ val : chartaccount.amount, currency : chartaccount.currency.toLowerCase(), label : chartaccount.wording})
                })
                
                
                rows.push({
                    id : chartaccount.id,
                    accounting_code : chartaccount.accounting_code,
                    wording : chartaccount.wording,
                    quantity : chartaccount.amount + " "+ chartaccount.currency,
                    action : <span className="table_action">
                            {
                                this.handleCheck("Modifier caisse") == true && (
                                  <button onClick={ () => this.showModal(chartaccount)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Modifier cet enregistrement"><FeatherIcon icon="edit-3" size={16}></FeatherIcon> </button>
                                )
                            }
                            {
                                this.handleCheck("Supprimer caisse") == true && (
                                    <button onClick={ () => this.delete(chartaccount.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="delete" size={16}></FeatherIcon> </button>
                                )
                            }
                        </span>
                })
            })
 
            this.setState({
                rows : rows,
                data : data,
                currency : currency,
                currencyS : currencyS,
                dataS : dataS,
                labels : labels,
                loading : true
            })
        }
    }

    showModal = (schoolfee = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : schoolfee,
        })
        this.getChartaccounts()
    }

    async getData(){
        if(this.state.start && this.state.end)
        {
            var schoolfees = await axios.get("schoolfees/date/"+this.state.start+"/"+this.state.end);
            var rows = this.state.rows, data= this.state.data, currency = this.state.currency, currencyS = this.state.currencyS, dataS =[], labels = [];
            this.setState({
                check : [],
                rows : [],
                data : [],
                currency : [],
                currencyS : [],
                dataS : [],
                labels : [],
                loading : false
            })
            
            if(schoolfees.status == 200)
            {
                schoolfees.data.map((schoolfee) => {
                    if (schoolfee.month) {
                        var count = labels.filter( (label) => label.month == schoolfee.month.month && label.chartaccount == schoolfee.accountingbalance.chartaccountId  ).length;
                       
                        if (count == 0) {
                            labels.push({
                                month_id: schoolfee.month.id,
                                month: schoolfee.month.month,
                                chartaccount: schoolfee.accountingbalance.chartaccountId,
                            });
                        }

                        dataS.push({
                            somme: schoolfee.pay_mount,
                            expensedetail : schoolfee.accountingbalance.expensedetails,
                            expense : schoolfee.accountingbalance.debit > 0 ? schoolfee.accountingbalance.debit : 0,
                            month: schoolfee.month.month,
                            chartaccount: schoolfee.accountingbalance.chartaccountId,
                        });
                    }

                })

                if(schoolfees.data.length == 0)
                {
                    this.setState({
                        rows : rows,
                        data : data,
                        currency : currency,
                        currencyS : currencyS,
                        dataS : dataS,
                        labels : labels,
                        loading : true
                    })
                }
                else if(labels.length > 0)
                {
                    this.setState({
                        rows : rows,
                        data : data,
                        currency : currency,
                        currencyS : currencyS,
                        dataS : dataS,
                        labels : labels,
                        loading : true
                    })
                }
            }
        }

    }

    inputHundle = (e) => {
        this.setState({
            [e.target.name] : e.target.value
          })
    }

    InputHindel = (e) => {
        var currency = this.state.currency;
        this.setState({ currency : []})
        if(this.state.check[e.target.value])
          this.state.check[[e.target.value]] = false;
        else  
          this.state.check[[e.target.value]] = true
    
          this.setState({
            currency : currency
          })
      }

    delete = (chartaccount) =>{
        axios.delete("chartaccounts/"+chartaccount).then((response) => {
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            this.getChartaccounts()
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    handleCheck(val) {
        var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
         return  permissions.some(item => val === item.permission);
     }

    render(){
        
        return(
            <div className="">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Structure d'etat financier par caisse</h5>
                {
                  this.handleCheck("Ajouter caisse") == true && (
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="btn-group me-2">
                            <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><FeatherIcon icon="plus"></FeatherIcon> Code caisse</button>
                        </div>
                    </div>
                  )
                }
            </div>
            {
                !this.state.loading && (<Loading />)
            }
            
            {
                this.state.rows.length > 0 && (
                    <div className={ this.props.yearId ? "d-none" : "overflow-hidden"}>
                        <Listprint columns={this.state.columns} rows={this.state.rows} title={"Structure de l'Etat financier par caisse"}/>
                    </div>    
                )
            }


        <div className="text-end me-2">
            <input type={"date"} name="start" onChange={this.inputHundle} value={ this.state.start} className="form-control-sm border-white m-2" />
            <input type={"date"} name="end" onChange={this.inputHundle} value={ this.state.end} className="form-control-sm border-white m-2" />
            <button className="btn btn-sm btn-outline-primary mx-2" type="button" onClick={ () => this.getData()}><FeatherIcon icon={"server"} size="22"></FeatherIcon>Afficher</button>
            <ReactToPrint
                trigger={() => <button className="btn btn-sm btn-outline-primary" type="button"><FeatherIcon icon={"printer"} size="22"></FeatherIcon> <span className="d-none d-sm-inline">Imprimer</span></button>}
                content={() =>  this.componentRef}
            />
          </div>

        {
            this.state.currencyS.length > 0 && (
              <div>
                <div className="" ref={el => (this.componentRef = el)}>
                    <Headerpdf />
                        {
                            this.state.currencyS.map((current) => {
                                
                                var some = 0, expense = 0, data = [], max = 0, expensedetail = [];
                                this.state.labels.filter(money => money.chartaccount == current.id).sort((a, b) => (a.month_id > b.month_id ? 1 : -1)).map((label) =>{
                                    some = 0;
                                    expense = 0;
                                    
                                    this.state.dataS.filter(mon => mon.month == label.month && mon.chartaccount == label.chartaccount).map((val) => {
                                       
                                       some = val.somme > 0 ? (some +  val.somme) : some;
                        
                                        expense = expense + val.expense;
                                        if(val.expensedetail)
                                            expensedetail.push(val.expensedetail);
                                    }) 
                                    data.push({ some : some, expense : expense, month : label.month, expensedetails : expensedetail});

                                })
                                var expenseD = 0;
                                return(
                                    <div className="card shadow mb-4 overflow-hidden" key={current.chartaccount}>
                                        <div className="card-body">
                                            <h6 className="text-center my-2 font-monspace">{"Statistique mensuelle en  "+ current.chartaccount.toUpperCase() }</h6>
                                            <div className="text-center"> { this.state.display ?  "Entre " + this.state.start + " et " + this.state.end : "" }</div>
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-sm">
                                                    <thead>
                                                        <tr>
                                                            {
                                                                this.state.months.map((month) => {
                                                                    return(
                                                                        <th key={month.month + "-" + current.currency}>{ month.month }</th>
                                                                    )
                                                                })
                                                            }
                                                            <th>Total</th>
                                                            <th>Depense</th>
                                                            <th>Solde</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            {
                                                                this.state.months.map((dat) => {
                                                                    return(
                                                                        <td key={dat.month+"-"+current.currency+"evive"}>
                                                                        {
                                                                            data.filter(val => val.month === dat.month).map((dat) => {
                                                                                max += dat.some > 0 ? dat.some : 0;
                                                                                expenseD += dat.expense;
                                                                                return(
                                                                                    dat.some+""+current.currency
                                                                                )
                                                                            })
                                                                        }
                                                                        </td>
                                                                    )
                                                                })
                                                            }
                                                            <td>{ max+""+current.currency }</td>
                                                            <td>{ expenseD+""+current.currency }</td>
                                                            <td>{ max - expenseD+""+current.currency }</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <span className="table_action">
                                              <input type={"checkbox"}   onChange={this.InputHindel} checked={this.state.check[current.currency]} value={current.currency} className="rounded rounded-circle" id={"check"+current.currency} />
                                              <label htmlFor={"check"+current.currency}>Afficher le detail</label>
                                            </span>
                                            
                                            {
                                              this.state.check[current.currency] == true && (
                                                <Reportdetail formData={expensedetail}   currency={ current.currency } />
                                              )
                                            }
                    
                                        </div>
                                    </div>
                                )
                            })
                        }
                </div>
              </div>
            )
        }



            <div className="mt-4 row">
                <ChartaccountStat />
            </div>

            <ChartaccountForm 
                isModal={this.state.isModal} 
                formData={this.state.formData} 
                showModal={this.showModal}
            />
            

        </div>
        )
    }
}
export default Chartaccount;