import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import Listprint from "../../Listprint";
import { Helmet } from 'react-helmet';
import Loading from "../../Loading";
import Schoolfees from "../schoolfees/Schoolfrees";
import Chartaccount from "../chartaccounts/Chartaccount";

class ArchiveDetail extends Component{

    state = {
        isModal : false,
        loading : false,
        formData : "",
        isModal : false,
        isModalArchive : false,
        year : "",
        formData : {},
        formDataarchive : {},
        classe : "",
        students : [],
        columns : [
             {
                label: 'Matr',
                field: 'matricule',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Eleve',
                field: 'student',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Point',
                field: 'point',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Pourcent',
                field: 'studentsuccess',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Date',
                field: 'created_at',
                sort: 'asc',
                width: 120
            },
        ]
    }

    componentDidMount(){
        this.getArchives();
        this.getYear();
    }

    async getYear(){
        var year = await axios.get('years/' + this.props.match.params.year)
        if(year.status === 200)
        {
            this.setState({ year : year.data})
        }
    }

    async getArchives(){
        var students = await axios.get('students/year/' + this.props.match.params.year)
        this.setState({
            students : [],
            loading : false,
        })
        if(students.status === 200){
            this.setState({
                students : students.data,
                loading : true
            })
        }
    }
    
  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }

    render(){
        var rows = []

        
        return(
            <div>
                <Helmet>
                    <title>{ process.env.REACT_APP_NAME } | archived data</title>
                </Helmet>
            <div className=" pt-3 pb-2 mb-3 border-bottom">
                <h6 className="h5 font-monospace">Archive scolaire des paiements</h6>
            </div>

            <div className=" mb-4 overflow-auto">
                <div className="">
                    <ul className="widget-todo-list-wrapper p-0">
                        <div className="overflow-hidden">
                            <li className="widget-todo-item list-group-item m-2 border border-secondary rounded-3 shadow mb-2">
                                <div
                                    className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                                    <div className="widget-todo-title-area d-flex align-items-center">
                                        <FeatherIcon icon="package" className='cursor-move'></FeatherIcon> 
                                        <label><span className="widget-todo-title ml-50 fw-bolder" >{this.state.year ? this.state.year.year : "" } ({ this.state.students.length}) </span></label>
                                    </div>
                                </div>
                            </li>
                            <div className="col-md-12 fluid-content">
                            <div className="mb-3 shadown">
                                { this.state.loading > 0 ? <Chartaccount yearId={ this.props.match.params.year } /> : <span></span>}
                            </div>
                            {this.state.loading > 0 ? <Schoolfees yearId={ this.props.match.params.year } /> : <Loading/>} 
                        </div>
                    </div>
                    </ul>
                </div>
            </div>
        </div>
        )
    }
}
export default ArchiveDetail;