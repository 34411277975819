import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import Select from 'react-select';
import { toast } from "react-toastify";

class   SectionschoolfeecategoryForm extends Component{

    state = {
        sectionId : "",
        transportId : "",
        price : "",
        currency : "",
        status : "",
        error : "",
        
        isModal : false,
        sections : [],
        transports : [],
        title : "",
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    store = (val = null) =>{
        var data = {
                price : this.state.price > 0 ? this.state.price : null,
                currency : this.state.currency,
                status : "Actif",
                sectionId : this.state.sectionId ,
                transportId : this.state.transportId ? this.state.transportId : null ,
                schoolfeecategoryId : this.props.formData.id,
            }

        var request = axios.post('sectionschoolfeecategories', data);
        request.then((response) =>{
            this.props.showModal()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }
    
    async getSections(){
        var sections = await axios.get('sections');
        if(sections.status === 200)
        {
            this.setState({sections : sections.data}) 
        }
    }

    async getTransports(){
        var transports = await axios.get('transports');
        if(transports.status === 200)
        {
            this.setState({transports : transports.data}) 
        }
    }

    edit=()=>  {
        this.form()
        this.getSections()
        this.getTransports()
    }

    form=()=>{
        this.setState({
            title :"Attacher une section ou axe, " + this.props.formData.category,
            error : "",
        })
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

    render(){
        var optionsections = [] ; 
        this.state.sections.map((section) => {
            optionsections.push({
                value : section.id,
                label : section.short_entitled_section + " " + section.entitled_section
            })
        })

        var optiontransports = [] ; 
        this.state.transports.map((transport) => {
            optiontransports.push({
                value : transport.id,
                label : transport.axe
            })
        })
        
        return(
            <div>
            <Modal 
                show={this.props.isModal} 
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title className="h6 font-monospace">{ this.state.title}</Modal.Title>
                </Modal.Header>
                    <Modal.Body className="row">
                    <div className="text-center"><span className="text-danger text-small small">{ this.state.error }</span></div>
                    <div className="form-group col-md-7 mt-2">
                        <label className="font-monospace fw-normal">Section</label>
                        <Select
                            className="form-control form-control-sm p-0"
                            placeholder="section"
                            value = {
                                optionsections.filter(option => 
                                    option.value == this.state.sectionId)
                            }
                            defaultValue={this.state.sectionId}
                            onChange={
                                (select) => {
                                    this.setState({
                                        sectionId : select.value
                                    });
                                }
                            }
                            options={optionsections}
                        />
                    </div>

                    <div className="form-group col-md-5 mt-2">
                        <label className="font-monospace fw-normal">Axe</label>
                        <Select
                            className="form-control form-control-sm p-0"
                            placeholder="Axe de transport"
                            value = {
                                optiontransports.filter(option => 
                                    option.value == this.state.transportId)
                            }
                            defaultValue={this.state.transportId}
                            onChange={
                                (select) => {
                                    this.setState({
                                        transportId : select.value
                                    });
                                }
                            }
                            options={optiontransports}
                        />
                    </div>
 

                        <div className="col-6 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Prix</label>
                                <div className="position-relative mt-1">
                                    <input type="number" className="form-control from-control-sm" placeholder=""  onChange={this.handleInput} name="price"  value={this.state.price} id="nom-id-icon" />
                                    <div className="form-control-icon">
                                        <FeatherIcon icon="trello"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-6 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Devise</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm" placeholder=""  onChange={this.handleInput} name="currency"  value={this.state.currency} id="nom-id-icon" />
                                    <div className="form-control-icon">
                                        <FeatherIcon icon="trello"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-6 mt-2 mt-2 d-none">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Statut</label>
                                <div className="position-relative mt-1">
                                    <select className="form-control form-control-sm" name="status" onChange={this.handleInput}>
                                        <option></option>
                                        <option value={"Actif"}>Actif</option>
                                        <option value={"Non actif"}>Non actif</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm p-0 px-1"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                        <button onClick={() => this.store()} className="btn btn-sm btn-outline-primary p-0 px-1"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                    </Modal.Footer>
            </Modal>
            
 
        </div>
        )
    }
}
export default SectionschoolfeecategoryForm;