import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import ReactToPrint from "react-to-print";
import axios from "axios";

class   ShoolfeeInvoiceList extends Component{
 
    state = {
        check : true,
        isModal : false,
        schoolfees : [],
    }
    async getSchoolfee(){
        
         await axios.post("schoolfeesList", {
                    id: this.props.formData
                } ).then((response) => {
               this.setState({
                schoolfees : response.data
            })
        })
    }

    edit=()=>  {
        this.getSchoolfee()
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }
    
    checkbox = () => {
        this.setState({
            check: !this.state.check
        }); 
    }
    render(){
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;
        var profile = JSON.parse(localStorage.getItem('profile'))
        
        return(
            <div>
            <Modal 
                show={this.props.isModal} 
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title className="font-monospace">Factures de paiement</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <div ref={el => (this.componentRef = el)}>
                        {
                            this.state.schoolfees.map((invoice) => {
                                
                                return (
                                    <div className="m-1 border border-1 ps-2 p-1 rounded rounded-3" ref={el => (this.componentRef = el)}>
                                        <div className=" my-1">
                                            <img src={profile.profile.establishment.logo} alt=""  className="rounded img-fluid" style={{ width:60+"px" }} />
                                            <span className="h6 font-monospace fw-bolder">{ profile.profile.establishment.name}</span>
                                        </div>
                                        <div className="text-center">
                                            <h5 className="h5 font-monospace">Reçu N°{invoice.invoice}</h5>
                                        </div>
                                        <div className="row">
                                            <div className={ !this.state.check ?"col-12" : "col-7 mt-2"}>
                                                Adresse : <span className="h6 font-monospace">{ profile.profile.establishment.city}</span><br />
                                                Tél : <span className="h6 font-monospace">{ profile.profile.establishment.tel}</span><br />
                                                Email : <span className="h6 font-monospace">{ profile.profile.establishment.email}</span><br />
                                                <hr />
                                            </div>
                                            <div className={ !this.state.check ?"col-12" : "col-5 mt-2"}>
                                                Année scolaire : <span className="font-monospace h6">{invoice.year.year}</span><br />
                                                Classe : <span className="font-monospace h6">{invoice.student.sectionclass.class.short_entitled_class} {invoice.student.sectionclass.title}</span><br />
                                                Section/option : <span className="font-monospace h6">{invoice.student.sectionclass.section.entitled_section}</span>
                                                <hr />
                                            </div>
                                        </div>
                                        
                                        <div className="mt-3 ">
                                            Reçu de <span className="h6 font-monospace">{ invoice.student.user.last_name } {invoice.student.user.family_name ? invoice.student.user.family_name : "" } {invoice.student.user.first_name ? invoice.student.user.first_name : "" }</span> <br />
                                            La somme de <span className="h6 font-monospace">{ invoice.pay_mount + "" + invoice.sectionschoolfeecategory.currency}</span>; <br />
                                            Motif <span className="h6 font-monospace"> {invoice.sectionschoolfeecategory.schoolfeecategory.category + " mois de " + invoice.month.month} payé le <span className="h6 font-monospace">{ invoice.createdAt.slice(0,10) }</span></span> <br />
                                            <div className="text-end pe-5 me-5">
                                                Pour l'etablissement <span className="h6 font-monospace">{ invoice.staff.user.last_name+ " " + invoice.staff.user.first_name}</span>.<br />
                                                { profile.profile.establishment.city}, le { today}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <input type={"checkbox"} onClick={() => this.checkbox()} id="check" /> <label htmlFor="check"> imprimer en petite taille</label>
                        <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                        <ReactToPrint
                            trigger={() => <button className="btn btn-sm btn-outline-primary" type="button"><FeatherIcon icon={"printer"} size="22"></FeatherIcon> <span className="d-none d-sm-inline">Imprimer</span></button>}
                            content={() =>  this.componentRef}
                        />
                    </Modal.Footer>
            </Modal>
            

            {/* <ParentfamilyForm 
                isModal={this.state.isModal} 
                formData={{}} 
                showModal={this.showModal}
            /> */}
        </div>
        )
    }
}
export default ShoolfeeInvoiceList;