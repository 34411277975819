import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import Select from 'react-select';
import { toast } from "react-toastify";

class   ExpenseForm extends Component{

    state = {
        id : "",
        month : null,
        type : "",
        chartaccount : "",
        istype : [],
        userId: "",
        profile : "",
        reason : "",
        amount : null,
        year : null,
        max : 0,
        error : "",

        isModal : false,
        users : [],
        chartaccounts : [],
        months : [],
        typesalaries : [],
        years : [],
        title : "",
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    handleInputMax = (e) => {
        if(this.state.max > 0 && e.target.value > 0 && e.target.value <= this.state.max)
        {
            this.setState({
                [e.target.name]: e.target.value
            });
        }
        else{
            this.setState({
                [e.target.name]: ""
            }); 
        } 
    }

    storeExpense = ( val = null) =>{
        var data = {
                // userId: this.state.userId ? this.state.userId : null,
                monthId : this.state.month ? this.state.month : null,
                chartaccountId : this.state.chartaccount ,
                type : this.state.type,
                reason : this.state.reason,
                amount : this.state.amount > 0 ? this.state.amount : null,
                status : "en attente",
                yearId : this.state.year ? this.state.year : null,
                type_expense : 'Expense',
                operation : this.state.id ? (val === 1 ? "Archiver" : "Editer" ) : "Ajouter",
            }

        var request = this.state.id ? axios.put('expensedetails/'+this.state.id, data) : axios.post('expenses', data);
        request.then((response) =>{
            this.props.showModal()
            toast (response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg
            })
            toast(error.response.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    async users(){
        var request = await axios.get('users');
        if(request.status === 200)
            this.setState({users : request.data})
    }

    async typesalaries(){
        var request = await axios.get('typesalaries');
        if(request.status === 200)
            this.setState({typesalaries : request.data})
    }

    async months(){
        var request = await axios.get('months');
        if(request.status === 200)
            this.setState({months : request.data})
    }

    async getYear(){
        var data=[], years = await axios.get('years');
        if(years.status === 200)
        {
            years.data.map((year) => {
                data.push({value : year.id, label : year.year})
            })
            
            this.setState({
                years : data
            })
        }
    }

    async chartaccount(){
        var request = await axios.get('chartaccounts');
        if(request.status === 200)
            this.setState({chartaccounts : request.data})
    }
    
    edit=()=>  {
        this.form()
        // this.users()
        this.chartaccount()
        this.months()
        this.getYear()
        // this.typesalaries()
    }

    form=()=>{

        if(this.props.formData)
        {
            this.props.formData.expensedetails.map((expensedetail) =>{
                this.setState({
                    id : expensedetail.id,
                    reason : expensedetail.reason,
                    amount : expensedetail.amount,
                    chartaccount : expensedetail.chartaccount ? expensedetail.chartaccount.id : "",
                })
            })
        }

        this.setState({
            // month: this.props.formData ? this.props.formData.month : "",
            userId: this.props.formData ? this.props.formData.userId : "",
            // profile : this.props.formData ? this.props.formData.profile : "",
            title : this.props.formData ? "Modifier une depense" : "Ajouter une depense",
            error : "",
        })
    }


    render(){
        var optionchartaccounts = [],  optionusers= [] , optionprofiles= [], optionmonths= [] ,  optiontypesalaries = [];
         
        this.state.typesalaries.map((type) => {
            optiontypesalaries.push({value : type.id, label : type.type })
        });
        

        this.state.chartaccounts.map((chartaccount) => {
            optionchartaccounts.push({value : chartaccount.id, label : chartaccount.coded + "  "+ chartaccount.wording + " # " + chartaccount.amount + " " + chartaccount.currency, max : chartaccount.amount })
        });

        this.state.months.map((month) => {
            optionmonths.push({value : month.id, label : month.month})
        });
        
        this.state.users.map((user) => {
            optionusers.push({value : user.id, label : user.first_name + "  "+ user.last_name +" "+user.tel})
        });
 
  
        return(
            <div>
            <Modal 
                show={this.props.isModal} 
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title className="h6 font-monospace">{ this.state.title}</Modal.Title>
                </Modal.Header>
                    <Modal.Body className="row">

                        <div className="text-center"><span className="text-danger text-small">{ this.state.error }</span></div>
                        <div className="form-group col-md-6 mt-2 d-none">
                            <label className="font-monospace fw-normal">Bénéficiaire {this.state.userId}</label>
                            <div className="input-group ">
                                <Select
                                    required={true}
                                    className="form-control form-control-sm p-0"
                                    defaultValue={this.state.userId}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                userId : select.value,
                                            });
                                        }
                                    }
                                    options={optionusers}
                                />
                            </div>
                            
                        </div>

                        <div className="form-group col-md-6 mt-2 d-none">
                            <label className="font-monospace fw-normal">Type</label>
                            <div className="input-group">
                                <Select
                                    className="form-control form-control-sm p-0"
                                    defaultValue={this.state.type}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                type : select.value,
                                            });
                                        }
                                    }
                                    options={optiontypesalaries}
                                />
                            </div>
                        </div>

                        <div className="form-group col-md-6 mt-2">
                            <label className="font-monospace fw-normal">Caisse</label>
                            <div className="input-group">
                                <Select
                                    className="form-control form-control-sm p-0"
                                    defaultValue={this.state.chartaccount}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                chartaccount : select.value,
                                                max : select.max
                                            });
                                        }
                                    }
                                    options={optionchartaccounts}
                                />
                            </div>
                        </div>

                        <div className="form-group col-md-6 mt-2">
                            <label className="font-monospace fw-normal">Mois</label>
                            <div className="input-group">
                                <Select
                                    className="form-control form-control-sm p-0"
                                    defaultValue={this.state.month}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                month : select.value,
                                            });
                                        }
                                    }
                                    options={optionmonths}
                                />
                            </div>
                        </div>

                        <div className="col-md-6 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Montant</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm" placeholder="montant"  onChange={this.handleInputMax} name="amount"  value={this.state.amount} id="nom-id-icon" />
                                    <div className="form-control-icon">
                                        <FeatherIcon icon="dollar-sign"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className=" col-md-6 mt-2">
                            <label className="font-monospace fw-normal">Année scolaire</label>
                            <div className="input-group">
                                <Select
                                    className="form-control p-0 form-control-sm"
                                    value = {
                                        this.state.years.filter(option => 
                                        option.value == this.state.year)
                                    }
                                    defaultValue={this.state.year}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                year : select.value
                                            });
                                        }
                                    }
                                    options={this.state.years}
                                />
                            </div>
                        </div>

                        {/* <div className="col-12 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Facture</label>
                                <div className="position-relative mt-1">
                                    <input type="file" className="form-control "  onChange={this.handleInput} name="facture"  value={this.state.facture} id="nom-id-icon" />
                                    <div className="form-control-icon">
                                        <FeatherIcon icon="file"></FeatherIcon>
                                    </div>
                                </div>
                                <span className="text-danger text-small small">{ this.state.errors.facture }</span>
                            </div>
                        </div> */}

                        <div className="col-12 mt-2">
                            <div className="form-group ">
                                <label htmlFor="postnom-id-icon">Descrption</label>
                                <div className="position-relative mt-1">
                                    <textarea
                                        value={this.state.reason}
                                        onChange={this.handleInput}
                                        name="reason"
                                        rows={2}
                                        cols={4}
                                        className="form-control  "
                                    />
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm p-0 px-1"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                        <button onClick={() => this.storeExpense()} className="btn btn-sm btn-outline-primary p-0 px-1"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                    </Modal.Footer>
            </Modal>
            
        </div>
        )
    }
}
export default ExpenseForm;